<template>
  <div class="desktop-search">
    <Header>
      <template #suggest-desktop>
        <BaseSuggestionsSearchHeader />
      </template>
    </Header>
    <DetailWrapper :id="String(props.id)" />
    <Footer :isLight="true" />
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { Header } from "@sutochno/header";
// import Header from "@/components/header/src/Header.vue";
import BaseSuggestionsSearchHeader from "@/components/base/BaseSuggestions/SearchHeader";
import DetailWrapper from "./../Detail/index.vue";
import { Footer } from "@sutochno/footer";
import { Detail } from "@/utils/gtm/";

const props = defineProps({
  id: {
    type: [Number, String],
    required: true,
  },
});

onMounted(() => {
  Detail.gtmDataPage();
});
</script>

<style lang="scss" scoped>
.desktop-search {
  grid-template-areas:
    "header"
    "content"
    "footer";
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}
</style>
