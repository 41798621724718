<i18n lang="json">
{
  "ru": {
    "sleepPlace": "Спальные места",
    "bedrooms": "нет спален | 1 спальня | {n} спальни | {n} спален",
    "beds": "нет кроватей | 1 кровать | {n} кровати | {n} кроватей",
    "maxGuests": "ни одного | {n} гость | {n} гостя | {n} гостей",
    "rooms": "0| {n} комната. | {n} комнаты. | {n} комнат.",
    "area": "общая площадь",
    "meter": "м",
    "kmeter": "км",
    "lift": "лифт",
    "floors": "всего этажей",
    "total": "всего",
    "floor": "этаж",
    "basement": "цокольный этаж",
    "sea": "до моря",
    "single_bed": "нет односпальных кроватей | 1 односпальная кровать | {n} односпальных кровати | {n} односпальных кроватей",
    "double_bed": "нет двуспальных кроватей | 1 двуспальная кровать | {n} двуспальных кровати | {n} двуспальных кроватей",
    "king_bed": "нет кроватей king-size | 1 кровать king-size | {n} кроватей king-size | {n} кроватей king-size",
    "double_sofa_bed": "нет двуспальных диванов-кроватей | 1 двуспальный диван-кровать | {n} двуспальных дивана-кровати | {n} двуспальных диванов-кроватей",
    "super_king_bed": "нет особо широких двуспальных кроватей | 1 особо широкая двуспальная кровать | {n} особо широких двуспальных кровати | {n} особо широких двуспальных кроватей",
    "yarus_bed": "нет двухъярусных кроватей | 1 двухъярусная кровать | {n} двухъярусных кровати | {n} двухъярусных кроватей",
    "bsofa_bed": "нет диванов-кроватей | 1 диван-кровать | {n} дивана-кровати | {n} диванов-кроватей",
    "of": "из",
    "elevator": "лифт",
    "there_is": "есть",
    "guestsRecommend": "Гости рекомендуют",
    "hint_sea": "Указано прямое расстояние до моря. Реальное расстояние в пути может быть больше.",
    "distance_meters": "{distance} м",
    "distance_kilometers": "{distance} км",
    "priceNote": "Примечание к стоимости:",
    "btnShow": "еще",
    "btnHide": "скрыть",
    "separate_kitchen": "отдельная кухня",
    "kitchen_dining" : "кухня-гостиная",
    "kitchen_area": "кухонная зона"
  },
  "en": {
    "sleepPlace": "Sleeping places",
    "bedrooms": "no bedrooms | 1 bedroom | {n} bedrooms | {n} bedrooms",
    "beds": "no beds | 1 bed | {n} beds | {n} beds",
    "maxGuests": "no guests | {n} guest | {n} guests | {n} guests",
    "rooms": "0| {n} room. | {n} rooms. | {n} rooms.",
    "area": "total area",
    "total": "total",
    "meter": "m",
    "kmeter": "km",
    "lift": "elevator",
    "floors": "total floors",
    "floor": "floor",
    "there_is": "there is an ",
    "basement": "ground floor",
    "sea": "to the sea",
    "single_bed": "no single bed | 1 single bed | {n} single beds | {n} single beds",
    "double_bed": "no double bed | 1 double bed | {n} double beds | {n} double beds",
    "king_bed": "no king-size beds | 1 king-size bed | {n} king-size beds | {n} king-size beds",
    "super_king_bed": "no extra wide double beds | 1 extra wide double bed | {n} extra wide double beds | {n} extra wide double beds",
    "double_sofa_bed": "no double sofa beds | 1 double sofa bed | {n} double sofa beds | {n} double sofa beds",
    "yarus_bed": "no bunk beds | 1 bunk bed | {n} bunk beds | {n} bunk beds",
    "bsofa_bed": "no sofa beds | 1 sofa bed | {n} sofa beds | {n} sofa beds",
    "of": "of",
    "elevator": "there is an elevator",
    "guestsRecommend": "Guests recommend",
    "hint_sea": "Showed direct distance calculated automatically; real distance to the sea may be longer.",
    "distance_meters": "{distance} m",
    "distance_kilometers": "{distance} km",
    "priceNote": "Show price details:",
    "btnShow": "more",
    "btnHide": "hide",
    "separate_kitchen": "separate kitchen",
    "kitchen_dining" : "kitchen-dining room",
    "kitchen_area": "kitchen area"
  }
}
</i18n>
<template>
  <div class="object-data">
    <div class="object-data--type tmp-font--big_bold">
      {{ titleObject }}
      <template v-if="property.area">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ property.area }}{{ t("meter")
        }}<sup>2</sup>
      </template>
    </div>
    <div class="object-data--params tmp-font--medium">
      <span v-if="property.max_guests">{{
        t("maxGuests", property.max_guests)
      }}</span>
      <span v-if="countBeds">{{ t("beds", countBeds) }}</span>
      <span v-if="property.bedrooms && property.bedrooms > 0">{{
        t("bedrooms", property.bedrooms)
      }}</span>
      <span v-if="kitchen">
        {{ kitchen }}
      </span>
      <span
        v-if="sea"
        v-tooltip="{
          content: t('hint_sea'),
          popperClass: 'v-popper__popper--max-width-300',
        }"
        >{{ sea }} {{ t("sea") }}</span
      >
      <template v-if="checkTypeTitle && property.max_floor > 0">
        <span>
          {{ t("floors") }} {{ property.max_floor }}
          <template v-if="checkAvailability && checkAvailability.lift">
            ({{ t("elevator") }})
          </template>
        </span>
      </template>
      <template v-else>
        <span v-if="property.floor || property.floor >= 0">
          <template v-if="property.floor > 0"
            >{{ t("floor") }} {{ property.floor }} {{ t("of") }}
            {{ property.max_floor }}</template
          >
          <template v-else-if="property.floor === 0"
            >{{ t("basement") }} ({{t("total")}} {{ property.max_floor }})</template
          >
          <template v-if="checkAvailability && checkAvailability.lift"
            >, {{ t("there_is") }} {{ t("elevator") }}</template
          >.
        </span>
        <span
          v-if="!property.floor && property.floor !== 0 && checkAvailability && checkAvailability.lift"
        >
          {{ t("there_is") }} {{ t("elevator") }}.
        </span>
      </template>
    </div>

    <div
      v-if="(object && object.meal) || isRecommend"
      class="object-data--meal"
    >
      <InfoMeal
        v-if="object && object.meal"
        :meal="object.meal"
        @clickMeal="scrollMeal"
      />
      <p v-if="isRecommend" class="guests-recommend">
        {{ t("guestsRecommend") }}
      </p>
    </div>

    <div v-if="description" class="object-data--desk tmp-font--medium">
      <template v-if="!viewFullTextDesk">
        {{ descriptionSlice }}
        <span v-if="!viewFullTextDesk">...</span>
        <span
          v-if="!viewFullTextDesk || priceNote"
          class="link-more"
          @click="viewFullTextDesk = !viewFullTextDesk"
          >{{ t("btnShow") }}</span
        >
      </template>
      <template v-else>
        {{ description }}
        <template v-if="priceNote">
          <br /><br />
          <h3 class="tmp-font--average_bold">
            {{ t("priceNote") }}
          </h3>
          <p>
            {{ String(priceNote.value).trim() }}
          </p>
        </template>
        <span
          v-if="priceNote || descriptionSlice.length !== description.length"
          class="link-more"
          @click="viewFullTextDesk = !viewFullTextDesk"
          >{{ t("btnHide") }}</span
        >
      </template>
    </div>
    <div
      v-if="beds.length > 0"
      class="object-data--sleeping-places tmp-font--average_bold"
    >
      {{ t("sleepPlace") }}<span v-if="bedsCount">: {{ bedsCount }}</span>
    </div>
    <div v-if="beds.length > 0" class="object-data--beds tmp-font--medium">
      <span v-for="(bed, i) in beds" :key="i">
        <span>{{ t(bed.name, bed.value) }}</span>
        <span v-if="beds.length > i + 1">,&nbsp;</span>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import InfoMeal from "@/components/base/Detail/InfoMeal";
import { useI18n } from 'vue-i18n';

export default {
  name: "Info",
  components: {
    InfoMeal,
  },
  props: {
    location: {
      type: Object,
      required: true,
    },
    planning: {
      type: String,
      required: true,
    },
    basicProperties: {
      type: Array,
      required: true,
    },
    basicAvailability: {
      type: Array,
      required: true,
    },
    object: {
      type: Object,
    },
    avgRating: {
      type: Object,
    },
    properties: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      viewFullTextDesk: false,
    };
  },
  mounted() {
    if (this.isDescriptionMore) {
      this.viewFullTextDesk = false;
    } else {
      this.viewFullTextDesk = true;
    }
  },
  computed: {
    ...mapGetters("search", ["getQuery"]),
    ...mapState("user", ["viewPort"]),
    description() {
      if (this.properties?.detail_description?.properties.length) {
        return this.properties.detail_description.properties[0].value;
      } else {
        return "";
      }
    },
    priceNote() {
      if (this.properties?.additional_payment?.properties) {
        const ratesInfo = this.properties.additional_payment.properties.find(
          (p) => p.name === "rates_info"
        );
        if (ratesInfo && ratesInfo?.value) return ratesInfo;
        return null;
      } else {
        return null;
      }
    },
    descriptionSlice() {
      return String(this.description).slice(0, 180);
    },
    isDescriptionMore() {
      if (String(this.description).length >= 180) {
        return true;
      } else {
        return false;
      }
    },
    sea() {
      if (!this?.location?.seas) return;
      if (this?.location?.seas.length === 0) return false;
      const distance = this.location?.seas[0]?.distance;

      if (distance < 50) {
        return this.t("distance_meters", {
          distance: "< 50",
        });
      } else if (distance < 991) {
        return this.t("distance_meters", {
          distance: (Math.ceil(distance / 10) * 10).toLocaleString(),
        });
      } else {
        return this.t("distance_kilometers", {
          distance: (
            (Math.round(distance / 100) * 100) /
            1000
          ).toLocaleString(),
        });
      }
    },
    property() {
      return this.basicProperties.reduce(
        (acc, item) => ((acc[item.name] = item.value), acc),
        {}
      );
    },
    checkAvailability() {
      if (!this.basicAvailability) return false;

      return this.basicAvailability.reduce(
        (acc, item) => ((acc[item.name] = item.value), acc),
        {}
      );
    },
    isRecommend() {
      let isRecommend =
        this?.object?.properties?.hidden_properties?.properties?.find(
          (item) => item.name == "is_recommend"
        );
      return isRecommend ? isRecommend.value : false;
    },
    beds() {
      const NAMES_BEDS = [
        "single_bed",
        "double_bed",
        "king_bed",
        "super_king_bed",
        "yarus_bed",
        "bsofa_bed",
        "double_sofa_bed"
      ];
      return Object.entries(this.property || {}).reduce((acc, [key, value]) => {
        if (NAMES_BEDS.includes(key) && value > 0) {
          acc.push({
            name: key,
            value: value,
          });
        }
        return acc;
      }, []);
    },
    bedsCount() {
      const beds = this.basicProperties.find((item) => item.name === "beds");
      if (beds) {
        return beds.value;
      } else {
        return 0;
      }
    },
    countBeds() {
      return this.beds.length > 0
        ? this.beds.reduce(function (result, item) {
            return result + item.value;
          }, 0)
        : 0;
    },
    titleObject() {
      const { hotel, title, hotel_category, type_title } = this.object || {};
      if(!hotel || !hotel_category) {
        return title || "";
      }
      return type_title || `${hotel_category?.type} ${hotel_category?.title}`;
    },
    kitchen() {
      const map = {
        1: "separate_kitchen",
        2: "kitchen_dining",
        3: "kitchen_area",
      };
      const kitchen = this.basicProperties.find(
        (p) => p.name === "kitchen_flat"
      );
      if (kitchen && Number(kitchen.value) < 1) {
        return false;
      }
      if (kitchen) {
        return this.t(map[kitchen.value]);
      }
      return "";
    },
    checkTypeTitle() {
      return this.object?.type_title === "дома, коттеджи целиком" &&
        this.object?.title !== "Часть дома с отдельным входом" &&
        this.object?.title !== "Целый этаж в доме"
        ? true
        : false;
    },
  },
  methods: {
    scrollMeal() {
      let blockMeal = document.querySelector("#block-meal");
      if (blockMeal) {
        window.scrollTo({
          top: blockMeal.getBoundingClientRect().top + window.scrollY - 50,
          behavior: "smooth",
        });
        sendEventGtag("object_sa", {
          click: "meals",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.object-data {
  &--type {
    margin-bottom: 10px;
    .area {
      padding-left: 18px;
    }
  }
  &--params {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
    }
  }
  &--desk {
    margin-bottom: 20px;
    line-height: 19px;
    white-space: pre-line;
    .link-more {
      cursor: pointer;
      margin-left: 4px;
      color: #2d6cb4;
    }
  }
  &--meal {
    margin-top: -10px;
    margin-bottom: 12px;
    .meal {
      border: none;
      padding-left: 0;
      font-size: 14px;
      margin-bottom: 0;
      .icon-app-meal {
        font-size: 18px;
      }
    }
  }
  &--sleeping-places {
    margin-bottom: 10px;
  }
  &--beds {
  }
}

.guests-recommend {
  color: #ffffff;
  background: #0659ff;
  font-size: 9px;
  height: 18px;
  line-height: 18px;
  padding: 0 10px;
  border-radius: 9px;
  margin-right: 10px;
  vertical-align: text-bottom;
  display: inline-block;
  margin-top: 0;
}
</style>
