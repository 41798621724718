import sendAbuseEmail from "./sendAbuseEmail";

/**
 * Работа с запросами приложения
 *
 * @module Application
 */
export default {
  sendAbuseEmail,
};
