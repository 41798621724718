<template>
  <div class="desktop-search">
    <Header />
    <DetailWrapper :id="String(id)" class="detail-wrap-mobile" />
    <!-- <Footer /> -->
  </div>
</template>
<script>
import { Header } from "@sutochno/header";
import DetailWrapper from "@/components/mobile/Detail/index.vue";
import { Footer } from "@sutochno/footer";
import { Detail } from "@/utils/gtm/";

export default {
  name: "Detail",
  components: {
    Header,
    DetailWrapper,
    Footer,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  mounted() {
    Detail.gtmDataPage();
  },
};
</script>
<style lang="scss"scoped>
.detail-wrap-mobile {
  z-index: 1;
}
</style>
