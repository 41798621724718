<i18n lang="json">
{
  "ru": {
    "distance_m": "{n} м",
    "distance_km": "{n} км",
    "reviews": "нет отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "titleAvailabileRooms": "Доступные номера на выбранный период",
    "titleNoReviews": "Нет отзывов",
    "titleReviews": "нет отзывов | {n} отзыв | {n} отзыва от гостей | {n} отзывов от гостей"
  },
  "en": {
    "distance_m": "{n} m",
    "distance_km": "{n} km",
    "reviews": "No reviews | {n} review | {n} reviews | {n} reviews",
    "titleAvailabileRooms": "Available rooms for the selected period",
    "titleNoReviews": "No reviews",
    "titleReviews": "not reviews | {n} review  | {n} reviews from guests | {n} reviews from guests"
  }
}
</i18n>
<template>
  <div>
    <div
      class="detail-desktop"
      :class="{
        'is-hotel': isHotel && isRoomsList,
        'hide-title-review': Number(reviewsRatingCount) > 1,
      }"
      itemscope
      :itemtype="itemtype"
    >
      <div class="bar">
        <BasePath
          v-if="object"
          type-object="object_id"
          :id-object="object.id"
          :location-object="location"
        />
      </div>
      <!-- <div class="detail-content grid-area-content" v-if="properties"> -->
      <div v-if="!show" class="detail-content grid-area-content">
        <Empty />
        <Wrap>
          <Cart v-model="permissionsSuperHost" :owner="detail.object.owner" />
        </Wrap>
        <!-- <div class="detail-banner">
          <BannerGuarant :is-green="isGreen" />
        </div> -->
      </div>
      <template v-else-if="detail">
        <div class="detail-content grid-area-content">
          <DetailPanelScroll
            v-if="showPanelScroll"
            :is-facilities="isFacilities"
          />
          <DetailTop
            v-if="!loadingRatings"
            v-model="reviewsRatingCount"
            :title-object="titleObject"
            :avg-rating="isHotel ? hotelAvgRating : avgRating"
            :location="location"
            :max-rating-external="
              maxRatingExternal ? maxRatingExternal : maxRatingExternalHotel
            "
            :count-reviews-external="
              countReviewsExternal
                ? countReviewsExternal
                : countReviewsExternalHotel
            "
            @toRating="scrollToRating()"
            @toMap="scrollToMap()"
          />
          <DetailSlider ref="panelPhoto" :media="media" :video="mediaVideo" />
          <Wrap>
            <Info
              :location="location"
              :planning="planning"
              :basic-properties="basicProperties"
              :basic-availability="basicAvailability"
              :object="object"
              :properties="properties"
              :short-description="shortDescription"
              @clickRating="scrollToRating()"
            />
          </Wrap>

          <DetailSuperhost
            v-if="permissionsSuperHost === 'showSuperhostLabelAbility'"
            :src-avatar="srcAvatar"
          />

          <Wrap>
            <Rules :rules="rules" />
          </Wrap>

          <Wrap v-show="isFacilities" ref="panelDescription">
            <Description
              v-model="isFacilities"
              :short-description="shortDescription"
              :properties="properties"
            />
          </Wrap>
          <div v-if="isHotel" ref="panelHotel">
            <Wrap
              :class="{
                'is-view-image': !viewImage,
                'load-block': hotelDataLoad,
                'card-hotel-wr': isRoomsList,
              }"
            >
              <DetailCardHotelPlaceholder v-if="hotelDataLoad" />
              <DetailCardHotel
                v-else
                v-model="viewImage"
                :hotel-type="hotelTypes[getHotelData.hotel.type_name]?.about"
              />
            </Wrap>
          </div>
        </div>
        <div class="detail-content grid-area-content-hotel">
          <div v-if="isHotel">
            <DetailRoomsList v-if="isRoomsList" />
          </div>

          <Wrap ref="panelReviews">
            <div v-show="!loadingComments">
              <Rating
                v-if="!loadingRatings"
                :id="Number(id)"
                :max-rating-external="
                  maxRatingExternal ? maxRatingExternal : maxRatingExternalHotel
                "
                :count-reviews-external="
                  countReviewsExternal
                    ? countReviewsExternal
                    : countReviewsExternalHotel
                "
                :ratings-obj="isHotel ? hotelAvgRating : avgRating"
                :is-loading="loadingRatings"
              />
              <BaseComments
                :id="Number(id)"
                :is-hotel="isHotel"
                :max-rating-external="
                  maxRatingExternal ? maxRatingExternal : maxRatingExternalHotel
                "
                :count-reviews-external="
                  countReviewsExternal
                    ? countReviewsExternal
                    : countReviewsExternalHotel
                "
              />
            </div>
            <CommentsPlaceholder v-show="loadingComments" />
          </Wrap>

          <Wrap ref="panelMap">
            <MapInfo
              :location="location"
              :metro="metro"
              :ropeway="ropeway"
              :is-show-metro="isShowMetro"
              :object="object"
            />
          </Wrap>

          <Wrap v-if="otherReviews && otherReviews.length != 0">
            <DetailOtherComments
              :owner="owner"
              :other-reviews="otherReviews"
              :page="pageOther"
              :last-page="lastPageOther"
              :load="loadComments"
              @page="otherComments($event)"
            />
          </Wrap>

          <Wrap>
            <Cart v-model="permissionsSuperHost" :owner="owner" />
          </Wrap>
          <!-- <div class="detail-banner">
            <BannerGuarant :is-green="isGreen" />
          </div> -->
        </div>
      </template>
      <DetailPlaceholder v-else />
      <Aside
        v-if="isAuth !== null && isOffer"
        ref="aside"
        :offer-id="offerId"
        :hot-id="hotId"
        :object="object"
        class="grid-area-aside"
        :is-empty="true"
        :title-object="titleObject"
        @priceLoaded="priceLoaded = true"
      />
      <Aside
        v-else-if="isAuth !== null && !show"
        :id="id"
        ref="aside"
        :object="object"
        class="grid-area-aside"
        :is-empty="true"
        :title-object="titleObject"
        @priceLoaded="priceLoaded = true"
      />
      <Aside
        v-else-if="isAuth !== null && id && object"
        :id="id"
        ref="aside"
        class="grid-area-aside"
        :object="object"
        :title-object="titleObject"
        @priceLoaded="priceLoaded = true"
      />
      <div v-else class="grid-area-aside">
        <AsidePlaceholder />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { HTTP, Objects, Reviews, Offers } from "@/components/api/";
import { useI18n } from 'vue-i18n';

import { format } from "@/utils/format";
import { Detail } from "@/utils/gtm/";
import setViewedObjectsStorage from "@/utils/setViewedObjectsStorage";
import { event as sendEventGtag } from "@/composables/gtag/index.js";

import Empty from "@/components/base/Detail/Empty";
import DetailPlaceholder from "@/components/base/Detail/Placeholder";
import AsidePlaceholder from "@/components/base/Detail/Aside/Placeholder";
import DetailPanelScroll from "@/components/desktop/Detail/DetailPanelScroll";
import DetailSlider from "@/components/desktop/Detail/DetailSlider";
import Wrap from "@/components/base/Detail/Wrap";
import Info from "@/components/base/Detail/Info";
import Rules from "@/components/base/Detail/Rules";
import Description from "@/components/base/Detail/Description";
import MapInfo from "@/components/base/Detail/MapInfo";
import DetailRating from "@/components/desktop/Detail/DetailRating";
import Rating from "@/components/base/BaseAvgRating";
import BaseComments from "@/components/base/BaseComments/Index";
import DetailOtherComments from "@/components/desktop/Detail/DetailOtherComments";
import Cart from "@/components/base/Detail/Cart";
import BannerGuarant from "@/components/base/Detail/BannerGuarant";
import Aside from "@/components/base/Detail/Aside";
import BasePath from "@/components/base/BasePath/index.vue";
import DetailCardHotel from "./DetailCardHotel";
import DetailCardHotelPlaceholder from "./DetailCardHotelPlaceholder";
import DetailRoomsList from "./DetailRoomsList";
import DetailTop from "./DetailTop";
import DetailSuperhost from "./DetailSuperhost";
import hotelTypes from "@/assets/json/hotelTypes.json";
import CommentsPlaceholder from "@/components/base/BaseComments/CommentsPlaceholder";
import avgRating from '@/components/desktop/NewSearch/ObjectList/AvgRating.vue';
import { pushDataToYM } from "@/utils/yandexMetrica";
import { useReplaceQuery } from '@/composables/query'
import { useAuth } from "@/composables/auth";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import getUserIP from "@/utils/getUserIP";
import { intersectObserver } from "@/utils/intersectObserver";
import useMetaDetail from "@/composables/meta/detail/index";

export default {

  setup() {
    const { t } = useI18n();

    const auth = useAuth();
    useReplaceQuery({ target: 'guest_id', finalValue: auth?.id });

    useMetaDetail();

    return {
      t
    }
  },

  name: "Detail",
  mixins: [dataLayerPushGA4],
  components: {
    CommentsPlaceholder,
    Empty,
    DetailPlaceholder,
    AsidePlaceholder,
    DetailPanelScroll,
    DetailSlider,
    Wrap,
    Info,
    Rules,
    Description,
    MapInfo,
    DetailRating,
    Rating,
    BaseComments,
    DetailOtherComments,
    Cart,
    BannerGuarant,
    Aside,
    BasePath,
    DetailCardHotel,
    DetailCardHotelPlaceholder,
    DetailRoomsList,
    DetailTop,
    DetailSuperhost,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showPanelScroll: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      show: true,
      isGreen: true,
      detail: null,
      properties: null,
      price: null,
      rules: null,
      location: null,
      planning: null,
      basicProperties: null,
      basicAvailability: null,
      reviews: null,
      owner: null,
      media: null,
      mediaVideo: null,
      idMoreReviews: null,
      comments: null,
      otherReviews: [],
      object: null,
      metro: null,
      shortDescription: null,
      itemtype: "http://schema.org/Product",
      pageOther: 1,
      lastPageOther: null,
      loadComments: false,
      permissionsSuperHost: "",
      hotelTypes,
      viewImage: false,
      reviewsRatingCount: 0,
      isFacilities: true,
      priceCurrency: "",
      isOffer: false,
      scrollToFooterObserver: null,
      priceLoaded: false
    };
  },
  watch: {
    getHotelData(val) {
      this.setParkingInPropertiesForHotel(val?.hotel);
    },
    refForScroll(val) {
      if (val && this.$refs[val]) {
        window.scrollTo({
          top:
            this.$refs[val].$el.getBoundingClientRect().top +
            window.scrollY -
            10,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.setRefForScroll("");
        }, 1500);
      }
    },
    priceLoaded(val) {
      if(val) {
        sutochnoMetrika.detail('view', '', {
          ...this.commonParamsForMetrika,
          page: "object",
        })
      }
    }
  },
  computed: {
    ...mapState("user", ["isAuth", "userData"]),
    ...mapState("detail", ["hotelDataLoad", "loadingComments", "loadingRatings", "currentOffer", "refForScroll"]),
    ...mapState("search", ["checkIn", "checkOut, filters"]),
    ...mapGetters("detail", [
      "isBronevik",
      "isHotel",
      "getHotelData",
      "getHotelReviews",
      "hotelAvgRating",
      "getObjectData",
      "getObjectReviews",
      "getObjectPrice",
      "avgRating",
      "getDataForYM",
      "getQuery",
      "hasAlwaysAbility",
    ]),
    ...mapGetters("search", ["maxGuests", "commonParamsForMetrika"]),
    ...mapGetters('user', ['auth']),
    titleObject() {
      if (this.isHotel) {
        if (this.getObjectData?.hotel?.title) {
          const title = this.getObjectData.hotel.title;
          const typeHotel = this.getObjectData.hotel.type;
          const titleObject = title ? `${typeHotel} ${title}` : typeHotel;
          return String(titleObject);
        } else {
          return "";
        }
      } else {
        const findTitle =
          this.getObjectData.properties?.enter_name?.properties?.find(
            (item) => item.name === "name_object"
          )?.value;
        if (findTitle) return String(findTitle);
        else return "";
      }
    },
    srcAvatar() {
      return this.detail.object.owner.avatar.replace("/small/", "/medium/");
    },
    isShowMap() {
      return this.$route.query.showMap;
    },
    isShowMetro() {
      return Boolean(this.$route.query.isShowMetro);
    },
    isShowComments() {
      return this.$route.query.showComments;
    },
    offerId() {
      return this?.$route?.query?.offer_id > 0
        ? Number(this.$route.query.offer_id)
        : false;
    },
    hotId() {
      return this?.$route?.query?.hot_id > 0
        ? Number(this.$route.query.hot_id)
        : false;
    },
    ropeway() {
      if (this.location.relations) {
        const ropeway = [];
        for (const item of JSON.parse(
          JSON.stringify(this.location.relations)
        )) {
          if (item.value < 100) {
            item.value = this.t(
              "distance_m",
              { n: Math.round(item.value / 10) * 10}
            );
          } else if (item.value < 950) {
            const a = String(item.value).slice(0, 1);
            const b = String(item.value).slice(1);
            item.value = this.t(
              "distance_m",
              { n: Math.round(b / 100) === 1 ? `${a}50` : `${a}00`}
            );
          } else {
            item.value = this.t(
              "distance_km",
              { n: Math.round(item.value / 100) / 10 }
            ).replace(".", ",");
          }
          ropeway.push(item);
        }
        return ropeway;
      } else {
        return null;
      }
    },
    isRoomsList() {
      if (
        this.getHotelData &&
        this.getHotelData.objects &&
        this.getHotelData.objects.length > 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    countReviews() {
      if (this.isHotel) {
        return this.getHotelReviews.count;
      } else {
        return this.commentsData.commentsCount;
      }
    },
    maxRatingExternal() {
      const externalReviews = this.getObjectData.external_reviews;
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"]
          ? externalReviews[0]["rating"]
          : 0;
        const ratingBooking = externalReviews[1]["rating"]
          ? externalReviews[1]["rating"]
          : 0;

        return Math.max(ratingAirbnb, ratingBooking);
      } else {
        return 0;
      }
    },
    maxRatingExternalHotel() {
      const externalReviews = this.getHotelData?.hotel?.external_reviews;
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"]
          ? externalReviews[0]["rating"]
          : 0;
        const ratingBooking = externalReviews[1]["rating"]
          ? externalReviews[1]["rating"]
          : 0;

        return Math.max(ratingAirbnb, ratingBooking);
      } else {
        return 0;
      }
    },
    countReviewsExternal() {
      const externalReviews = this.getObjectData.external_reviews;
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"]
          ? externalReviews[0]["count"]
          : 0;
        const countBooking = externalReviews[1]["count"]
          ? externalReviews[1]["count"]
          : 0;

        return countAirbnb + countBooking;
      } else {
        return 0;
      }
    },
    countReviewsExternalHotel() {
      const externalReviews = this.getHotelData?.hotel?.external_reviews;
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"]
          ? externalReviews[0]["count"]
          : 0;
        const countBooking = externalReviews[1]["count"]
          ? externalReviews[1]["count"]
          : 0;

        return countAirbnb + countBooking;
      } else {
        return 0;
      }
    },
    isExternalRating() {
      const countReviews = this.isHotel
        ? this.rating?.count
        : this.data.count_reviews;
      if (countReviews <= 1 && this.maxRatingExternal > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.$route.query.showMap === 'true') {
      this.$store.commit('detail/setRefForScroll', 'panelMap');
    }
  },
  async mounted() {
    setViewedObjectsStorage(this.id);
    if (this.$route.query.review) {
      this.$router.push({
        name: "Review",
        params: {
          objectId: this.$route.params.id,
        },
      });
    }
    getUserIP();
    await this.init();
    // await this.otherComments(this.pageOther);

    //Если есть hotId и offerId обращаемся к getOffersListToUser и получаем список предложений, если id объекта в этом списке нет, показываем стандартную стоимость
    if (this.hotId && this.offerId) {
      await this.setOfferDataForUser()
      if(!this.currentOffer) {
        return;
      }
      this.isOffer = true;
    }

    this.initScrollObserver();

    setTimeout(() => {
      Detail.gtmObjectPage(
        this.id,
        this.getObjectPrice?.price || this.getObjectPrice?.price_default
      );
      this.dataLayerPush("view_item_list");
    }, 3000);

    const dataForYM = this.getDataForYM('detail', this.$route.name);
    pushDataToYM(dataForYM);

    localStorage.setItem("hasAlwaysAbility", this.hasAlwaysAbility);

  },
  beforeUpdate() {
    window.removeEventListener("scroll", this.scrollToReview);
  },
  updated() {
    window.addEventListener("scroll", this.scrollToReview);
  },
  unmounted() {
    this.scrollToFooterObserver?.disconnect();
  },
  methods: {
    ...mapActions("detail", [
      "setObjectData",
      "setHotelData",
      "setHotelAvgRating",
      "setAvgRating",
      "fetchOffersListToUser",
      "setCurrentOffer"
    ]),
    ...mapMutations("detail", [
      "setRefForScroll",
    ]),
    ...mapMutations("search", ["setCheckIn", "setCheckOut"]),
    setParkingInPropertiesForHotel(hotel) {
      if (this.isHotel && hotel) {
        const parking = hotel.properties?.parking;
        if (parking) {
          this.properties['parking'] = JSON.parse(JSON.stringify(parking));
        }
      }
    },
    async setOfferDataForUser() {
      await this.fetchOffersListToUser(this.hotId)
      this.setCurrentOffer(this.offerId)
      if(!this.currentOffer) {
        return;
      }
      const { hot } = this.currentOffer
      this.setCheckIn(new Date(hot?.dateBegin))
      this.setCheckOut(new Date(hot?.dateEnd))
    },
    scrollToReview() {
      const review = this.$refs.panelReviews?.$el;
      if (review) {
        const top = window.pageYOffset + review.getBoundingClientRect().top;
        if (window.pageYOffset > top - 90 && !this.scrolledToReview) {
          this.scrolledToReview = true;
          Detail.gaScrollToReview("desktop", this.getObjectData.id);
        }
      }
    },
    scrollToRating() {
      window.scrollTo({
        top: this.$refs.panelReviews?.$el.getBoundingClientRect().top - 50,
        behavior: "smooth",
      });
    },
    scrollToMap() {
      window.scrollTo({
        top: this.$refs.panelMap.$el.getBoundingClientRect().top - 50,
        behavior: "smooth",
      });
    },
    initScrollObserver() {
      const { observer, initObserve, targets } = intersectObserver(() => {
        sutochnoMetrika.detail('scroll', '', {
          ...this.commonParamsForMetrika,
          page: "object",
        });
      }, ".sc-footer", { threshold: 0.1 });
      this.scrollToFooterObserver = observer;
      initObserve(this.scrollToFooterObserver, targets);
    },
    async init() {
      await this.setObjectData(Number(this.id))
        .then(async (response) => {
          this.show = response.data.actions.show_object;
          const data = response.data.data;
          this.detail = data;
          this.properties = JSON.parse(JSON.stringify(data.object.properties || []));
          this.setParkingInPropertiesForHotel(this.getHotelData?.hotel);
          this.price = data.object;
          this.rules = data.object || [];
          this.location = data.object.location;
          this.shortDescription = data.object.short_description;
          this.planning = data.object.title;
          this.basicProperties =
            (data.object.properties &&
              data.object.properties.basic_properties &&
              data.object.properties.basic_properties.properties) ||
            [];
          this.basicAvailability =
            (data.object.properties &&
              data.object.properties.availability &&
              data.object.properties.availability &&
              data.object.properties.availability.properties) ||
            [];
          this.reviews = data.reviews;
          this.owner = data.object.owner;
          this.media = data.object.media;
          this.mediaVideo =
            data.object.media_video != "" ? data.object.media_video : {};
          this.object = data.object;
          this.metro = data.object.metro || [];

          if (this.isBronevik)
            sendEventGtag("test_bronevik", {
              view_object: data.object.location.location,
            });
          if (data.object.hotel) {
            await this.setHotelData(data.object.hotel.id);
            await this.setHotelAvgRating(data.object.hotel.id);
          }
          this.onLoadScroll();
        })
        .catch((error) => {
          showError({ statusCode: 404, statusMessage: "Page Not Found" });
        });
      const item = 2;

      if(!this.isHotel) {
        await this.setAvgRating(Number(this.id))
      }
      // await this.setCurrencyPriceAction(this.getObjectData?.id)
      //   .then((response) => {
      //     basePrice = response;
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   });
    },
    onLoadScroll() {
      this.$nextTick(() => {
        if (this.isShowComments) {
          setTimeout(() => {
            this.$refs.panelReviews.$el.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }, 300);
        }
        if (this.isShowMap && this.$refs.panelMap) {
          setTimeout(() => {
            window.scrollTo({
              top: this.$refs.panelMap.$el.getBoundingClientRect().top - 50,
              behavior: "smooth",
            });
          }, 2000);
        }
      });
    },
    otherComments(page) {
      this.loadComments = true;
      return Reviews.getOtherReviews(Number(this.id), this.pageOther)
        .then((response) => {
          const data = response.data.data;
          this.otherReviews = [...this.otherReviews, ...data];
          this.lastPageOther = response.data.meta.last_page;
          this.pageOther += 1;
          this.loadComments = false;
        })
        .catch((error) => {
          console.log("Ajax error:", error);
        });
    }
  },
};
</script>

<style lang="scss">
.detail-desktop {
  display: grid;
  grid-template-areas:
    ". bar bar bar ."
    ". detail-content . detail-aside ."
    ". detail-content-hotel . detail-aside .";

  grid-template-columns: 1fr 613px 20px 320px 1fr;
  grid-template-rows: 54px auto;
  background: #f6f6f6;
  padding-bottom: 20px;

  &.is-hotel {
    grid-template-areas:
      ". bar bar bar ."
      ". detail-content . detail-aside ."
      ". detail-content-hotel . . .";
  }

  .detail-content,
  .grid-area-aside {
    animation: fadein 0.5s;
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .aside__banner {
    margin-bottom: 0;
  }
  .bar {
    grid-area: bar;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 1;

    display: flex;
    align-items: flex-end;

    .breadcrumb {
      padding-bottom: 5px;
      margin-bottom: 0;
      &__item {
        font-size: 13px;
        line-height: 20px;
        padding-right: 25px;
        color: #444;
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .reviews__comments {
    padding: 0;
  }
}

@media only screen and (min-width: 1080px) {
  .detail-desktop {
    grid-template-columns: 1fr 692px 20px 320px 1fr;
  }
}

@media only screen and (min-width: 1280px) {
  .detail-desktop {
    grid-template-columns: 1fr 830px 20px 360px 1fr;
  }
}

.grid-area {
  &-content {
    grid-area: detail-content;
  }
  &-content-hotel {
    grid-area: detail-content-hotel;
  }

  &-aside {
    grid-area: detail-aside;
  }
}

a {
  color: #2d6cb4;
  &:hover,
  &:focus,
  &:active {
    color: #f51449;
  }
}
.detail-banner {
  margin-top: 20px;
}

.card-hotel-wr {
  margin-bottom: -20px;
  position: relative;
  &.is-view-image {
    z-index: 3;
  }
  &.load-block {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .sc-footer-container {
    width: 1224px !important;
  }
}
.hide-title-review {
  .comments__title {
    display: none;
  }
  .reviews--range {
    margin-bottom: 60px !important;
  }
}
</style>
