<i18n lang="json">
{
  "ru": {
    "checkIn": "Заезд",
    "checkOut": "Отъезд",
    "guests": "Гости",
    "dateCheck": "дд.мм",
    "guest": "0|{n} гость|{n} гостя|{n} гостей",
    "childrenAge": "0|{n} год|{n} лет|{n} лет",
    "noComissionText": "Без скрытых комиссий",
    "btnShowPhone": "Показать телефон",
    "share": "Поделиться",
    "busyDate": "На указанные даты объект уже занят",
    "changeDate": "Попробуйте выбрать другие даты",
    "datesPassed": "Указанные даты уже прошли — выберите другие.",
    "maximumGuests":"Обратите внимание! Жилье вмещает максимум",
    "alertAdd": "Обратите внимание! Это жильё максимум для {guests}.",
    "maxGuests": "{n} гостей | {n} гостя | {n} гостей | {n} гостей",
    "maximumChildrenAge":"Обратите внимание! Разрешено размещение детей с",
    "isChildren": "Обратите внимание! Размещение с детьми запрещено",
    "petsError": "Внимание, владелец жилья указал, что не готов принимать гостей с питомцами",
    "button": "Выбрать даты"
  },
  "en": {
    "checkIn": "Check in",
    "checkOut": "Check out",
    "guests": "Guests",
    "dateCheck": "dd.mm",
    "guest": "0|{n} guest|{n} guests|{n} guests",
    "childrenAge": "0|{n} age|{n} age|{n} age",
    "noComissionText": "No hidden fees",
    "btnShowPhone": "Show phone",
    "share": "Share",
    "busyDate": "The object is already busy on the specified dates",
    "changeDate": "Try to select different dates",
    "datesPassed": "The specified dates have already passed - please select others.",
    "maximumGuests":"Note! Accommodation accommodates maximum",
    "alertAdd": "Note! This accommodation is for {guests}.",
    "maxGuests": "{n} guests | {n} guests | {n} guests | {n} guests",
    "maximumChildrenAge":"Note! Allowed to accommodate children from",
    "isChildren": "Note! Children are not allowed",
    "petsError": "Attention, the landlord has indicated that he is not ready to host guests with pets",
    "button": "Select dates"
  }
}
</i18n>
<template>
  <div v-scroll="handleScroll" class="detail-aside-sticky">
    <div ref="aside" class="aside" :style="{ top: asideTop + 'px' }">
      <div
        v-if="!simple"
        class="aside__options options"
        :class="{ 'no-title': !titleObject }"
      >
        <Favorite
          v-if="!whitelabel"
          :id="Number(id)"
          class="options__item options__item_favorite"
        />
        <Share
          class="options__item options__item_share"
          :class="{ show: showShares }"
          @show="showShares = true"
          @close="showShares = false"
        >
          <span class="icon-app-share-simple"></span>
          <span class="options__text">{{ t("share") }}</span>
        </Share>
      </div>

      <div v-if="offerId && hotId" class="aside__wrap">
        <Offer :offer-id="offerId" :hot-id="hotId" />
      </div>

      <div v-else-if="!isEmpty" class="aside__wrap">
        <div class="aside__choise">
          <div v-click-outside="clickOutside" class="aside__dates dates">
            <div
              class="dates__item dates__item_in"
              :class="{
                dates__item_current: activeIn || activeFieldIn,
              }"
              @click.self="datesClickHandler('in')"
            >
              <span v-if="date.in">{{ format(date.in) }}</span>
              <span v-else class="dates__default">{{ t("checkIn") }}</span>
            </div>
            <div
              class="dates__item dates__item_out"
              :class="{
                dates__item_current: activeOut || activeFieldOut,
              }"
              @click.self="datesClickHandler('out')"
            >
              <span v-if="date.out">{{ format(date.out) }}</span>
              <span v-else class="dates__default">{{ t("checkOut") }}</span>
            </div>
            <DatePicker
              ref="calendar"
              v-model="date"
              v-model:date-views="dateViews"
              :fill-mode="fillMode"
              :employment="employment ? employment : []"
              :show-header="!dateViews.in || !dateViews.out"
              :lang="$i18n.locale"
              @visibleCalendar="visibleCalendar = $event"
              @reset="handleReset"
            />
          </div>
          <div class="aside__guests">
            <Guests
              @clickGuests="clickGuests"
              @show="showGuests = true"
              @close="showGuests = false"
            />
            <!-- <SelectGuestsDesktop
              ref="guests"
              :is-detail="true"
              :view="'page-detail'"
              :showPetsDescriptionError="openDetailGuestsModal"
              :isShowModal="true"
              @clickGuests="clickGuests"
              @show="showGuests = true"
              @close="showGuests = false"
            /> -->
            <transition name="fade">
              <div v-if="objectPriceError" class="guests-hint">
                {{ objectPriceError }}
              </div>
            </transition>
            <transition name="fade">
              <div v-if="maxGuestsSearch > maxGuestsObject" class="guests-hint">
                {{
                  this.t("alertAdd", {
                    guests: this.t("maxGuests", maxGuestsObject),
                  })
                }}
              </div>
            </transition>
            <transition name="fade">
              <div v-if="isChildren" class="guests-hint">
                {{ t("isChildren") }}.
              </div>
              <div v-else-if="isMaxAgeChildren && maxAgeChildren > 0" class="guests-hint">
                {{ t("maximumChildrenAge") }}
                {{ t("childrenAge", maxAgeChildren) }}.
              </div>
            </transition>
            <transition name="fade">
              <div v-if="isErrorsBooking" class="guests-hint">
                {{ errorsBooking }}
              </div>
            </transition>
            <transition name="fade">
              <Warning
                v-if="isPetsError"
                vertical="small"
                horizontal="small"
                color="coral"
                :text="t('petsError')"
                :disabled="true"
              />
            </transition>
          </div>
        </div>
        <div ref="asideCalculate" class="aside__calculate">
          <Booking
            v-if="date && date.in && date.out && getObjectPrice && !isBusy"
            :id="id"
            :calc-info="getObjectPrice"
            :object="object"
            :date="date"
            :guests="guests"
            :many-guests="
              maxGuestsSearch > maxGuestsObject || isMaxAgeChildren
                ? true
                : false || isChildren || isErrorsBooking || isPetsError
            "
            @onErrors="onErrorsBooking"
          />
          <Default
            v-else
            :calc-info="calcInfoDefault"
          />
        </div>
      </div>
      <div v-if="isBusy" class="aside__error">
        <p>{{ t("busyDate") }}</p>
        <p>{{ t("changeDate") }}</p>
      </div>
      <div v-if="isDatesPassed" class="aside__error">
        <p>{{ t("datesPassed") }}</p>
      </div>
      <div
        v-if="!isEmpty && (!date || !date.in || !date.out) && !hintHide"
        class="aside__banner"
      >
        <Banner :is-min="true">
          <button
            class="button button_size_md button_simple"
            @click="choiceDates()"
          >
            {{ t("button") }}
          </button>
        </Banner>
      </div>
      <ViewPhone
        :calc-info="getObjectPrice ? getObjectPrice : null"
        :object="object"
        :is-bronevik="isBronevik"
        :is-always="!!whitelabel"
        :whitelabel="whitelabel"
      />
      <!--:is-always="true"-->
      <!-- <Leave /> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { Detail } from "@/utils/gtm/";
import { format } from "@/utils/format";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';

import { HTTP, Orders, Objects } from "@/components/api/";

import DatePicker from "@sutochno/datepickerext";
// import DatePicker from "@sutochno/datepicker";
import Default from "@/components/base/Detail/Aside/Default";
import Booking from "@/components/base/Detail/Aside/Booking";
import ViewPhone from "@/components/base/Detail/Aside/ViewPhone";
import Guests from "./Guests"
import Share from "@/components/base/Detail/Aside/Share";
import Favorite from "@/components/base/Detail/Aside/Favorite";
import Leave from "@/components/base/Detail/Aside/Leave";
import AsidePlaceholder from "@/components/base/Detail/Aside/Placeholder";
import Offer from "@/components/base/Detail/Aside/Offer";
import Banner from "@/components/base/Banner";
import Warning from "@/components/base/BaseWarning";
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import objectTimeLocation from "@/mixins/objectTimeLocation";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4";

export default {
  name: "Aside",
  components: {
    DatePicker,
    Default,
    Booking,
    ViewPhone,
    // SelectGuestsDesktop,
    Guests,
    Share,
    Favorite,
    Leave,
    AsidePlaceholder,
    Offer,
    Banner,
    Warning,
  },
  props: {
    id: {
      type: String,
    },
    object: {
      type: Object,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    offerId: {
      type: [Boolean, Number],
      default: false,
    },
    hotId: {
      type: [Boolean, Number],
      default: false,
    },
    titleObject: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      date: {
        in: this.checkIn,
        out: this.checkOut,
      },
      employment: [
        {
          from: null,
          to: null,
        },
      ],
      calcInfo: null,
      guests: null,
      favorite: false,
      viewIn: false,
      viewOut: false,
      showShares: false,
      dateViews: {
        in: null,
        out: null,
      },
      busy: false,
      hintHide: true,
      activeFieldOut: false,
      activeFieldIn: false,
      visibleCalendar: false,
      showGuests: false,
      cacheGuests: null,
      isErrorsBooking: false,
      errorsBooking: "",
      oldScrollY: 0,
      asideTop: 50,
      // currencyId: 1,
      objectPriceError: ''
    };
  },
  mixins: [objectTimeLocation, dataLayerPushGA4],
  watch: {
    "dateViews.in"(val) {
      if (val) {
        this.activeFieldIn = false;
      }
    },
    visibleCalendar(val) {
      if (!val && this.dateViews.in && !this.dateViews.out) {
        if (this.checkIn && this.checkOut) {
          this.dateViews.in = this.checkIn;
          this.dateViews.out = this.checkOut;
        } else {
          this.dateViews.in = this.date.in;
        }
      }
    },
    "currency.id"() {
      this.getInfoPrice();
    },
    showGuests(val) {
      if (!val) {
        if (this.cacheGuests !== this.guestsNum) {
          this.cacheGuests = null;
          // this.getInfoPrice();
          // this.getCurrencyPrice();
          // this.setQuery();
          this.gaChangeForm(this.object.location.location);
          this.ymChangeForm();
        }
      } else {
        this.cacheGuests = JSON.parse(JSON.stringify(this.guestsNum));
      }
    },
    date: {
      deep: true,
      handler() {
        if (this.date && this.date.in && this.date.out) {
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: this.date.in,
            checkOut: this.date.out,
          });
          this.clickOutside();
          this.getInfoPrice();
          // this.getCurrencyPrice();
        } else {
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: null,
            checkOut: null,
          });
          this.setQuery();
        }
      },
    },
    isAuth(val) {
      if (val) {
        this.getInfoPrice();
        // this.getCurrencyPrice();
      }
    },
    checkIn() {
      this.date.in = this.checkIn;
      this.date.out = this.checkOut;
    },
    checkOut() {
      this.date.in = this.checkIn;
      this.date.out = this.checkOut;
    },
  },
  async created() {
    let countGuests =
      parseInt(this.guestsState.adults) + this.guestsState.childrens.length;
    if (countGuests > this.maxGuestsObject) {
      this.guests = {
        adults: 1,
        childrens: [],
      };
    } else {
      this.guests = this.guestsState;
    }
  },
  mounted() {
    if (this.$route.query?.setOccupied) {
      const URL_PARAMS = new URLSearchParams(window.location.search);
      const OCCUPIED = URL_PARAMS.get("occupied").split(/\s*;\s*/);
      if (!isNaN(Date.parse(OCCUPIED[0])) && !isNaN(Date.parse(OCCUPIED[1]))) {
        this.$store.dispatch("search/setCalendarDates", {
          checkIn: new Date(OCCUPIED[0]),
          checkOut: new Date(OCCUPIED[1]),
        });
      }
    }

    const selectGuests = JSON.parse(localStorage.getItem("select_guests")) ?? {};
    if (this.$route.query.pets) {
      this.$store.commit("detailBooking/setIsPets", true)
      if (selectGuests.pets?.desription) {
        this.$store.commit(
          "detailBooking/setPets",
          selectGuests.pets.desription
        );
      }
      this.$store.commit("detailBooking/setPetsWarning", true);
    }

    this.$nextTick(() => {
      this.$nextTick(() => {
        // TODO: проверить
        // [vue2 comment] хз пока что как придумать, почему то просто изменение date не видит dateViews
        this.date.in = this.checkIn;
        this.date.out = this.checkOut;
        this.dateViews.in = this.checkIn;
        this.dateViews.out = this.checkOut;
        if (!this.checkIn && !this.checkOut) {
          sendEventGtag("dates_sa", {
            click: "object_dates_no",
          });
        } else {
          sendEventGtag("dates_sa", {
            click: "object_dates_yes",
          });
        }
        this.checkStep();
      });
      this.init();

      if (!this.getObjectPrice) {
        this.getInfoPrice();
      }
      if (!this.offerId && !this.hotId)
        this.$nextTick(() => {
          // https://jira.webpower.ru/browse/VUECLIENT-1811
          // this.gaCreateProduct();
          this.dataLayerPush("view_item")
        });
    });

    this.handleScroll();
  },
  computed: {
    ...mapState("user", [
      "viewPort",
      "currency",
      "userData",
      "isAuth",
      "whitelabel",
    ]),
    ...mapState("search", ["checkIn", "checkOut", "location"]),
    ...mapState("search", { guestsState: "guests" }),
    ...mapGetters("search", ["getQuery", "maxGuests", "maxGuestsFreeBabies", "commonParamsForMetrika"]),
    ...mapGetters("detail", [
      "getObjectPrice",
      "isBronevik",
      "isHotel",
      "getObjectPermissions",
      "getGuestRules"
    ]),
    ...mapGetters("detailBooking", ["petsObject"]),
    fillMode() {
      if (this.activeFieldIn) {
        return "in";
      } else if (this.activeFieldOut) {
        return "out";
      } else {
        return null;
      }
    },
    isBusy() {
      if (this.getObjectPermissions.includes("hasNoShowInSearchResult")) {
        return true;
      }
      return this.getObjectPrice && this.getObjectPrice.busy === "busy";
    },
    activeIn() {
      return !!(
        this.visibleCalendar &&
        !this.dateViews.in &&
        !this.dateViews.out
      );
    },
    activeOut() {
      return !!(
        this.visibleCalendar &&
        this.dateViews.in &&
        !this.dateViews.out
      );
    },
    freeBabies() {
      const properties =
        this.object?.properties?.accommodation_rules?.properties;
      return !!properties?.find(item => item.name === 'free_babies')?.value;
    },
    maxGuestsSearch() {
      return this.freeBabies ? this.maxGuestsFreeBabies : this.maxGuests;
    },
    maxGuestsObject() {
      if (this.isEmpty) return 0;
      /** Максимальное количество гостей вмещаемое объектом */
      return this.object.properties.basic_properties.properties.find(
        (item) => item.name == "max_guests"
      ).value;
    },
    maxAgeChildren() {
      if (this.object.properties.accommodation_rules) {
        const isChildren = this.object.properties.accommodation_rules.properties.find(
          (el) => el.name === "is_children"
        )
        if(isChildren !== 1){
          return 0;
        }
        const childrenAgeValues =
          this.object.properties.accommodation_rules.properties.filter(
            (el) => el.name === "children_age"
          );
        if (childrenAgeValues.length) {
          return childrenAgeValues[0].value ?? 0;
        }
      }
      return 0;
    },
    isMaxAgeChildren() {
      if (this.guests.childrens.length) {
        const checkCildren = this.guests.childrens.filter(
          (el) => el < this.maxAgeChildren
        );
        if (Boolean(checkCildren.length)) {
          return true;
        }
      }
      this.onErrorsBooking(false);
      return false;
    },
    isChildren() {
      if (this.object.properties.accommodation_rules) {
        const children =
          this.object.properties.accommodation_rules.properties.filter(
            (el) => el.name === "is_children"
          );
        if (
          children[0] &&
          children[0].value === 0 &&
          this.guests.childrens.length
        ) {
          return true;
        }
      }
      this.onErrorsBooking(false);
      return false;
    },
    guestsNum() {
      return this.maxGuests;
    },
    dateCheck() {
      if (this.object.properties?.check_in_out_rules) {
        return this.object.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.object.properties?.additional_payment?.properties?.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
    isPetsError() {
      return (
        this.petsObject.isPets &&
        this.object.properties?.accommodation_rules?.properties?.find(
          (p) => p.name === "pets"
        )?.value === 0
      );
    },
    isDatesPassed() {
      const today = new Date();
      today.setDate(today.getDate() - 1);
      today.setHours(23, 59, 59);
      if (!this.date.in || !this.date.out) {
        return false;
      }
      return this.date.in.getTime() < today.getTime() || this.date.out.getTime() < today.getTime();
    },
    isDatesYearAhead() {
      if (!this.date.in || !this.date.out) {
        return false;
      }
      const lastAllowedDateIn = new Date();
      lastAllowedDateIn.setHours(this.date.in.getHours(), this.date.in.getMinutes(), this.date.in.getSeconds());
      lastAllowedDateIn.setDate(lastAllowedDateIn.getDate() - 2);
      lastAllowedDateIn.setUTCFullYear(lastAllowedDateIn.getUTCFullYear() + 1);

      const lastAllowedDateOut = new Date();
      lastAllowedDateOut.setHours(this.date.out.getHours(), this.date.out.getMinutes(), this.date.out.getSeconds());
      lastAllowedDateIn.setDate(lastAllowedDateIn.getDate() - 1);
      lastAllowedDateOut.setUTCFullYear(lastAllowedDateOut.getUTCFullYear() + 1);
      return this.date.in.getTime() > lastAllowedDateIn.getTime() || this.date.out.getTime() > lastAllowedDateOut.getTime();
    },
    calcInfoDefault() {
      return this.getObjectPrice ||
        (!this.date.in && !this.date.out) ||
        this.isDatesPassed || this.isDatesYearAhead;
    },
  },
  methods: {
    ...mapActions("detail", [
      "setObjectPrice",
      // "setCurrecyPrice",
    ]),
    async checkStep() {
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      const step = this.$route.query.step;
      setTimeout(() => {
        if (step === "calendar") {
          this.$refs.calendar.toggle();
        }
        // else if (step === "guests") {
        //   this.$refs.guests.isSelect = true;
        // }
      }, 1500);
    },
    clickGuests() {
      sendEventGtag("dates_sa", {
        click: "object_guests",
      });

      sutochnoMetrika.detail('click', 'guests', {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
    handleReset() {
      this.activeFieldIn = false;
      this.activeFieldOut = false;
    },
    checkInOpen() {
      if (this.dateViews.in) {
        this.activeFieldOut = false;
        this.activeFieldIn = true;
      }
      this.$refs.calendar.show();
    },
    async datesClickHandler(type) {
      if(type === 'in') {
        this.checkInOpen();
        await this.checkInAnalytics();
        return;
      }
      this.checkOutOpen();
      await this.checkOutAnalytics();
    },
    checkInAnalytics() {
      sendEventGtag("dates_sa", {
        click: "object_dates_in",
      });

      sutochnoMetrika.detail('click', 'dates', {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
    checkOutOpen() {
      if (this.dateViews.out) {
        this.activeFieldIn = false;
        this.activeFieldOut = true;
      }
      this.$refs.calendar.show();
    },
    checkOutAnalytics() {
      sendEventGtag("dates_sa", {
        click: "object_dates_out",
      });

      sutochnoMetrika.detail('click', 'dates', {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
    handleScroll(evt, el) {
      let scrollY = window.scrollY;
      if (scrollY > 50) {
        this.hintHide = false;
        // если скролл пошел вверх
        if (scrollY < this.oldScrollY) {
          let newAsideTop = this.asideTop - (scrollY - this.oldScrollY);
          this.asideTop = newAsideTop >= 50 ? 50 : newAsideTop;
        } else {
          //прокрутка блока aside вместе с общей прокруткой
          let diff =
            this.$refs.asideCalculate?.getBoundingClientRect().bottom -
            document.documentElement.clientHeight;
          if (diff > 0) {
            let scrollDiff = scrollY - this.oldScrollY;
            if (scrollDiff > diff) {
              this.asideTop = this.asideTop - diff;
            } else {
              this.asideTop = this.asideTop - scrollDiff;
            }
          }
        }
      } else {
        this.hintHide = true;
      }
      this.oldScrollY = scrollY;
    },
    choiceDates() {
      setTimeout(() => {
        this.$refs.calendar.toggle();
      }, 100);
    },
    // https://jira.webpower.ru/browse/VUECLIENT-1811
    // gaCreateProduct() {
    //   Detail.gaCreateProduct(
    //     this.id,
    //     String(
    //       this.object.extprices?.find((item) => item.interval_start == 0)
    //         ?.value || "?"
    //     ),
    //     "5",
    //     this.checkIn || this.date.in
    //       ? format(this.checkIn ? this.checkIn : this.date.in)
    //       : null,
    //     this.checkOut || this.date.out
    //       ? format(this.checkOut ? this.checkOut : this.date.out)
    //       : null,
    //     this.object.title,
    //     this.object.location?.location,
    //     String(this.object.type_title).charAt(0).toUpperCase() +
    //       String(this.object.type_title).substr(1),
    //     `${this.t(
    //       "rooms",
    //       this.object?.properties?.basic_properties?.properties?.find(
    //         (item) => item.name == "rooms"
    //       )?.value || 1
    //     )}`,
    //     this.object.position,
    //     this.isHotel ? "Отель" : "Отдельный объект"
    //   );
    // },
    setQuery(is_hot_sale = false) {
      const query = {
        hash: this.getQuery.hash,
        query: _.omit(this.getQuery.query, ["lat", "lng", "zoom"]),
      };
      if (is_hot_sale) {
        query.query.burning_offer = 1;
      } else {
        delete query.query.burning_offer;
      }

      navigateTo(query);
    },
    init() {
      Orders.getOrdersByObject(Number(this.id))
        .then((response) => {
          const calendars = response.data.data.calendars;
          /**
           * соберем массив дат и заменим пробел на символ T для корректного парсинга дат в ios
           */
          this.employment = calendars.map((item) => ({
            from: new Date(item.date_begin.replace(" ", "T")),
            to: new Date(item.date_end.replace(" ", "T")),
          }));
        })
        .catch((error) => {
          console.log("Ajax error: Orders.getOrdersByObject, ", error);
        });
    },
    gaChangeForm(value) {
      return Detail.gaChangeForm(value);
    },
    ymChangeForm() {
      return Detail.ymChangeForm();
    },
    clickOutside() {
      this.handleReset();
      this.$refs.calendar?.close();
    },
    format(date) {
      if (!(date instanceof Date)) return "";
      const day = date.toLocaleString("ru-RU", {
        day: "numeric",
      });
      const month = date
        .toLocaleString("ru-RU", {
          month: "short",
        })
        .slice(0, 3);
      const weekday = date.toLocaleString("ru-RU", {
        weekday: "short",
      });
      return `${day} ${month}, ${weekday}`.toLocaleLowerCase();
    },
    setOccupied() {
      this.clickOutside();
      this.$store.dispatch("search/setCalendarDates", {
        checkIn: this.date.in,
        checkOut: this.date.out,
      });
    },
    getInfoPrice: _.debounce(function () {
      if (
        ((this.checkIn && this.checkOut) || (this.date.in && this.date.out)) &&
        this.guestsNum
      ) {
        this.setObjectPrice({
          guests: Number(this.guestsNum),
          time_begin:
            !this.dateCheck?.in || this.dateCheck.in == ""
              ? "14"
              : this.dateCheck.in.match(/\d{2}/),
          time_end:
            !this.dateCheck?.in || this.dateCheck.in == ""
              ? "11"
              : this.dateCheck.out.match(/\d{2}/),
          date_begin: this.formatDateGetPrices("in"),
          date_end: this.formatDateGetPrices("out"),
          objects: Number(this.id),
          currency_id: this.currency.id,
          no_time: 1,
        }).then(response => {
          const isHotSale = !!response?.[0]?.data?.object_hot?.sale;
          this.setQuery(isHotSale);
          this.objectPriceError = '';
        }).catch(e => {
          this.objectPriceError = e;
        }).finally(() => {
          this.$emit("priceLoaded");
        });
      } else {
        this.$emit("priceLoaded");
      }
    }, 300),
    // getCurrencyPrice() {
    //   if(this.currencyId != 1) {
    //     this.setCurrecyPrice({
    //       guests: Number(this.guestsNum),
    //       time_begin:
    //         !this.dateCheck.in || this.dateCheck.in == ""
    //           ? "14"
    //           : this.dateCheck.in.match(/\d{2}/),
    //       time_end:
    //         !this.dateCheck.in || this.dateCheck.in == ""
    //           ? "11"
    //           : this.dateCheck.out.match(/\d{2}/),
    //       date_begin: this.formatDateGetPrices("in"),
    //       date_end: this.formatDateGetPrices("out"),
    //       objects: Number(this.id),
    //       currency_id: this.getCurrencyObject?.id,
    //       no_time: 1,
    //     })
    //   }
    // },
    formatDateGetPrices(elem) {
      if (!this.date.in && !this.date.out) return false;
      const format = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, 0);
        const day = date.getDate().toString().padStart(2, 0);
        const hours =
          this.dateCheck?.[elem] && this.dateCheck?.[elem] != ""
            ? this.dateCheck[elem].match(/\d{2}|\d{1}/)
            : elem == "in"
              ? "14"
              : "11";
        const minutes = date.getMinutes().toString().padStart(2, 0);
        const seconds = date.getSeconds().toString().padStart(2, 0);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
      return format(this.date[elem]);
    },
    formatTimeGetPrices(elem) {
      if (!this.date.in && !this.date.out) return false;
      const format = (date) => {
        const hours = date.getHours().toString().padStart(2, 0);
        return `${hours}`;
      };
      return format(this.date[elem]);
    },
    onErrorsBooking(errors) {
      if (errors) {
        this.isErrorsBooking = true;
        this.errorsBooking = errors.join(". ");
      } else {
        this.isErrorsBooking = false;
        this.errorsBooking = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 50px;
  color: #000;
  font-size: 14px;
  z-index: 3;

  .guests-hint {
    background: #fff6c4;
    padding: 10px;
    margin-top: 20px;
  }

  &__banner {
    margin: 20px 0;
  }

  &__error {
    background: #fdd2d2;
    color: #e10600;
    font-size: 13px;
    line-height: 17px;
    padding: 10px 10px 10px 36px;
    position: relative;
    width: 100%;
  }

  &__options {
    margin-bottom: 7px;
    display: flex;
    justify-content: flex-end;
    padding-top: 41px;
    &.no-title {
      padding-top: 5px;
    }
    .options {
      &__item {
        display: flex;
        // align-items: center;
        cursor: pointer;
        //width: 42px;
        height: 42px;
        margin-right: 10px;
        transition: width 0.3s;

        &_favorite {
          padding: 10px 0 10px 9px;
          margin-right: 2px;
          :deep(.options__text) {
            padding-top: 2px;
          }
        }
        &_share {
          padding: 13px;
          .options__text {
            margin-top: -1px;
          }
        }

        width: 137px;
        .options__text,
        :deep(.options__text) {
          display: block;
          animation: display-none-transition 0.2s both 0.2s;
        }
        :deep(.icon-app-heart),
        .icon-app-share-simple {
          color: #000;
        }

        &:hover,
        &.show {
          :deep(.icon-app-heart),
          .icon-app-share-simple {
            color: #f51449;
          }
        }

        &:last-child {
          margin-right: 0;
          width: 122px;
        }
        :deep(.icon-app-heart),
        .icon-app-share-simple {
          color: #000;
        }
        .icon-app-share-simple {
          font-size: 16px;
        }
      }
      @keyframes display-none-transition {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .options__text,
    :deep(.options__text) {
      display: none;
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  &__wrap {
    background: #fff;
    padding: 20px;
    border-radius: 8px;

    :deep(.booking__button) {
      border-radius: 0 0 8px 8px;
    }
  }

  :deep(.sc-datepickerext-wrapper) {
    top: calc(100% + 4px);
    right: 0;
  }

  &__dates {
    display: flex;
    position: relative;
    .dates {
      &__item {
        cursor: pointer;
        padding: 11px 15px;
        width: 50%;
        margin-left: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        line-height: normal;
        position: relative;
        transition: 0.3s;

        &_current {
          border: 1px solid #e10600;
        }

        &:first-child {
          margin-left: 0;
        }
        > span {
          pointer-events: none;
        }
      }
      &__default {
        color: #717171;
      }
    }
  }
  &__guests {
    margin-top: 10px;
  }
  &__calculate {
    margin-top: 20px;
  }
}
@media screen and (max-width: 359px) {
  .aside__dates {
    .dates__item {
      &_in {
        .sc-datepickerext-wrapper {
          right: -159px;
        }
      }
      &_out {
        .sc-datepickerext-wrapper {
          right: -24px;
        }
      }
    }
  }
}
@media screen and (min-width: 360px) and (max-width: 659px) {
  .aside__dates {
    .dates__item {
      &_in {
        .sc-datepickerext-wrapper {
          left: 0;
          right: initial;
        }
      }
      &_out {
        .sc-datepickerext-wrapper {
          right: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 767px) {
  .aside__dates {
    .dates__item {
      &_in {
        .sc-datepickerext-wrapper {
          right: 0;
        }
      }
      &_out {
        .sc-datepickerext-wrapper {
          right: 0;
        }
      }
    }
  }
}
</style>
