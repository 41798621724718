<i18n lang="json">
{
	"ru": {
    "LeaveComplaint": "Пожаловаться",
    "leave": "Жалоба",
    "error": "Необходимо заполнить это поле",
    "placeholderMessage": "Опишите проблему",
    "placeholderContacts": "Введите email или телефон",
    "send": "Отправить жалобу",
    "cancel": "Отмена",
    "close": "Закрыть",
    "thanks": "Спасибо"
	},
	"en": {
    "LeaveComplaint": "Leave",
    "leave": "Leave",
    "error": "This field is required",
    "placeholderMessage": "Describe the problem",
    "placeholderContacts": "Phone number or email address",
    "send": "Send the complaint",
    "cancel": "Cancel",
    "close": "Close",
    "thanks": "Thanks"
	}
}
</i18n>
<template>
  <div class="detail-leave">
    <div class="aside-line aside-line-text-pad text-center">
      <p class="text-center text-small complain" @click="showModal()">
        <span class="icon-app-exclamation-triangle"></span>
        {{ t("LeaveComplaint") }}
      </p>
    </div>
    <BaseModal ref="modalLeave" class="modal__leave" :title="t('leave')">
      <div class="leave__body">
        <div class="leave__message">
          <textarea
            ref="message"
            v-model="message"
            :placeholder="t('placeholderMessage')"
          ></textarea>
        </div>
        <div class="leave__contacts">
          <input
            ref="contacts"
            v-model="contacts"
            type="text"
            :placeholder="t('placeholderContacts')"
          />
        </div>
        <div class="leave__buttons">
          <button class="button" @click="hideModal()">
            {{ t("cancel") }}
          </button>
          <button class="button button_blue" @click="onSubmit()">
            {{ t("send") }}
          </button>
        </div>
      </div>
    </BaseModal>
    <BaseModal ref="modalComplete" class="modal__complete" :title="t('leave')">
      <div class="complete__body">
        <div class="complete__text">
          <p>{{ t("thanks") }}!</p>
        </div>
        <div class="complete__buttons">
          <button class="button" @click="$refs.modalComplete.close()">
            {{ t("close") }}
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import BaseModal from "@/components/base/BaseModal";
import { Feedback } from "@/components/api/";
import { useI18n } from 'vue-i18n';

export default {
  name: "Leave",
  components: {
    BaseModal,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      message: null,
      contacts: null,
    };
  },
  methods: {
    showModal() {
      this.$refs.modalLeave.show();
    },
    hideModal() {
      this.$refs.modalLeave.close();
    },
    onSubmit() {
      if (this.$refs.contacts.value == "") {
        this.$refs.contacts.classList.add("error");
        this.$refs.contacts.placeholder = this.t("error");
      } else {
        this.$refs.contacts.classList.remove("error");
        this.$refs.contacts.placeholder = this.t("placeholderContacts");
      }
      if (this.$refs.message.textLength == 0) {
        this.$refs.message.classList.add("error");
        this.$refs.message.placeholder = this.t("error");
      } else {
        this.$refs.message.classList.remove("error");
        this.$refs.message.placeholder = this.t("placeholderMessage");
      }
      if (
        this.$refs.contacts.value != "" &&
        this.$refs.message.textLength != 0
      ) {
        this.sendAbuse();
        this.hideModal();
        this.message = null;
        this.contacts = null;
        this.$refs.modalComplete.show();
      }
    },
    sendAbuse() {
      Feedback.sendAbuseEmail({
        type: "objectAbuse",
        contacts: this.message,
        text: this.contacts,
        object_id: this.objectId,
        object_url: window.location.href,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-leave {
  padding-top: 10px;
}

.complain {
  color: #717171;
  cursor: pointer;
  .icon-app-exclamation-triangle {
    font-size: 16px;
    line-height: 16px;
    padding-right: 10px;
  }
  &:hover {
    color: #2d6cb4;
  }
}
.modal__leave {
  :deep(.base-modal-header) {
    padding: 0 10px;
  }

  .leave {
    &__body {
      padding: 20px;
    }
    &__message,
    &__contacts {
      .error {
        border: 1px solid #ff0306;
      }
      textarea,
      input {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 10px;
        outline: none;
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        font-size: 14px;
      }
    }
    &__message {
      textarea {
        min-height: 105px;
        resize: none;
      }
    }
    &__contacts {
      input {
        margin-top: 10px;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      padding-top: 30px;

      .button {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
.modal__complete {
  :deep(.base-modal-header) {
    padding: 0 10px;
  }
  .complete {
    &__text {
      display: flex;
      justify-content: center;

      font-size: 22px;
      padding: 20px;
    }
    &__buttons {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
  }
}
</style>
