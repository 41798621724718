import { useStore } from "vuex";
import { computed } from "vue";

const useAuth = () => {
  const store = useStore();
  const auth = computed(() => store.getters["user/auth"]);
  return { isAuth: auth?.value?.isAuth, id: auth?.value?.data?.id || "" };
};

export { useAuth };

export default {
  useAuth,
};