<template>
  <div class="card-hotel placeholder">
    <div class="card-hotel--img">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="card-hotel--title">
      <div class="info__item"></div>
    </div>
    <div class="card-hotel--data">
      <div class="info__item"></div>
      <div class="info__item"></div>
      <div class="info__item"></div>
    </div>
    <div class="card-hotel--desk">
      <div class="info__item"></div>
    </div>
    <div class="card-hotel--facilities">
      <div class="title">
        <div class="info__item"></div>
      </div>
      <div class="list">
        <div class="item">
          <div class="info__item"></div>
        </div>
      </div>
    </div>
    <div class="card-hotel--more-facilities">
      <div class="info__item"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailCardHotelPlaceholder",
};
</script>

<style lang="scss" scoped>
.card-hotel {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -10%;
    top: -10%;
    bottom: -10%;
    width: 30px;
    opacity: 0;
    background: #fff;
    animation: loader 1.3s linear infinite;
    filter: blur(20px);
    transform: rotate(25deg);
  }
  &--img {
    display: grid;
    grid-template-columns: 244px 1fr;
    grid-template-areas:
      "img-2 img-1"
      "img-3 img-1";
    margin-bottom: 30px;
    > div {
      background-color: #ededef;
      background-image: url("~/assets/img/no_photo.svg");
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 9px;

      &:nth-child(1) {
        grid-area: img-1;
        height: 252px;
      }
      &:nth-child(2) {
        grid-area: img-2;
        margin-right: 10px;
        height: 121px;
        margin-bottom: 10px;
      }
      &:nth-child(3) {
        grid-area: img-3;
        margin-right: 10px;
        height: 121px;
      }
    }
  }
  &--title {
    margin-bottom: 16px;
    .info__item {
      height: 25px;
      width: 50%;
      background: #d8d8d8;
      border-radius: 15px;
    }
  }
  &--data {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .info__item {
      height: 15px;
      width: 60px;
      background: #d8d8d8;
      border-radius: 15px;
      margin-right: 30px;
    }
  }
  &--desk {
    margin-bottom: 10px;
    .info__item {
      height: 15px;
      width: 80%;
      background: #d8d8d8;
      border-radius: 15px;
      margin-bottom: 10px;
      &:last-child {
        width: 70%;
        margin-bottom: 0;
      }
    }
  }
  &--facilities {
    margin-bottom: 20px;
    .title {
      .info__item {
        height: 25px;
        width: 20%;
        background: #d8d8d8;
        border-radius: 15px;
        margin-bottom: 10px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex: 1 1 auto;
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        padding: 4px 0;

        &:nth-child(odd) {
          margin-right: 10px;
        }

        &:nth-child(even) {
          margin-left: 10px;
        }
        .info__item {
          height: 15px;
          width: 100%;
          background: #d8d8d8;
          border-radius: 15px;
        }
      }
    }
  }
  &--more-facilities {
    .info__item {
      height: 42px;
      width: 20%;
      background: #d8d8d8;
      border-radius: 15px;
    }
  }
}

@keyframes loader {
  from {
    left: -10%;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
</style>
