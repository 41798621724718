import HTTP from "../http-common";

/**
 * Обратная связь
 *
 * @module sendAbuseEmail
 * @param {object} payload Данные для обратной связи
 * @param {string} payload.type Тип обратной связи пример: 'objectAbuse'
 * @param {string} payload.contacts Контакты
 * @param {string} payload.text Текст сообщения
 * @param {string} payload.object_id id объекта с которого пришла обртная связь
 * @param {string} payload.object_url Ссылка на объект
 * @return {object} Promise
 */
export default (payload) => {
  let objectData = new FormData();
  objectData.append("params[type]", payload.type || "");
  objectData.append("params[text][contact]", payload.contacts || "");
  objectData.append("params[text][text]", payload.text || "");
  objectData.append("params[object_id]", payload.object_id || "");
  objectData.append("params[object_url]", payload.object_url || "");

  return HTTP.post("json/feedback/sendAbuseEmail", objectData, {
    headers: {
      // "api-version":  // Если указать версию, то будет минимум данных
    },
  });
};
