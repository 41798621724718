<i18n lang="json">
{
	"ru": {
    "rulHome": "Правила объекта размещения",
    "rulChekIn": "Заезд",
    "rulChekOut": "Отъезд",
    "rulMinState": "Минимальный срок проживания",
    "after": "после",
    "before": "до",
    "from": "от",
    "deposit": "страховой депозит (возвращается при отъезде)",
    "accommodationRules": {
      "smoking":{
        "0": "курение запрещено",
        "1": "курение разрешено",
        "3": "курение разрешено в специально отведенных местах"
      },
      "pets":{
        "0": "нельзя с питомцами",
        "1": "можно с питомцами",
        "2": "можно с питомцами по согласованию с хозяином жилья"
      },
      "party":{
        "0": "без вечеринок и мероприятий",
        "1": "можно проводить вечеринки и мероприятия",
        "2": "вечеринки и мероприятия по согласованию с хозяином жилья"
      },
      "isolation":{
        "1": "самоизоляция разрешена"
      }
    },
    "is_children": {
      "0": "нельзя с детьми",
      "1": {
        "0": "можно с детьми любого возраста",
        "1": "можно с детьми от 1 года и старше",
        "2": "можно с детьми от {age} лет и старше"
      },
      "2": "можно с детьми по согласованию с хозяином жилья"
    },
    "is_children_free_babies": "нельзя с детьми до {age} лет, но можно с детьми 1 года и младше",
    "free_babies": "дети 1 года и младше размещаются бесплатно, но без дополнительного спального места",
    "depositHint": "Залог за сохранность имущества. Хозяин может взять его при заселении и вернуть при отъезде, если ничего не испорчено.",
    "documents_0": "владелец не предоставляет отчетные документы о проживании",
    "documents_1": "владелец предоставляет отчетные документы о проживании",
    "documents_2": "владелец предоставляет отчетные документы о проживании по согласованию",
    "ages": "0|c {n} года | {n} лет| {n} лет",
    "day": "0|{n} суток |{n} суток |{n} суток",
    "selectDates": "выберите даты",
    "note": "Обратите внимание!",
    "code": "{n}",
    "calculationMade": "Расчет при заселении с арендодателем будет производиться в",
    "prepaymentPrompt": "Вы внесёте предоплату через Суточно.ру в рублях по курсу на {n}. Оставшуюся сумму нужно отдать хозяину при заселении — возможен расчёт в национальной валюте страны, в которой находится объект (по курсу на момент заселения).",
    "hotelCleaningCostHint": "Плата за уборку не входит в стоимость проживания — вам нужно дополнительно оплатить уборку при заселении."
	},
	"en": {
    "rulHome": "Object's posting rules",
    "rulChekIn": "Check-in time is",
    "rulChekOut": "Check-out by",
    "rulMinState": "Minimum stay",
    "after": "after",
    "before": "before",
    "from": "from",
    "deposit": "security deposit (refundable upon departure)",
    "accommodationRules": {
      "smoking":{
        "0": "no smoking",
        "1": "smoking allowed",
        "3": "smoking is allowed in designated areas"
      },
      "pets":{
        "0": "pets are not allowed",
        "1": "pets allowed",
        "2": "it is possible with pets in agreement with the landlord"
      },
      "party":{
        "0": "no parties and events",
        "1": "parties allowed",
        "2": "parties and events as agreed with the host"
      },
      "isolation":{
        "1": "self-isolation allowed"
      }
    },
    "is_children": {
      "0": "not with children",
      "1": {
        "0": "possible with children of any age",
        "1": "possible with children from 1 year old and older",
        "2": "it is possible with children from {age} years old and older"
      },
      "2": "it is possible with children in agreement with the landlord"
    },
    "is_children_free_babies": "not allowed with children under {age} years old, but allowed with children 1 year and younger",
    "free_babies": "children 1 year and younger are accommodated free of charge, but without an extra bed",
    "depositHint": "Security deposit. The owner can take it upon check-in and return it upon departure, if nothing is spoiled.",
    "documents_0": "the owner does not provide accounting documents about the residence",
    "documents_1": "the owner provides accounting documents about the residence",
    "documents_2": "the owner provides accounting documents on residence as agreed",
    "ages": "0| c {n} years | {n} years | {n} years",
    "day": "0|{n} day |{n} day |{n} day",
    "selectDates": "select dates",
    "note": "Note!",
    "code": "{n}",
    "calculationMade": "Settlement with the landlord will be made in",
    "prepaymentPrompt": "You will make a prepayment via Sutochno.ru in rubles at the currency rate of {n}. The remainder is to be paid to the owner at the moment of your check-in. The payment is possible in the local currency of the country in which the object is located (at the rate at the time of check-in).",
    "hotelCleaningCostHint": "The cleaning fee is not included in the room rate - you need to pay an additional cleaning fee upon check-in."
	}
}
</i18n>
<template>
  <div v-if="rules" class="rules tmp-font--medium">
    <div class="rules--title tmp-font--big_bold">
      {{ t("rulHome") }}
    </div>
    <div class="rules--top">
      <div class="item">
        <div class="clock">
          <div>
            <div class="clock-title tmp-font--average_bold">
              {{ t("rulChekIn") }}
            </div>
            {{ t("after") }} {{ checkIn("check_in") }}
          </div>
          <div>
            <div class="clock-title tmp-font--average_bold">
              {{ t("rulChekOut") }}
            </div>
            {{ t("before") }} {{ checkIn("check_out") }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="clock-title tmp-font--average_bold">
          {{ t("rulMinState") }}
        </div>
        <div>
          {{ minNights }}
          <span
            v-if="!occupied"
            @click="showCalendar()"
            class="select-date tmp-font--link"
            >{{ t("selectDates") }}</span
          >
        </div>
      </div>
    </div>
    <div
      class="rules--list"
      v-if="accommodationRules.length > 0 || children || documents"
    >
      <div class="item" v-if="children">
        <div class="icon"><span :class="showIconChild"></span></div>
        {{ children }}
        <template v-if="isFreeBabies">
          <br>
          {{ t('free_babies') }}
        </template>
      </div>
      <template v-for="rule in accommodationRules" :key="rule.name">
        <div class="item">
          <div class="icon">
            <span
              :class="[showIconRule(rule)]"
            ></span>
          </div>
          {{ t(`accommodationRules.${rule.name}.${Number(rule.value)}`) }}
        </div>
      </template>
      <div class="item" v-if="documents">
        <div class="icon">
          <span
            :class="{
              'icon-app-doc-on': documents.value === 1 || documents.value === 2,
              'icon-app-documents1': documents.value === 0,
            }"
          ></span>
        </div>
        {{ t(`documents_${documents.value}`) }}
      </div>
    </div>
    <div
      class="rules-payment"
      v-if="
        getObjectPrice?.currency?.id &&
        getObjectOriginalCurrency?.id != getObjectPrice?.currency?.id
      "
    >
      <div class="rules-payment__text">
        {{ t("note") }}
      </div>
      <p class="rules-payment__description">
        {{ t("calculationMade") }} <span class="rules-payment__code">{{ t("code", { n: getObjectOriginalCurrency.code }) }}</span>
        <VDropdown
          class="rules-payment__popover"
          distance="5"
          placement="top"
          :triggers="['click']"
          popper-class="v-popper__popper--max-width-300"
        >
          <span class="ico-question">?</span>
          <template #popper>
            <div class="tooltip-text">
              <p class="text-left" v-html="popoverText"></p>
              <div v-close-popper class="tooltip-close"></div>
            </div>
          </template>
        </VDropdown>
      </p>
    </div>
    <div class="rules--deposit" v-if="deposit && deposit.value">
      <div class="price">
        {{ formatNumber(deposit.value) }}
      </div>
      {{ t("deposit") }}
      <VDropdown
        theme="tooltip"
        class="bonus__popover"
        distance="10"
        placement="top"
        :triggers="['hover', 'click']"
        popper-class="v-popper__popper--max-width-300"
      >
        <span class="icon-app-question"></span>
        <template #popper>
          <div class="tooltip-text">
            <p class="text-left" v-html="t('depositHint')"></p>
          </div>
        </template>
      </VDropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "Rules",
  props: {
    rules: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectPrice", "getObjectData", "getObjectOriginalCurrency", "getRates", "getHotelData"]),
    ...mapGetters("search", ["occupied"]),
    ...mapState("search", { checkInRule: "checkIn" }),
    ...mapState("search", { checkOutRule: "checkOut" }),
    deposit() {
      if (this.rules?.properties?.additional_payment?.properties) {
        return this.rules.properties.additional_payment.properties.find(
          (p) => p.name === "deposit"
        );
      } else {
        return null;
      }
    },
    minNightsObj() {
      let additional_payment = this.rules?.properties?.additional_payment
        ?.properties
        ? this.rules.properties.additional_payment.properties?.find(
            (item) => item.name == "min_nights"
          )
        : "";
      return additional_payment ? additional_payment.value / 24 : "";
    },
    cntMin() {
      return this.getObjectPrice?.error?.cnt_min
        ? this.getObjectPrice.error.cnt_min
        : "";
    },
    minNights() {
      if (!this.occupied) {
        if (this.isSeasonalPrices.length) {
          return this.minMaxDate();
        } else {
          return `${this.t("from")} ${this.t("day", this.minNightsObj)}`;
        }
      } else {
        if (this.cntMin) {
          return `${this.t("from")} ${this.t("day", this.cntMin)}`;
        } else {
          if (this.isSeasonalPrices.length) {
            let checkStart = new Date(this.checkInRule).getTime();
            let checkEnd = new Date(this.checkOutRule).getTime();

            let fundSeasona = this.isSeasonalPrices.find(
              (item) =>
                new Date(item.date_start).getTime() <= checkStart &&
                checkStart <= new Date(item.date_end).getTime() &&
                checkEnd <= new Date(item.date_end).getTime()
            );

            if (fundSeasona) {
              let intervalStart = fundSeasona.interval_start / 24;
              if (intervalStart === 0) {
                intervalStart = this.minNightsObj;
              }
              return `${this.t("from")} ${this.t("day", intervalStart)}`;
            } else {
              return this.minMaxDate();
            }
          } else {
            return `${this.t("from")} ${this.t("day", this.minNightsObj)}`;
          }
        }
      }
    },
    isSeasonalPrices() {
      return (
        this.getObjectData?.extprices.filter(
          (item) => item.date_start && item.date_end
        ) || []
      );
    },
    accommodationRules() {
      var rules = [];
      const ISOLATION = this.rules?.properties?.pay_attention?.properties?.find(
        (p) => p.name === "isolation"
      );
      if (this.rules?.properties?.accommodation_rules?.properties?.length > 0) {
        const ACC_RULES = ["smoking", "pets", "party"];
        rules = this.rules.properties.accommodation_rules.properties.filter(
          (p) => ACC_RULES.includes(p.name)
        );
      }
      if (ISOLATION) {
        //rules.push(ISOLATION);
      }
      return rules;
    },
    showIconChild(){
      const properties =
        this.rules?.properties?.accommodation_rules?.properties;
      if (properties) {
        const is_children = properties.find(
          (item) => item.name === "is_children"
        );
        if (is_children?.value) {
          return "icon-app-child";
        }
        return "icon-app-no-child";
      }
      return "icon-app-no-child";;
    },
    children() {
      const properties = this.rules?.properties?.accommodation_rules?.properties;
      if (properties) {
        const is_children = properties.find(item => item.name === 'is_children');
        const children_age = properties.find(item => item.name === 'children_age');
        if (is_children?.value === 1) {
          if (children_age?.value <= 2 && this.isFreeBabies) {
            return this.t('is_children.1.0');
          } else if (children_age?.value > 2 && this.isFreeBabies) {
            return this.t('is_children_free_babies', {
              age: children_age.value,
            });
          } else if (children_age?.value <= 1) {
            return this.t(
              `is_children.${is_children.value}.${children_age.value}`
            );
          } else {
            return this.t('is_children.1.2', {
              age: children_age.value,
            });
          }
        } else if (is_children?.value === 0 || is_children?.value === 2) {
          return this.t(`is_children.${is_children.value}`);
        } else {
          return false;
        };
      } else {
        return false;
      };
    },
    isFreeBabies() {
      const properties = this.rules?.properties?.accommodation_rules?.properties;
      const freeBabies = properties?.find(item => item.name === 'free_babies')?.value;
      return freeBabies === "false" ? false : Boolean(freeBabies);
    },
    documents() {
      if (this.rules?.properties?.documents_rules?.properties) {
        let documents =
          this.rules?.properties?.documents_rules?.properties.find(
            (item) => item.name === "documents"
          );
        return documents ? documents : false;
      } else {
        return false;
      }
    },
    popoverText() {
      let now = new Date().toLocaleString('ru', {
        month: 'long',
        day: 'numeric'
      });

      return this.t("prepaymentPrompt", { n: now });
    },
    objectCurrencyCode() {
      return this.getObjectPrice?.currency?.code || "RUB";
    },
  },
  methods: {
    showIconRule(rule){
      // 'icon-app-no-smoking1': rule.name === 'smoking',
      //           'icon-app-no-pets-2': rule.name === 'pets',
      //           'icon-app-invalid-name': rule.name === '',
      switch (rule.name) {
        case 'smoking':
          return rule.value? "icon-app-smoking-ok": "icon-app-no-smoking1"
          break;
        case 'pets':
          return rule.value? "icon-app-pets-ok": "icon-app-no-pets-2"
          break;
        case 'party':
          return rule.value? "icon-app-party-ok": "icon-app-invalid-name"
          break;

        default:
          return false
          break;
      }
    },
    minMaxDate() {
      let minSeasonalDate =
        this.isSeasonalPrices.reduce((prev, curr) =>
          prev.interval_start < curr.interval_start ? prev : curr
        ).interval_start / 24;
      let maxSeasonalDate =
        this.isSeasonalPrices.reduce((prev, curr) =>
          prev.interval_start > curr.interval_start ? prev : curr
        ).interval_start / 24;

      if (minSeasonalDate === 0) {
        minSeasonalDate = this.minNightsObj;
      }

      if (this.minNightsObj < minSeasonalDate) {
        minSeasonalDate = this.minNightsObj;
      }
      if (this.minNightsObj > maxSeasonalDate) {
        maxSeasonalDate = this.minNightsObj;
      }

      if (minSeasonalDate === 0 && maxSeasonalDate > 0) {
        return `${this.t("from")} ${this.t("day", maxSeasonalDate)}`;
      } else if (
        minSeasonalDate !== 0 &&
        maxSeasonalDate !== 0 &&
        minSeasonalDate === maxSeasonalDate
      ) {
        if (this.minNightsObj > minSeasonalDate) {
          return `${this.t("from")}  ${minSeasonalDate} ${this.t(
            "before"
          )} ${this.t("day", this.minNightsObj)}`;
        } else {
          return `${this.t("from")} ${this.t("day", minSeasonalDate)}`;
        }
      } else if (minSeasonalDate > 0 && maxSeasonalDate > 0) {
        return `${this.t("from")}  ${minSeasonalDate} ${this.t(
          "before"
        )} ${this.t("day", maxSeasonalDate)}`;
      } else if (minSeasonalDate === 0 && maxSeasonalDate === 0) {
        return `${this.t("from")} ${this.t("day", this.minNightsObj)}`;
      }
    },
    showCalendar() {
      setTimeout(() => {
        this.$parent.$parent.$refs.aside.$refs.calendar.toggle();
      }, 50);
    },
    checkIn(index) {
      let item;
      if (this.rules.properties.check_in_out_rules) {
        item = this.rules.properties.check_in_out_rules.properties.find(
          (item) => item.name == index
        ).value;
      } else {
        item = this.rules.properties.additional_payment.properties.find(
          (item) => item.name == index
        ).value;
      }

      if (index == "min_nights") {
        return item / 24;
      } else {
        return item;
      }
    },
    formatNumber(num, currency) {
      return new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: currency ? currency : this.getObjectOriginalCurrency?.code,
        minimumFractionDigits: 0,
      }).format(num);
    },
  },
};
</script>

<style lang="scss" scoped>
//  To-Do  нужно переделать под иконочный шрифт
.yes-smoking{
  &::before{
    content: url("~/assets/img/smoking-ok.svg");
  }
}
.yes-party{
  &::before{
    content: url("~/assets/img/party-ok.svg");
  }
}
.yes-pets{
  &::before{
    content: url("~/assets/img/pets-ok.svg");
  }
}

.rules {
  color: #000000;
  &--title {
    margin-bottom: 20px;
  }

  &--top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 24px;

    .item {
      width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      padding: 4px 0;

      &:nth-child(odd) {
        margin-right: 10px;
      }

      &:nth-child(even) {
        margin-left: 10px;
      }

      .clock {
        display: flex;
        justify-content: space-between;
        max-width: 280px;
      }

      .clock-title {
        margin-bottom: 10px;
      }
      .select-date {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }

  &--list {
    .item {
      display: flex;
      align-items: center;
      margin: 8px 0;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 15px;

        span {
          font-size: 25px;
          color: #000000;
        }
      }
    }
  }

  &--deposit {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #fff6c4;
    padding: 8px 20px;
    margin-top: 14px;

    .price {
      font-weight: 500;
      margin-right: 20px;
    }
    .bonus__popover {
      margin-left: 8px;
    }
    .icon-app-question {
      cursor: pointer;
      font-size: 16px;
      color: #000000;
      vertical-align: bottom;
    }
  }
}

.rules-payment {
  margin: 15px 0 10px 0;
  padding: 9px 20px;
  border-radius: 4px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;

  &__text {
    font-weight: 500;
    margin-right: 20px;
  }

  &__description {
    margin: 0;
    display: flex;
    align-items: center;
  }

  &__code {
    font-weight: bold;
    margin-left: 4px;
  }

  &__popover {
    margin-left: 6px;
  }

  .ico-question {
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 0 2px 2px;
    font-size: 10px;
  }
}
</style>
