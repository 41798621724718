<template>
  <div class="placeholder-loader placeholder-loader_content">
    <div class="info info_map flex-column-wrap">
      <div class="flex-wrap">
        <div class="w-10">
          <div class="circle"></div>
        </div>
        <div class="w-90">
          <div class="mb-20">
            <div class="subtitle w-25"></div>
            <div class="flex-between">
              <div class="type w-45"></div>
              <div class="type w-15"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-column-wrap flex-end mb-20">
        <div v-for="(item, index) in 3" class="type type_middle" style="width: 90%"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentsPlaceholder'
};
</script>

<style scoped lang="scss">

.placeholder-loader {
  margin: 0px 0 20px 0;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &_content {
    padding: 20px;
  }
}

.flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flex-end {
  align-items: flex-end;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.info {
  width: 100%;
  min-height: 90px;

  &_map {
    min-height: 150px;
  }

  .map {
    height: 492px;
  }
}

.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-25 {
  width: 25%;
}
.w-45 {
  width: 45%;
}
.w-50 {
  width: 50%;
}
.w-90 {
  width: 90%;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #d8d8d8;
}

.subtitle {
  width: 25%;
  height: 20px;
  margin-bottom: 10px;
  background: #d8d8d8;
  border-radius: 15px;
}

.type {
  top: 214px;
  height: 15px;
  margin-bottom: 10px;
  background: #d8d8d8;
  border-radius: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

</style>