<i18n lang="json">
{
	"ru": {
    "toFavorite": "В избранное",
    "save": "В избранном",
    "removeFavorite": "Убрать из избранного",
    "addFavorite": "Добавить в избранное"
	},
	"en": {
    "toFavorite": "To favorite",
    "save": "Favorites",
    "removeFavorite": "Remove from favorites",
    "addFavorite": "Add to favorites"
	}
}
</i18n>
<template>
  <div
    class="favorite"
    :class="{ favorite_hover: hover }"
    @click="toFavorite"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <template v-if="favorite">
      <span class="icon-app-heart-full"></span>
      <span class="options__text">{{
        hover ? t("removeFavorite") : t("save")
      }}</span>
    </template>
    <template v-else>
      <span class="icon-app-heart"></span>
      <span class="options__text">{{
        hover ? t("addFavorite") : t("toFavorite")
      }}</span>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { Objects } from "@/components/api/";
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import { useI18n } from "vue-i18n";

export default {
  name: "Favorite",
  props: {
    id: {
      type: Number,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      favorite: false,
      hover: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setFavorite()
    });
  },
  methods: {
    ...mapActions("user", ["loadUserData"]),
    ...mapMutations("user", ["toggleFavoritesForGuest"]),
    setFavorite(){
      if (!this.isAuth) {
        this.favoritesForGuest.forEach(element => {
          if (String(element) === String(this.id)) {
            this.favorite = true;
          }
        });
        return;
      }

      if (
        this.userData &&
        this.userData.objects &&
        this.userData.objects.favorites_ids
      ) {
        this.userData.objects.favorites_ids.filter((id) => {
          if (id == this.id) {
            this.favorite = true;
          }
        });
      }
    },
    toFavorite() {
      this.favorite = !this.favorite;
      Objects.toggleFavorite(Number(this.id)).then((res) => {
        this.favorite = res.data.data.is_favorite;
        if (!this.isAuth) {
          this.toggleFavoritesForGuest({
            id: String(this.id),
            value: this.favorite,
          });
        }
        setTimeout(() => {
          this.loadUserData({ useCache: true });
        }, 0);
      });
      const screenBlock = this.favorite ? 'object_favorite' : 'object_unfavorite'
      sutochnoMetrika.detail('click', screenBlock, {
        ...this.commonParamsForMetrika,
        page: "object",
      })
    },
  },
  computed: {
    ...mapState("user", ["userData", "favoritesForGuest", "isAuth"]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
  },
};
</script>

<style lang="scss" scoped>
.favorite {
  // .icon-app-heart,
  // .icon-app-heart-full {
  //   padding-right: 10px;
  // }
  .icon-app-heart,
  .icon-app-heart-full {
    font-size: 24px;
  }
  .icon-app-heart-full {
    color: #f51449;
  }
}
div.aside__options .options__item_favorite:deep() {
  // TODO: проверить как перенеслось ::v-deep
  margin-right: 0;
  justify-content: flex-end;
}
.favorite:deep(.options__item.options__item_favorite.favorite_hover) {
  width: 185px;
}
</style>
