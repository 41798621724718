<i18n lang="json">
{
    "ru": {
      "selectDateTitle": "Доступные номера на выбранный период",
      "meals": {
        "included": "{0} включен | {0} и {1} включены | {0}, {1}, {2} включены",
        "breakfast": "завтрак",
        "dinner": "обед",
        "supper": "ужин"
      }
    },
    "en": {
      "selectDateTitle": "Available rooms for the selected period",
      "meals": {
        "included": "{0} is included | {0} and {1} are included | {0}, {1}, {2} are included",
        "breakfast": "breakfast",
        "dinner": "lunch",
        "supper": "dinner"
      }
    }
}
</i18n>
<template>
  <div>
    <div
      class="select-date-guests"
      :class="{ 'sticky': objectsFilter.length > 2 }"
    >
      <div class="select-date-guests--title tmp-font--big_bold">
        {{ t("selectDateTitle") }}
      </div>
      <div class="select-date-guests--body">
        <Availability view="desktop-view" ref="availability" :is-object-list="true" />
      </div>
    </div>
    <div class="hotel-rooms">
      <Room
        v-for="(room, i) in objectsFilter"
        :room="room"
        :price="
          getHotelPrice
            ? getHotelPrice.find((item) => item.id == room.id)
            : null
        "
        :key="i"
      />
    </div>
  </div>
</template>

<script>
import Availability from "@/components/mobile/Detail/Hotel/RoomsList/Availability";
import { mapActions, mapGetters, mapState } from "vuex";
import Room from "./DetailRoom";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailRoomsList",
  components: {
    Availability,
    Room,
  },
  provide(){
    return{
      meals: this.meals
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "getHotelData",
      "getHotelPrice",
    ]),
    ...mapState("detail", ["hotelObjectsId"]),
    objectsFilter() {
      const prices = this.getHotelPrice;
      if (!prices) {
        return [];
      }
      const objects = this.getHotelData.objects.filter(item => item.id != this.getObjectData.id);
      return [
        ...objects.filter(el => prices.find((item) => item.id == el.id)?.data.busy !== 'busy')
          .sort((a, b) => {
            const priceA = prices.find((item) => item.id == a.id);
            const priceB = prices.find((item) => item.id == b.id);
            return priceA.data.price - priceB.data.price;
          }),
        ...objects.filter(el => prices.find((item) => item.id == el.id)?.data.busy === 'busy')
      ];
    },
    trmHotelPrice() {
      if (!this.getHotelPrice) return null;
      return this.getHotelPrice.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },
    meals(){
      const meal = this.getHotelData.hotel.meal;
      if (!meal?.meal_types) return null;

      let included = [];
      let unincluded =[];
      meal.meal_types.forEach((v) => {
        if(v.kind === 'nofood' || v.kind === '') return false;
        if(v.price === 0){
          included.push(this.t(`meals.${v.name}`))
        } else if(v.price === 1) {
          unincluded.push(this.t(`meals.${v.name}`))
        }
      });

      const ucFirst = (str) => {
        return str[0].toUpperCase() + str.slice(1);
      }

      if (included.length) {
        return ucFirst(this.t("meals.included", included, included.length-1));
      };
      return null
    },
  },
  methods: {
    ...mapActions("detail", ["setHotelPrice"]),
  },
};
</script>

<style lang="scss" scoped>
.select-date-guests {
  color: #000;
  padding: 50px 20px 20px;
  background: #f6f6f6;
  position: relative;
  top: 0;
  z-index: 2;
  &.sticky {
    position: sticky;
  }
  &--title {
    margin-bottom: 18px;
  }
}

.hotel-rooms {
  margin-bottom: 20px;
}
</style>
