<template>
  <div class="placeholder-loader placeholder-loader_content">
    <div class="info">
      <div class="info__item info__item_flex">
        <div v-for="(item, index) in 2" :key="index" class="button_flex">
          <div class="button button_big w-100"></div>
        </div>
      </div>
      <div class="info__guests">
        <div class="button button_big w-100"></div>
      </div>

      <div class="info__item info__item_flex">
        <div class="button button_big w-100"></div>
      </div>

      <div class="w-100">
        <div class="offset">
          <div
            v-for="(item, index) in 2"
            :key="index"
            class="type type_initial"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
};
</script>

<style lang="scss" scoped>
.placeholder-loader {
  width: 100%;
  position: sticky;
  top: 50px;

  border: none;

  display: flex;
  flex-wrap: wrap;

  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .options {
    display: flex;
    &__item {
      display: flex;
      width: 42px;
      height: 42px;
      background: #ffffff;
      border-radius: 3px;
      margin-right: 10px;
      transition: width 0.3s;

      &_favorite {
        padding: 10px 9px;

        :deep(.options__text) {
          padding-top: 2px;
        }
      }
      &_share {
        padding: 13px;
      }

      &:last-child {
        margin-right: 0;
      }
      :deep(.icon-app-heart),
      .icon-app-share-simple {
        color: #7f7f7f;
      }
      .icon-app-share-simple {
        font-size: 16px;
      }
    }
    @keyframes display-none-transition {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .options__text,
  :deep(.options__text) {
    display: none;
    margin-left: 15px;
    white-space: nowrap;
  }

  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }

  .w-40 {
    width: 40%;
  }

  .w-50 {
    width: 50%;
  }
  .w-70 {
    width: 70%;
  }
  .w-80 {
    width: 80%;
  }
  .w-100 {
    width: 100%;
  }

  .offset {
    margin-top: 20px;

    &_long {
      margin-top: 40px;
    }
  }

  .slider {
    height: 100%;
    width: 100%;

    &_preview {
      .slider__img {
        height: 553px;
      }
    }
    &_nav {
      display: flex;

      .slider__img {
        width: calc(100% / 6);
        height: 102px;
        outline: 2px solid #fff;
        outline-offset: -2px;
      }
    }
  }

  .block-right {
    display: inline-block;
    float: right;
  }

  .info {
    margin-top: 86px;
    padding: 20px;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #ffffff;
    border-radius: 8px;
    // justify-content: space-between;
    // align-items: stretch;
    // align-content: stretch;

    &__item {
      padding-top: 20px;
      &_flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    &__guests {
      padding-top: 10px;
    }
    &__subitem {
      margin-top: 30px;
    }

    &_map {
      min-height: 150px;
    }

    .map {
      height: 492px;
    }
  }

  &__img {
    grid-area: carousel;
    width: 100%;
    background-color: #ededef;
    background-image: url("~/assets/img/no_photo.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  .type,
  .place,
  .price,
  .button,
  .square,
  .title,
  .subtitle,
  .circle {
    background: #d8d8d8;
    border-radius: 15px;
    // margin: 0 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .type {
    top: 214px;
    height: 15px;
    width: calc(100% - 40px);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
    &_min {
      width: 30%;
    }
    &_middle {
      width: 60%;
    }
    &_initial {
      width: initial;
    }
  }
  .place {
    height: 10px;
    width: calc(60% - 40px);
  }
  .price {
    height: 17px;
    width: calc(60% - 40px);
    margin: 0 auto;
    margin-top: 30px;
  }
  .button {
    height: 35px;
    width: 101px;
    border-radius: 3px;
    margin: 0 auto;
    border: none !important;

    &_big {
      height: 42px;
    }

    &_flex {
      width: calc(100% / 2);
      padding: 0 5px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .square {
    height: 60px;
    width: 80px;
    &_rating {
      height: 80px;
    }
  }
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    &_big {
      width: 100px;
      height: 100px;
    }
  }
  .title {
    width: 30%;
    height: 25px;
  }
  .subtitle {
    width: 25%;
    height: 20px;
    margin-bottom: 10px;
  }
}
</style>
