<i18n lang="json">
{
	"ru": {
    "amenities": "Основные удобства",
    "choose": "Выбрать",
    "dining": "Кухонное оборудование",
    "dinTableware": "посуда и принадлежности",
    "dinElKettle": "электроплита",
    "dinCutlery": "столовые приборы",
    "dinKettle": "электрический чайник",
    "dinRefregerator": "холодильник",
    "dinKitchen": "кухонный гарнитур",
    "dinDinTable": "обеденный стол",
    "dinFreezer": "морозильник",

    "equipment": "Оснащение",
    "equipCleanProd": "чистящие средства",
    "equipSofaBed": "диван-кровать",
    "equipWaterHeater": "водонагреватель",
    "equipIronBoard": "утюг с гладильной доской",
    "equipCentheat": "центральное отопление",
    "equipClothDry": "сушилка для белья",
    "equipSteelDoor": "металлическая дверь",
    "equipCupboard": "шкаф",
    "equipWashMach": "стиральная машина",
    "equipTiled": "плиточный/мраморный пол",
    "equipAirCond": "кондиционер",
    "equipSofa": "диван",

    "withoutRepair": "без ремонта",
    "redecorating":"косметический ремонт",
    "capitalRepair":"евроремонт",
    "designerRenovation": "дизайнерский ремонт",
    "modernRenovation": "современный ремонт",

    "leisure": "Для досуга",
    "leisureTV": "телевизор",
    "leisureCable": "кабельное ТВ",

    "attentions": "Обратите внимание",
    "attentionsChild": "можно с детьми",

    "court": "Оснащение двора",
    "courtBarbecue": "принадлежности для барбекю",
    "courtGrill": "барбекю/мангал",
    "courtTerrace": "терраса",

    "struct": "Инфраструктура и досуг рядом",
    "structBathHouse": "баня (за территорией)",
    "structSkiing": "горные лыжи",
    "structForest": "лес",
    "structWater": "водные виды спорта",
    "structBike": "прокат велосипедов",
    "structPark": "парк аттракционов",
    "structSPA": "SPA-центр",
    "structBowling": "боулинг",
    "structBilliards": "бильярдный клуб",
    "structNightClub": "ночной клуб",
    "structTennis": "теннисный корт",

    "price": "Входит в стоимость проживания",
    "priceBedclothes": "постельное бельё",

    "bath": "Ванная комната",
    "bathroom": "ванная комната",
    "bathShower": "душ",
    "bathDryer": "фен",
    "bathToiletries": "туалетные принадлежности",
    "bathTowels": "полотенца",

    "infoStruct": "Инфраструктура",
    "additional": "Дополнительная информация",

    "description": "Дополнительная информация",
    "infrastructure": "Инфраструктура",
    "furniture": "Мебель и другие удобства",
    "payAttention": "Обратите внимание",
    "transferTitle":"Трансфер",
    "transferDescription": "По договоренности с хозяином жилья.",

    "breakfast": "Завтрак",
    "dinner": "Обед",
    "supper": "Ужин",
    "price_0": "входит в стоимость проживания",
    "price_1": "оплачивается отдельно",
    "nofood": "не предоставляется",
    "sweden": "шведский стол",
    "menu": "по меню",
    "complex": "комплексный",
    "food": "Питание",
    "all_inclusive": "всё включено",
    "more": "Показать все удобства",
    "moreHide": "Скрыть все удобства",
    "decoration": {
      "title": "Отделка",
      "1":"косметический ремонт",
      "2":"евроремонт",
      "3": "дизайнерский ремонт",
      "4": "современный ремонт",
    },
    "view": "вид",
    "pool": "бассейн",
    "parking": {
      "parking": {
        "1": "оплачивается отдельно",
        "2": "без оплаты",
        "3": "нет парковки",
        "default": "есть бесплатная парковка"
      },
      "is_p_covered": {
        "0": "открытая",
        "1": "крытая"
      },
      "is_p_private": {
        "0": "общественная",
        "1": "частная"
      },
      "is_p_fenced": {
        "0": "не огорожена",
        "1": "огорожена"
      },
      "is_p_video_surveilla": {
        "0": "нет видеонаблюдения",
        "1": "есть видеонаблюдение"
      },
      "is_p_security": {
        "0": "нет охраны",
        "1": "есть охрана"
      },
      "is_p_on_site": {
        "0": "за территорией объекта {distance}",
        "1": "на территории объекта"
      },
      "distance_to_p": {
        "5": "до 5 мин пешком",
        "10": "до 10 мин пешком",
        "15": "до 15 мин пешком",
        "20": "более 15 мин пешком"
      },
      "parking_cost_1": {
        "1": "стоимость: {cost} в сутки",
        "2": "стоимость: {cost} за период проживания",
        "3": "стоимость: {cost} в час",
        "default": "стоимость: {cost}"
      },
      "is_p_booking": {
        "0": "парковочное место нельзя забронировать заранее",
        "1": "парковочное место можно забронировать заранее"
      }
    }
	},
	"en": {
    "amenities": "Basic amenities",

    "dining": "Dining",
    "dinTableware": "tableware",
    "dinElKettle": "electric kettle",
    "dinCutlery": "cutlery",
    "dinKettle": "kettle",
    "dinRefregerator": "refrigerator",
    "dinKitchen": "kitchen",
    "dinDinTable": "dinner table",
    "dinFreezer": "freezer",

    "equipment": "Equipment",
    "equipCleanProd": "cleaning products",
    "equipSofaBed": "sofa bed",
    "equipWaterHeater": "water heater",
    "equipIronBoard": "ironing board and iron",
    "equipCentheat": "central heating",
    "equipClothDry": "clothes dryer",
    "equipSteelDoor": "steel door",
    "equipCupboard": "cupboard",
    "equipWashMach": "washing machine",
    "equipTiled": "tiled / marble floor",
    "equipAirCond": "air conditioner",
    "equipSofa": "sofa",

    "leisure": "For leisure",
    "leisureTV": "TV",
    "leisureCable": "cable TV",

    "attentions": "Pay attentions",
    "attentionsChild": "Access for children",

    "court": "Court Equipment",
    "courtBarbecue": "barbecue facilities",
    "courtGrill": "grill/barbecue",
    "courtTerrace": "terrace",

    "struct": "Infrastructure and leisure near",
    "structBathHouse": "bathhouse",
    "structSkiing": "alpine skiing",
    "structForest": "forest",
    "structWater": "water sports",
    "structBike": "bike hire",
    "structPark": "amusement park",
    "structSPA": "SPA center",
    "structBowling": "bowling",
    "structBilliards": "Billiards",
    "structNightClub": "night club",
    "structTennis": "tennis court",


    "price": "Included in the price",
    "priceBedclothes": "bedclothes",

    "bath": "Bathroom",
    "bathroom": "bathroom",
    "bathShower": "shower",
    "bathDryer": "hair dryer",
    "bathToiletries": "toiletries",
    "bathTowels": "towels",

    "infoStruct": "Infrastructure",
    "additional": "Additional information",

    "description": "Additional information",
    "infrastructure": "Infrastructure",
    "furniture": "Furniture and other amenities",
    "payAttention": "Обратите внимание",
    "transferTitle":"Transfer",
    "transferDescription": "By agreement with the landlord.",

    "withoutRepair": "without renovation",
    "redecorating":"redecorating",
    "capitalRepair":"capital renovation",
    "designerRenovation": "designer renovation",
    "modernRenovation": "modern renovation",

    "breakfast": "Breakfast",
    "dinner": "Lunch",
    "supper": "Dinner",
    "price_0": "included in the cost of living",
    "price_1": "paid separately",
    "nofood": "not provided",
    "sweden": "buffet",
    "menu": "by menu",
    "complex": "complex",
    "food": "Food",
    "all_inclusive": "all inclusive",
    "more": "Show all amenities",
    "moreHide": "Hide all amenities",
    "decoration": {
      "title": "Decoration",
      "1":"redecorating",
      "2":"capital renovation",
      "3": "designer renovation",
      "4": "modern renovation",
    }
	},
  "view": "view",
  "pool": "pool",
  "parking": {
    "parking": {
      "1": "paid separately",
      "2": "without payment",
      "3": "",
      "default": "there is free parking"
    },
    "is_p_covered": {
      "0": "open",
      "1": "covered"
    },
    "is_p_private": {
      "0": "public",
      "1": "private"
    },
    "is_p_fenced": {
      "0": "not fenced",
      "1": "fenced"
    },
    "is_p_video_surveilla": {
      "0": "no video surveillance",
      "1": "there is video surveillance"
    },
    "is_p_security": {
      "0": "no security",
      "1": "there is security"
    },
    "is_p_on_site": {
      "0": "outside the facility ({distance})",
      "1": "on site"
    },
    "distance_to_p": {
      "5": "up to 5 minutes on foot",
      "10": "up to 10 minutes on foot",
      "15": "up to 15 minutes on foot",
      "20": "more than 15 minutes on foot"
    },
    "parking_cost_1": {
      "1": "cost: {cost} per day",
      "2": "cost: {cost} per stay",
      "3": "cost: {cost} per hour",
      "default": "cost: {cost}"
    },
    "is_p_booking": {
      "0": "parking space cannot be reserved in advance",
      "1": "parking space can be reserved in advance"
    }
  }
}
</i18n>

<template>
  <div v-if="isFacilities">
    <div class="panel-head">
      <h1 class="panel-title tmp-font--big_bold">
        {{ t("amenities") }}
      </h1>
    </div>

    <div
      class="panel-body often-properties"
      itemprop="description"
      :class="{ 'no-often-propertie': !oftenPropertiesForHotel.length }"
    >
      <div class="b-info b-info-flex">
        <div
          class="info-block often-properties--list w-100"
          v-if="oftenPropertiesForHotel.length"
        >
          <p
            v-for="item in oftenPropertiesForHotel"
            :key="item.name"
            class="info-item-pad-sm item-properties"
          >
            <!--            <span :class="iconProperties(item.name)"></span>-->
            <span v-if="item.name === 'v_seas'" class="icon-img">
              <img src="~/assets/img/ico-sea-view.svg" alt="" />
            </span>
            <span v-else-if="item.name === 'v_moun'" class="icon-img">
              <img src="~/assets/img/ico-mountain.svg" alt="" />
            </span>
            <span v-else-if="item.name === 'swimpool1'" class="icon-img">
              <img src="~/assets/img/ico-pool.svg" alt="" />
            </span>
            <AppIcon
              v-else
              :icon-type="iconProperties(item.name)"
              :no-bg="true"
              :size="25"
              :font-size="25"
            />
            {{
              item.name === "v_seas" || item.name === "v_moun"
                ? `${t("view")} ${item.title}`
                : item.title
            }}
          </p>
          <p v-if="isPoolHotel" class="info-item-pad-sm item-properties">
            <span class="icon-img">
              <img src="~/assets/img/ico-pool.svg" alt="" />
            </span>
            {{ t("pool") }}
          </p>
        </div>
        <template v-else>
          <div class="info-block w-100 b-info-column" v-if="!viewBlock">
            <template v-for="(property, index) in propertiesFilter">
              <div
                :key="property.key"
                class="pad-block b-info-column-item"
                v-if="index === showProperties"
              >
                <div class="tmp-font--average_bold info-item-marg-md">
                  {{ property.title }}
                </div>
                <p
                  v-for="propertyItem in property.properties"
                  :key="propertyItem.name"
                  class="info-item text-small info-item-pad-sm"
                >
                  <span>{{ propertyItem.title }}</span>
                  <span
                    v-if="
                      property.key == 'bathroom' &&
                      typeof propertyItem.value !== 'boolean'
                    "
                  >
                    &nbsp;{{ propertyItem.value }}
                  </span>
                </p>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>

    <template v-if="viewBlock">
      <div
        class="panel-body"
        :class="{ 'no-often': !oftenPropertiesForHotel.length }"
        itemprop="description"
      >
        <div class="b-info b-info-flex">
          <div class="info-block w-100 b-info-column">
            <template v-for="property of propertiesFilter" :key="property.key">
              <div class="pad-block b-info-column-item">
                <div class="tmp-font--average_bold info-item-marg-md">
                  <span class="b-info-item-title">{{ property.title }}</span>
                  <span
                    class="b-info-item-label"
                    v-if="property.label"
                    :data-label="property.label"
                  ></span>
                </div>
                <p
                  v-for="propertyItem in property.properties"
                  :key="propertyItem.name"
                  class="info-item text-small info-item-pad-sm"
                >
                  <span>{{ propertyItem.title }}</span>
                  <span
                    v-if="
                      property.key == 'bathroom' &&
                      typeof propertyItem.value !== 'boolean'
                    "
                  >
                    &nbsp;{{ propertyItem.value }}
                  </span>
                </p>
              </div>
            </template>
          </div>
        </div>

        <div v-if="dopPropertiesFilter" class="b-info b-info-flex">
          <div
            v-for="(property, i) in dopPropertiesFilter"
            :key="i"
            class="pad-block info-block w-100"
          >
            <div>
              <div class="tmp-font--average_bold info-item-marg-md">
                {{ property.translate }}
              </div>
              <p class="info-item text-break-spaces info-item-marg-md">
                {{ String(property.value).trim() }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="transfer.transfer" class="b-info b-info-flex">
          <div class="info-block w-100">
            <div>
              <div class="tmp-font--average_bold info-item-marg-md">
                {{ t("transferTitle") }}
              </div>
              <p class="info-item info-item-marg-md">
                {{ transfer.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <button class="button button_size_md" @click="viewBlock = !viewBlock">
      {{ btnProperties }}
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { useProcessData } from "@/composables/properties/processData";
import AppIcon from "@/components/base/AppIcons/AppIcon.vue";
import { useI18n } from 'vue-i18n';
import _ from "lodash";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Description",
  components: { AppIcon },
  setup() {
    const { t } = useI18n();
    const { processDataParking } = useProcessData();
    return {
      t,
      processDataParking
    }
  },
  props: {
    properties: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewBlock: false,
      showProperties: 0,
    };
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getHotelData", "isHotel"]),
    btnProperties() {
      return this.viewBlock ? this.t("moreHide") : this.t("more");
    },
    oftenProperties() {
      const renovationOptions = {
        "-10": "choose",
        "-1": "choose",
        0: "withoutRepair",
        1: "redecorating",
        2: "capitalRepair",
        3: "designerRenovation",
        4: "modernRenovation",
      };

      let oftenProp = [];
      Object.values(this.properties).forEach((value) => {
        const propFilter = value.properties.filter(
          (subProp) =>
            subProp.name === "condition" ||
            subProp.name === "balcony" ||
            subProp.name === "isolation" ||
            subProp.name === "wifi" ||
            subProp.name === "tv" ||
            subProp.name === "kettle" ||
            subProp.name === "hairdryer" ||
            subProp.name === "microwave" ||
            subProp.name === "towels" ||
            subProp.name === "quality_repair" ||
            subProp.name === "bedclothes" ||
            subProp.name === "v_seas" ||
            subProp.name === "v_moun" ||
            subProp.name === "swimpool1"
        );
        propFilter.forEach((item) => {
          if (item.name === "quality_repair") {
            const newItem = { ...item };
            if (renovationOptions[newItem.value] && newItem.value > 0) {
              newItem.title = this.t(renovationOptions[newItem.value]);
              oftenProp.push(newItem);
            }
          } else {
            oftenProp.push(item);
          }
        });
      });
      let indexWife = oftenProp.findIndex((item) => item.name === "wifi");
      if (indexWife > 0) {
        let elWife = oftenProp[indexWife];
        oftenProp[indexWife] = oftenProp[0];
        oftenProp[0] = elWife;
      }
      const isSeas = oftenProp.find((item) => item.name === "v_seas");
      const isMoun = oftenProp.find((item) => item.name === "v_moun");

      return isSeas && isMoun
        ? oftenProp.filter((item) => item.name !== "v_moun")
        : oftenProp;
    },
    oftenPropertiesForHotel() {
      if (this.isHotel) {
        return this.oftenProperties.filter((item) => item.name !== "swimpool1");
      }
      return this.oftenProperties;
    },
    filterProperties() {
      return Object.entries(this.properties).reduce((acc, [key, value]) => {
        if (key === "included_in_price") {
          const included_in_price = {
            title: value.title,
            key: key,
            properties: value.properties.filter((v) => v.name !== "transfer"),
          };
          if (included_in_price.properties.length) {
            acc[key] = included_in_price;
          }
        } else if (key === "bathroom") {
          const bathroom = {
            title: value.title,
            key: key,
            properties: value.properties.filter((v) => v.value !== 0),
          };
          if (bathroom.properties.length) {
            acc[key] = bathroom;
          }
        } else if (key === "parking") {
          const currency =
            this.getObjectData?.current_price?.currency_code || "RUB";
          acc[key] = this.processDataParking(
            key,
            value,
            currency,
            this.isHotel
          );
        } else {
          const prop = {
            title: value.title,
            key: key,
            properties: value.properties.filter((subProp) => {
              if (this.isHotel) {
                return (
                  typeof subProp.value === "boolean" &&
                  subProp.name !== "swimpool1"
                );
              }
              return typeof subProp.value === "boolean";
            }),
          };
          if (prop.properties.length) {
            acc[key] = prop;
          }
        }
        return acc;
      }, {});
    },
    propertiesFilter() {
      // eslint-disable-next-line no-undef
      const propertiesGroup = _.omit(this.filterProperties, [
        "additional_payment",
        "accommodation_rules",
        "documents_rules",
        "enter_name",
        "guests_rules",
        "check_in_out_early_rules",
        "instant_rules",
        "basic_properties",
        "detail_description",
        "additional_properties",
        "hidden_properties",
        "additional_payment",
        "check_in_out_rules",
        "parking",
      ]);

      const result = Object.values(propertiesGroup);
      if (
        !this.isHotel &&
        this.filterProperties["parking"] &&
        this.filterProperties["parking"].properties.length
      ) {
        const parkingIndex = this.filterProperties[
          "parking"
        ].properties.findIndex((prop) => prop.name === "p_parking");
        const parkingProperties = JSON.parse(
          JSON.stringify(this.filterProperties["parking"].properties)
        );
        if (
          parkingIndex !== -1 &&
          parkingProperties[parkingIndex].value !== 3
        ) {
          if (
            parkingProperties.length === 1 &&
            parkingProperties[parkingIndex].value === 2
          ) {
            parkingProperties.push({
              name: "free_parking",
              title: this.t("parking.parking.default"),
              value: 2,
            });
          }
          parkingProperties.splice(parkingIndex, 1);
          result.push({
            title: this.filterProperties["parking"].title,
            properties: parkingProperties,
          });
        }
      }
      return result;
    },
    repairQuality() {
      const qualityOfRepair = this.properties.basic_properties.properties.find(
        (prop) => prop.name === "quality_repair"
      );

      if (!qualityOfRepair) {
        return null;
      }

      return {
        title: this.t("decoration.title"),
        properties: [
          {
            ...qualityOfRepair,
            title: this.t(`decoration.${qualityOfRepair.value}`),
          },
        ],
      };
    },
    dopPropertiesFilter() {
      if (!this.properties || !this.properties.detail_description) return false;
      const prop = ["infrastructure", "furniture"];
      return prop
        .map((p) => {
          let newProp;
          this.properties.detail_description.properties.filter((findP) => {
            if (p == findP.name) {
              newProp = {
                ...findP,
                translate: this.t(findP.name),
              };
            }
          });
          return newProp;
        })
        .filter((v) => v);
    },
    transfer() {
      const transferDesc = this.properties?.additional_payment?.properties.find(
        (item) => item.name === "transfer_description"
      )?.value;
      const transferVal = this.properties?.included_in_price?.properties.find(
        (item) => item.name === "transfer"
      )?.value;

      return {
        transfer: transferVal,
        description: transferDesc
          ? transferDesc
          : this.t("transferDescription"),
      };
    },
    isMeal() {
      if (
        this.getObjectData?.meal?.meal_types &&
        this.getObjectData?.meal?.meal_types.findIndex(
          (item) => item.kind !== "" && item.kind != "nofood"
        ) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAllInclusive() {
      if (this.getObjectData?.meal?.is_all_inclusive) {
        return true;
      } else {
        return false;
      }
    },
    mealTypes() {
      if (this.getObjectData?.meal?.meal_types) {
        return this.getObjectData.meal.meal_types.filter(
          (item) => item.kind && item.kind != "nofood"
        );
      } else {
        return [];
      }
    },
    isFacilities() {
      if (
        !this.dopPropertiesFilter.length &&
        !this.oftenPropertiesForHotel.length &&
        !Object.entries(this.propertiesFilter).length &&
        this.transfer.transfer === undefined
      ) {
        this.$emit("update:modelValue", false);
        return false;
      } else {
        this.$emit("update:modelValue", true);
        return true;
      }
    },
    isPoolHotel() {
      const isSwimpool1 = this.oftenProperties.find(
        (item) => item.name === "swimpool1"
      );
      if (isSwimpool1) return false;
      const propertiesHotel =
        this.getHotelData?.hotel?.properties?.services?.properties;
      const isPool = propertiesHotel
        ? propertiesHotel.find(
            (item) =>
              item.name === "сhildrens_pool" ||
              item.name === "pool_in" ||
              item.name === "pool_out"
          )
        : undefined;
      return isPool;
    },
  },
  methods: {
    iconProperties(name) {
      const iconMap = {
        condition: "condition1",
        balcony: "balcony",
        isolation: "isolation",
        wifi: "wifi1",
        tv: "tv1",
        kettle: "kettle",
        hairdryer: "hairdryer1",
        microwave: "microwave1",
        towels: "towels",
        bedclothes: "bedclothes",
        quality_repair: "repair",
        v_seas: "sea_view",
        v_moun: "mountain_view",
      };

      return iconMap[name];
    },
  },
};
</script>

<style lang="scss" scoped>
.text-space {
  white-space: pre-line;
}
.text-break-spaces {
  white-space: break-spaces;
}
.short-description {
  padding: 10px 0 20px 0;
  margin: 0;
}
.often-properties {
  margin-bottom: 30px;
  color: #000000;
  &--list {
    display: flex;
    flex-wrap: wrap;
    .item-properties {
      display: flex;
      flex: 1 1 auto;
      width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      align-items: center;
      &:nth-child(odd) {
        margin-right: 10px;
      }
      &:nth-child(even) {
        margin-left: 10px;
      }
      .icon-img {
        width: 28px;
        padding-left: 3px;
      }
    }
  }
}
.panel-body {
  color: #000000;
  p {
    margin: 5px 0;
  }
  .info-item-marg-md {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
}
.b-info-item-label {
  display: inline-block;
  max-width: 160px;
  margin: 0;
  margin-left: 16px;
  padding: 0px 5px 3px;
  border-radius: 3px;
  background: #f6f6f6;
  &::before {
    content: attr(data-label);
    font-size: 12px;
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  .b-info-column {
    columns: auto 1;
  }
}
.item-properties {
  span {
    margin-right: 10px;
    font-size: 25px;
    color: #000;
    vertical-align: middle;
  }
}
.no-often-propertie {
  margin-top: 20px;
  margin-bottom: 10px;
}
.no-often {
  margin-top: -10px;
}
</style>
