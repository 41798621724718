<i18n lang="json">
{
	"ru": {
    "panelPhoto": "Фото",
    "panelDescription": "Описание",
    "panelPrice": "Цены",
    "panelMap": "Карта",
    "panelReviews": "Отзывы",
    "panelHotel": "Об отеле"
	},
	"en": {
    "panelPhoto": "Photo",
    "panelDescription": "Description",
    "panelPrice": "Price",
    "panelMap": "Map",
    "panelReviews": "Reviews",
    "panelHotel": "About the hotel"
	}
}
</i18n>
<template>
  <div
    ref="panelScroll"
    v-scroll="handleScroll"
    class="panel-scroll panel-scroll_fixed"
  >
    <div class="panel-scroll__wrap panel-scroll__wrap_flex">
      <div
        v-for="(item, index) in panel"
        :key="index"
        class="panel-scroll__item"
        :class="{ hide: item.hideItem }"
      >
        <div
          :ref="item.name"
          class="panel-scroll__link"
          @click="scrollTo(item.name, $event)"
        >
          {{ t(item.name) }}
        </div>
        <!-- :class="{active: active === item.name}" -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailPanelScroll",
  props: {
    isFacilities: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      panel: [
        {
          name: "panelPhoto",
          hideItem: false,
        },
        {
          name: "panelDescription",
          hideItem: false,
        },
        {
          name: "panelReviews",
          hideItem: false,
        },
        {
          name: "panelMap",
          hideItem: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("detail", ["isHotel"]),
  },
  mounted() {
    if (this.isHotel) {
      this.addHotelBtn();
    }
  },
  watch: {
    isHotel(val) {
      if (val) {
        this.addHotelBtn();
      }
    },
    isFacilities(val) {
      if (!val) {
        let indexDescription = this.panel.findIndex(
          (item) => item.name === "panelDescription"
        );
        this.panel[indexDescription]["hideItem"] = true;
      }
    },
  },
  methods: {
    addHotelBtn() {
      this.panel.splice(2, 0, {
        name: "panelHotel",
        hideItem: false,
      });
    },
    scrollTo(block, e) {
      if (this.$parent.$refs[block]?.$el) {
        const headerHeight = 50;
        const scrollToEl = this.$parent.$refs[block].$el;
        const topOfElement =
          window.pageYOffset +
          scrollToEl.getBoundingClientRect().top -
          headerHeight;
        window.scroll({ top: topOfElement, behavior: "smooth" });
      } else if (this.$parent.$refs[block]) {
        this.$parent.$refs[block].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },

    handleScroll(evt, el) {
      if (window.scrollY > 50) {
        el.classList.add("visible");
      }
      if (window.scrollY <= 50) {
        el.classList.remove("visible");
      }

      for (let i = 0; i < this.panel.length; i++) {
        let height = null;
        if (this.$parent.$refs[this.panel[i].name]?.$el) {
          height = this.$parent.$refs[this.panel[i].name].$el.offsetTop - 80;
        } else if (this.$parent.$refs[this.panel[i].name]) {
          height = this.$parent.$refs[this.panel[i].name].offsetTop - 80;
        }

        let height2 = null;
        if (this.panel[i + 1] && this.panel[i + 1].name) {
          if (this.$parent.$refs[this.panel[i + 1].name]?.$el) {
            height2 =
              this.$parent.$refs[this.panel[i + 1].name].$el.offsetTop - 80;
          } else if (this.$parent.$refs[this.panel[i + 1].name]) {
            height2 = this.$parent.$refs[this.panel[i + 1].name].offsetTop - 80;
          }
        } else {
          height2 = document.body.scrollHeight;
        }
        let item = Object.keys(this.$parent.$refs)[i];
        if (
          this.panel[i].name == item &&
          window.scrollY > height &&
          window.scrollY < height2
        ) {
          if (
            this.panel[i].name &&
            this.$refs &&
            this.$refs[this.panel[i].name]
          ) {
            this.$refs[this.panel[i].name][0].classList.add("active");
          }
        } else {
          if (
            this.panel[i].name &&
            this.$refs &&
            this.$refs[this.panel[i].name]
          ) {
            this.$refs[this.panel[i].name][0].classList.remove("active");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1080px) {
  .panel-scroll {
    &__wrap {
      width: 1080px !important;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .panel-scroll {
    &__wrap {
      width: 1200px !important;
    }
  }
}
.panel-scroll {
  width: 100%;
  // height: 40px;
  background: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  &_fixed {
    position: fixed;
    top: -60px;
    left: 0;
    right: 0;
    z-index: 4;
  }
  &.visible {
    top: 0;
  }

  &__wrap {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 25px;
    padding-right: 25px;
    &_flex {
      display: flex;
    }
  }

  &__item {
    margin: 0 18px;
    padding: 9px 0px;

    &:first-child {
      margin-left: 0;
    }
    &.hide {
      display: none;
    }
  }

  &__link {
    color: #444;
    cursor: pointer;
    &:active,
    &:hover,
    &:focus,
    &.active {
      color: #007bff;
    }
  }
}
</style>
