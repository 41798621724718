import { Offers } from '@/components/api';
const state = () => ({
  offer: null,
});

// getters
const getters = {};

// actions
const actions = {
  setOffersList({ commit, state }, PAYLOAD) {
    return Offers.getOffersList({hotId: PAYLOAD.hotId})
      .then(res=> {
        const offer = res.data.data.offers.find(el => el.id === PAYLOAD.offerId)
        commit("offersListMutate", offer)
        return offer
      })
  },
  setBookingByOffer({ commit, state }, PAYLOAD) {
    return Offers.setBookingByOffer({offerId: PAYLOAD.offerId})
      .then(res=> {
        return res
      })
  },
};

// mutations
const mutations = {
  offersListMutate(state, value) {
    state.offer = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
