<i18n lang="json">
{
	"ru": {
    "bookingBtn": "Внести предоплату {price}",
    "bookingBtnGo": "Перейти к брони",
    "hintText": "Чтобы забронировать жильё, внесите предоплату {price} как можно скорее.",
    "error": "Ошибка",
    "notActual": "Заявка сгорела"
	},
	"en": {
    "bookingBtn": "Make an advance payment { price }",
    "bookingBtnGo": "Go to booking",
    "hintText": "To book accommodation, make a prepayment {price} as soon as possible.",
    "error": "Error",
    "notActual": "Not actual"
	}
}
</i18n>
<template>
  <div class="offer" v-if="offer && offer.payment"> 
    <div class="offer__item">
      <OfferCalc
        :data="offer"
        :currency="offer.payment.currency"
      />
    </div>
    <div class="offer__item">
      <OfferPrice
        :price="Number(offer.payment.cost)"
        :count="Number(offer.hot.countNights)"
        :currency="offer.payment.currency"
        :bonuses="userBonuses"
      />
    </div>
    <div class="offer__item">
      <OfferDate :checkIn="offer.hot.dateBegin" :checkOut="offer.hot.dateEnd" :guests="offer.hot.guestsCnt"/>
    </div>

    <template
      v-if="
        offer.statusType === 'active'
        || (offer.statusType === 'take' && !offer.payment.payed)"
    >
      <div class="offer__item">
        <button
          class="button button_green button_w-100 button_size_md"
          @click="setOffer"
          :class="{ 'loading loading_simple': offerLoading }"
        >
          <span v-if="!offerLoading">{{ t('bookingBtn', { price: formatPrice(totalPrice) }) }}</span>
        </button>
      </div>
      <div class="offer__item">
        <p class="offer__text">
          <span>{{ t('hintText', { price: formatPrice(offer.payment.precost) }) }}</span>	&ensp;
        </p>
      </div>
    </template>

    <template
      v-else-if="offer.statusType === 'take' && offer.payment.payed"
    >
      <div class="offer__item">
        <button
          class="button button_green button_w-100 button_size_md"
          @click="gotoOffer"
          :class="{ 'loading loading_simple': offerLoading }"
        >
          <span v-if="!offerLoading">{{ t('bookingBtnGo') }}</span>
        </button>
      </div>
    </template>

    <template v-else>
      <div class="offer__item">
        <p class="offer__text big">
          <span>{{ t('notActual') }}</span>
        </p>
      </div>
    </template>
    <Modal
      ref="offerError"
      :title="t('error')"
      v-model="offerErrorView"
      @close="offerErrors = []"
      class="offer-error"
      min-width="330px"
      max-width="330px"
    >
      <div class="offer-error__modal">
        <p v-for="error in offerErrors">{{ error }}</p>
      </div>
    </Modal>
  </div>
  <div v-else class="offer offer_loading loading" />
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import offerStoreModule from "./store";
import OfferCalc from "./OfferCalc";
import OfferPrice from "./OfferPrice";
import OfferDate from "./Date";
import Modal from "@sutochno/modal";
import AsidePlaceholder from "@/components/base/Detail/Aside/Placeholder";
import { useI18n } from 'vue-i18n';

export default {
  name: "Offer",
  components: {
    OfferCalc,
    OfferPrice,
    OfferDate,
    Modal,
    AsidePlaceholder
  },
  props: {
    offerId: {
      type: [Boolean, Number],
      default: false
    },
    hotId: {
      type: [Boolean, Number],
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      offerErrorView: false,
      offerErrors: [],
      offerLoading: false
    };
  },
  computed: {
    ...mapState('offerStoreModule', ['offer']),
    ...mapState("user", ["isAuth", "userData"]),
    totalPrice() {
      const { cost, precost } = this.offer?.payment
      const tenPercent = cost * 0.1
      if(this.userBonuses > tenPercent) {
        return precost - tenPercent
      }
      return precost - this.userBonuses
    },
    userBonuses() {
      const { balance_total: balance } = this.userData
      if(!this.isAuth || !balance) {
        return 0;
      }
      return balance;
    },
  },
  async serverPrefetch() {
    this.registerStore();
    return this.setOffersList({ offerId: this.offerId, hotId: this.hotId })
  },
  created() {
    const alreadyData = !!this.$store.state.offerStoreModule;    

    // Регистрируем модуль
    this.registerStore();

    if (!alreadyData) {
      this.setOffersList({ offerId: this.offerId, hotId: this.hotId })
    }
  },
  methods: {
    ...mapActions('offerStoreModule', ['setOffersList', 'setBookingByOffer']),
    registerStore() {
      // !!this.$store.state.locationStoreModule
      // Сохраняем предыдущее состояние, если оно внедрялось на стороне сервера
      this.$store.registerModule("offerStoreModule", offerStoreModule, {
        preserveState: !!this.$store.state.offerStoreModule,
      });
    },
    gotoOffer() {
      document.location.href = `/cabinet/notices/${this.offer.order_id}`;
    },
    setOffer() {
      this.offerLoading = true;
      this.setBookingByOffer({ offerId: this.offerId })
        .then(res => {
          this.offerLoading = false;
          const data = res.data
          if(!data?.success) {
            this.offerErrorView = true;
            if(data?.errors?.length > 0) {
              this.offerErrors = [];
              data.errors.forEach(el => this.offerErrors.push(el))
            }
          } else {
            const { protocol, host } = window.location;
            window.location = `${protocol}//${host}/pay/${data.data.order_id}`
          }
        })
        .catch(e => {
          this.offerErrorView = true;
          this.offerLoading = false;
        })
    },
    formatPrice(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.offer.payment.currency.code,
        minimumFractionDigits: 0,
      }).format(item);
    }
  },
};
</script>

<style lang="scss" scoped>
.offer {
  &_loading {
    position: relative;
    min-height: 300px;
  }
  &__item {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__text {
    text-align: center;
    color: #717171;

    &.big {
      font-size: 16px;
    }
  }
}
.offer-error {
  &__modal {
    padding: 0 10px 10зч;
  }
}
</style>
