<i18n lang="json">
{
	"ru": {
    "btnBooking": "Запрос на бронь"
	},
	"en": {
    "btnBooking": "Booking Request"
	}
}
</i18n>
<template>
  <div class="fixed-price">
    <Wrap :detail-block-no-mr="detailBlockNoMr">
      <div class="aside-line">
        <button
          class="button button_green button_w-100 button_size_md"
          @click="scrollTo"
        >
          <span v-if="isMb" class="icon-app-lightning"></span>
          <span>{{ btnTitle }}</span>
        </button>
      </div>
    </Wrap>
  </div>
</template>
<script>
import Wrap from "@/components/base/Detail/Wrap";

export default {
  name: "DetailBookingFix",
  components: {
    Wrap,
  },
  props: {
    btnTitle: {
      type: String,
      required: true,
    },
    isMb: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailBlockNoMr: true,
      visible: true,
    };
  },
  methods: {
    scrollTo(block, e) {
      this.$parent.$refs.btnBooking.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-app-lightning {
  font-size: 16px;
  padding-right: 10px;
}
.btn-primary {
  color: #fff;
  background-color: #498bc3;
  border-color: #3671a3;
  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
}
.btn-price {
  padding: 0px 28px;
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  line-height: 20px;
  border-radius: 3px;
  margin-bottom: 5px;
}
.fixed-price {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 4px rgba(0, 0, 0, 0.15);
  animation: fadein 0.2s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
.aside-line {
  margin: 0 20px;
}
</style>
