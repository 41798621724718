<template>
  <div class="card-price">
    <div class="card-price__price">
      <BaseViewPrice
        :price="getObjectPrice"
        count="top"
        :currency="currencyCalc"
        :default-price="priceNight ? priceNight.value : ''"
        :prepay="t('questionPrepayment')"
        :hot="getObjectPrice.object_hot"
        :old-price="prices.max_price"
        :sale-price="prices.total_price"
      />
    </div>
    <div class="card-price__calc">
      <PriceCalcDetail :prices="prices" />
    </div>
    <BonusSale v-if="bonuses > 0" :sale="bonuses"/>
    <CalcNotifications class="card-price__calc-notifications" :sale="bonuses" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from 'vuex';
import { useI18n } from "vue-i18n";

import { usePricesObject, useBonuses, useCurrencyCalc } from "@/composables/price";

import PriceCalcDetail from "@/components/base/PriceCalcDetail/PriceCalcDetail.vue";
import BaseViewPrice from "@/components/base/BaseViewPrice.vue";
import BonusSale from "./BonusSale.vue";
import CalcNotifications from "@/components/base/Detail/Aside/CalcNotifications";

const store = useStore();
const { t } = useI18n();

const prices = usePricesObject();
const bonuses = useBonuses();
const currencyCalc = useCurrencyCalc();

const props = defineProps({
  object: {
    type: Object,
    required: true,
  }
});

const getObjectPrice = computed(() => store.getters['detail/getObjectPrice']);
const priceNight = computed(() => props.object.extprices.find((p) => p.interval_start == 0));

</script>
