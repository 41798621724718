<i18n lang="json">
{
  "ru": {
    "title": "Подтвердите телефон",
    "textModal": {
      "0": "Введите пароль из СМС/Viber, отправленный на номер",
      "1": "Введите код из СМС, отправленный на номер"
    },
    "textError": "Проверьте правильность введенного {n}",
    "receiveYourPassword": {
      "0": "Пароль не пришел?",
      "1": "Код не пришел?"
    },
    "retrievePasswordAgain": "Получить {n} повторно",
    "textsuccess": "Телефон успешно проверен",
    "confirmationPayment": "К подтверждению и оплате…",
    "youCanRequestNewPasswordVia":"Вы можете запросить новый {n} через",
    "minutes": "0|{n} минуту |{n} минуты|{n} минут",
    "seconds": "0|{n} секунду |{n} секунды|{n} секунд",
    "password": "пароль | пароля",
    "code": "код | кода",
    "continue": "Продолжить",
    "enterCode": "Ошибка авторизации, введите код с картинки через:"
  },
  "en": {
    "title": "Confirm phone",
    "textModal": { 
      "0": "Enter password from SMS/Viber sent to number",
      "1": "Enter code from SMS sent to number"
    },
    "textError": "Check that {n} entered is correct",
    "receiveYourPassword": { 
      "0": "Password not received?",
      "1": "Code not received?"
    },
    "retrievePasswordAgain": "Retrieve {n} again",
    "textsuccess": "Phone verified successfully",
    "confirmationPayment": "For confirmation and payment ...",
    "youCanRequestNewPasswordVia":"You can request a new {n} via",
    "minutes": "0|{n} minute |{n} minutes|{n} minutes",
    "seconds": "0|{n} second |{n} seconds|{n} seconds",
    "password": "password | password",
    "code": "code | code",
    "continue": "Continue",
    "enterCode": "Authorization error, enter the code from the image via:"
  }
}
</i18n>
<template>
  <Modal
    v-model="isModalCode"
    class="modal-sms"
    :class="{ 'modal-sms--loading': isLoading }"
    :is-loading="isLoading"
    :title="t('title')"
    min-height="308px"
    min-width="520px"
  >
    <template v-if="getCaptchaData?.captcha_image">
      <Captcha
        v-model="captchaValue"
        :captchaImage="getCaptchaData.captcha_image"
      />

      <div class="field-error" v-if="currentTime">
        {{ t("enterCode") }}
        <template v-if="Math.floor(currentTime / 60)">{{
          t("minutes", Math.floor(currentTime / 60))
        }}</template>
        {{ t("seconds", currentTime % 60) }}
      </div>
      <button
        class="button button_w-100 button_green button_size_md captcha_submit"
        :class="{ loading: isLoading }"
        @click="sendCode(true)"
        :disabled="buttonDisabled"
      >
        {{ t("continue") }}
      </button>
    </template>
    <template v-else>
      <template v-if="isLoading">
        <div class="shadow"></div>
        <p class="ph-item ph-item--h34"></p>
      </template>
      <p v-else class="tmp-font--medium">
        {{ t(isNewUser ? "textModal.0" : "textModal.1") }}
        <span v-if="inputPhoneNumber" class="text-bold"
          >+{{ inputPhoneNumber }}</span
        >
      </p>
      <div class="inputs-wrap">
        <input
          v-for="(el, i) in 5"
          :key="i"
          :ref="`input${i}`"
          :class="{
            'input--invalid': error,
            'input--success': success,
          }"
          class="input"
          type="number"
          inputtype="numeric"
          maxLength="1"
          size="1"
          min="0"
          max="9"
          pattern="\d [0-9]"
          @keyup="fillCode($event, i)"
          @keydown="onKeyDown"
          @paste="onPaste"
          @click="selectInput(i)"
        />
      </div>
      <p v-if="isLoading" class="ph-item"></p>
      <template v-else>
        <span v-if="retrievePasswordAgain">
          <p v-if="error" class="error tmp-font--small">
            <template v-if="textError">
              {{ textError }}
            </template>
            <template v-else>
              {{
                t("textError", {
                  n: isNewUser ? t("password", 1) : t("code", 1),
                })
              }}
            </template>
          </p>
          <template v-if="currentTime > 0 && btnAuth">
            <div class="new-password-via tmp-font--small color-gray">
              {{
                t("youCanRequestNewPasswordVia", {
                  n: isNewUser ? t("password", 0) : t("code", 0),
                })
              }}<br />
              {{ t("minutes", Math.floor(currentTime / 60)) }}
              {{ t("seconds", currentTime % 60) }}
            </div>
          </template>
          <template v-else>
            <span class="tmp-font--small color-gray"
              >{{
                t(
                  isNewUser ? "receiveYourPassword.0" : "receiveYourPassword.1"
                )
              }}
            </span>&nbsp;
            <a
              class="link link--password-again tmp-font--link"
              @click.prevent="clickPasswordAgain"
              >{{
                t("retrievePasswordAgain", {
                  n: isNewUser ? t("password", 0) : t("code", 0),
                })
              }}</a
            >
          </template>
        </span>
        <div v-if="pending" class="loader-pulse"></div>
        <template v-if="success">
          <p class="tmp-font--small success">
            {{ t("textsuccess") }}
          </p>
          <div class="loader-pulse"></div>
          <p class="tmp-font--small redirect-text">
            {{ t("confirmationPayment") }}
          </p>
        </template>
      </template>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { Detail } from "@/utils/gtm/";

import { Users } from "@/components/api/";
import Modal from "@sutochno/modal";
import Captcha from "@sutochno/captcha";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModalCodeDesktop",
  components: {
    Modal,
    Captcha,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      codeAccess: [null, null, null, null, null],
      error: null,
      retrievePasswordAgain: true,
      success: false,
      pending: false,
      isBooking: false, //бронирование
      currentTime: 0,
      timer: null,
      btnAuth: false,
      textError: "",
      excludeKeys: ["Delete", "Backspace"],
      isLoading: false,
      isNewUser: false,
      captchaValue: "",
      captchaData: null,
      passwordForNewUser: false
    };
  },
  computed: {
    ...mapState("user", ["whitelabel", "isAuth"]),
    isModalCode: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    inputPhoneNumber() {
      return this.phone
        ? Number(this.phone.split(" ").join("").slice(1))
        : null;
    },
    getCaptchaData() {
      if (this.captchaData?.captcha_code) {
        return this.captchaData;
      }
      return null;
    },
    buttonDisabled() {
      return !this.captchaValue || this.currentTime;
    },
  },
  watch: {
    isModalCode(val) {
      if (val) {
        this.init();
      } else {
        this.codeAccess = [null, null, null, null, null];
      }
    },
  },
  methods: {
    ...mapActions("booking", ["authAction", "codeAction"]),
    onPaste(e) {
      //e.target.value будет пустым хотя если вывести event там все будет, какой то баг
      //поэтому нашел такой костыль
      const VALUE = e.clipboardData.getData("text").trim();
      if (VALUE.length === 5) {
        for (var i = 0; i < VALUE.length; i++) {
          let char = Number(VALUE[i]);
          this.codeAccess[i] = char;
          this.$refs[`input${i}`][0].value = char;
        }
        this.checkCode();
      }
      e.preventDefault();
      return false;
    },
    init() {
      this.isLoading = true;
      this.error = false;
      setTimeout(() => {
        this.selectInput(0);
      }, 10);
      this.sendCode();
    },
    onKeyDown(event) {
      const VALUE = event.target.value || event.key;

      if (VALUE && VALUE.length > 0) {
        event.target.value = VALUE[0];
        event.preventDefault();
      }

      if (this.excludeKeys.includes(event.key) || /^\d+$/.test(VALUE)) {
        return true;
      }

      event.target.value = "";
      event.preventDefault();
      return false;
    },
    fillCode(event, index) {
      let VALUE = event.target.value || event.key;
      if (VALUE.length > 0) {
        VALUE = VALUE[0];
        event.target.value = VALUE[0];
        event.preventDefault();
      }
      if (this.excludeKeys.includes(event.key)) {
        //если клавиши удаления то очищаем
        this.$refs[`input${index}`][0].value = "";
        this.codeAccess[index] = null;
        const prevInput = this.$refs[`input${index - 1}`]
          ? this.$refs[`input${index - 1}`][0]
          : null;
        if (prevInput) {
          prevInput.select();
        }
        return true;
      } else if (/^\d+$/.test(VALUE)) {
        // если цифры, то заполняем
        // const val = this.$refs[`input${index}`][0].value;
        this.codeAccess[index] = Number(VALUE);
        event.target.value = VALUE;
        if (!this.codeAccess.includes(null)) {
          this.checkCode();
        }
        const nextInput = this.$refs[`input${index + 1}`]
          ? this.$refs[`input${index + 1}`][0]
          : null;
        if (nextInput) {
          nextInput.select();
        } else {
          this.$refs[`input${index}`][0].blur();
        }
        return true;
      } else {
        event.target.value = "";
        event.preventDefault();
        return false;
      }
    },
    selectInput(numInput) {
      if (this.$refs[`input${numInput}`][0]) {
        this.$refs[`input${numInput}`][0].select();
      }
    },
    clearInput() {
      setTimeout(() => {
        this.codeAccess.forEach((item, index) => {
          this.codeAccess[index] = null;
          const input = this.$refs[`input${index}`]
            ? this.$refs[`input${index}`][0]
            : null;
          if (input) {
            input.value = "";
            input.classList.remove("input--invalid");
          }
        });
      }, 40);
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.currentTime > 0) {
            this.currentTime--;
          } else {
            clearTimeout(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async checkCode(
      phone = this.inputPhoneNumber,
      code = this.codeAccess.join(""),
      terms = 1,
      captcha_code = this.getCaptchaData?.captcha_code
    ) {
      this.pending = true;
      if (this.isAuth && this.phone) {
        await this.approveContact();
      } else {
        await this.codeAction({ phone, code, terms, captcha_code })
          .then((res) => {
            if (res.data?.success) {
              Detail.gaLoginStatus("success", "sms");
              sendEventGtag("draft_sa_m", {
                click: "pwd_ok",
              });
              this.error = false;
              this.retrievePasswordAgain = false;

              this.success = true;
              this.$emit("success");

              this.btnAuth = false;
            } else {
              this.checkCaptcha(res);

              Detail.gaLoginStatus("error", "sms");
              sendEventGtag("draft_sa_m", {
                click: "pwd_error",
              });
              this.error = true;
              this.retrievePasswordAgain = true;
              this.currentTime = res.data.data.timeout || 0;
              if (this.currentTime > 1) {
                this.btnAuth = true;
                this.startTimer();
              }
            }
            if (Array.isArray(res.data.errors) && res.data.errors.length) {
              this.textError =
                res.data.errors[0] == "логин или пароль указаны неверно"
                  ? this.t("textError", {
                      n: this.isNewUser
                        ? this.t("password", 1)
                        : this.t("code", 1),
                    })
                  : res.data.errors[0];
            }
            return res;
          })
          .catch(() => {
            Detail.gaLoginStatus("error", "sms");
          });
      }
      this.pending = false;
    },
    sendCode(isSendCapchaCode) {
      if (this.error) {
        this.clearInput();
        this.error = false;
      }
      let params = { phone: this.inputPhoneNumber, terms: 1, forgot: this.passwordForNewUser ? 1 : "" };
      if (isSendCapchaCode) {
        params = {
          phone: this.inputPhoneNumber,
          value: this.inputPhoneNumber,
          terms: 1,
          captcha_code: this.getCaptchaData.captcha_code,
          captcha_value: this.captchaValue,
        };
      }
      // методы для авторизованного пользователя
      if (this.isAuth) {
        this.addContact();
      } else {
        this.codeAction(params)
          .then((res) => {
            this.checkCaptcha(res);
            if(isSendCapchaCode) {
              const captchaCode = res.data?.data?.messages?.captcha_code;

              sendEventGtag("login_captcha", {
                show: captchaCode ? "error" : "success", 
                login_type: 'fast'
              });
            }

            if (res.data.actions.auth) {
              this.isNewUser =
                res.data.actions.auth === "code sent" ? false : true;
              this.passwordForNewUser =
                res.data.actions.auth === "show password for new user" ? true : false;
            } else {
              this.isNewUser = true;
              this.passwordForNewUser = false;
            }
            this.currentTime = res.data.data.timeout || 0;
            if (this.currentTime > 1) {
              this.btnAuth = true;
              this.startTimer();
            }
            if (Array.isArray(res.data.errors) && res.data.errors.length) {
              this.textError = res.data.errors[0];
            }
            this.isLoading = false;
          })
          .catch((err) => {
            if (err) console.error(err);
            this.error = true;
            this.textError = "ошибка запроса кода";
            this.isLoading = false;
          });
      }
    },
    // добавление телефона в лк пользователя
    addContact() {
      let formData = new FormData();

      formData.append("contactValue", this.inputPhoneNumber || "");
      formData.append("typeId", "2");
      formData.append("isLandline", "0");

      Users.addContact(formData)
        .then((response) => {
          // если нас просит редиректнуть на повторную отправку, то выходим
          if (
            response.data.actions &&
            response.data.actions.redirect === "users/requestApproveContact"
          ) {
            this.requestApproveContact();
          } else if (
            Array.isArray(response.data.errors) &&
            response.data.errors.length
          ) {
            this.textError = response.data.errors[0];
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err) console.error(err);
          this.error = true;
          this.textError = "ошибка добавления номера";
          this.isLoading = false;
        });
    },
    //повторный запрос пароля
    requestApproveContact() {
      let formData = new FormData();
      formData.append("value", this.inputPhoneNumber);
      Users.requestApproveContact(formData)
        .then((res) => {
          this.currentTime = res.data.data.timeout || 0;
          if (res.data.data.timeout > 0) {
            this.btnAuth = true;
            this.startTimer();
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    //проверка пароля подтверждения телефона
    approveContact() {
      let formData = new FormData();
      formData.append("value", this.phone);
      formData.append("code", this.codeAccess.join(""));
      return Users.approveContact(formData)
        .then((res) => {
          if (res.data.actions && res.data.actions.success) {
            this.success = true;
            this.error = false;
          } else {
            this.success = false;
            this.error = true;
          }
          // this.disabledPass = false;
          return res;
        })
        .catch((err) => {
          console.warn(err);
          return err;
        });
    },
    clickPasswordAgain() {
      if (this.isAuth) {
        this.requestApproveContact();
      } else {
        this.sendCode();
        this.btnAuth = true;

        if(this.passwordForNewUser) {
          sendEventGtag("login_interference", {
            click: 'show_password_new_user',  
            login_type: 'fast'
          });
        }
      }
    },
    checkCaptcha(res) {
      if (res.data?.data?.messages?.captcha_code) {
        const messages = res.data.data.messages;
        const timer = messages.timeout ? messages.timeout : 1;
        const codeAccess = JSON.stringify(this.codeAccess);
        this.showCaptcha(messages);
        this.isLoading = false;

        setTimeout(() => {
          if (
            res.data?.actions?.auth === "resend request" &&
            timer <= 10 &&
            codeAccess === JSON.stringify(this.codeAccess)
          ) {
            this.checkCode();
          }
        }, +(timer + "000"));
      } else {
        this.removeCaptcha();
      }
    },
    showCaptcha(messages) {
      this.captchaValue = "";
      this.captchaData = messages;
    },
    removeCaptcha() {
      this.captchaValue = "";
      this.captchaData = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-sms {
  :deep(.sc-modal-header) {
    margin: 0 10px;
  }
  :deep(.sc-modal-body) {
    margin: 20px auto;
    width: 320px;
  }
  &.modal-sms--loading {
    :deep(.sc-modal-body) {
      position: relative;
      overflow: hidden;
    }

    .shadow {
      position: absolute;
      left: -10%;
      top: -10%;
      bottom: -10%;
      width: 30px;
      opacity: 0;
      background: #fff;
      animation: loader 1.3s linear infinite;
      filter: blur(20px);
      transform: rotate(25deg);
    }
    .ph-item {
      width: 100%;
      height: 17px;
      background: #d8d8d8;
      border-radius: 10px;

      &--h34 {
        height: 34px;
      }
    }
  }

  .new-password-via {
    padding-top: 8px;
  }
  .inputs-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 20px;

    input[type="number"] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    .input {
      display: flex;

      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: solid 1px #d8d8d8;
      margin-right: 15px;
      border-radius: 5px;
      padding: 1px 15px;
      min-height: 51px;
      max-height: 51px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
        border: solid 1px #000000;
      }
      &.letter-spacing {
        letter-spacing: 10px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input--success {
      border-color: #417505;
    }
    .input--invalid {
      border-color: #e10600;
    }
  }
  .success {
    color: #417505;
  }
  .error {
    color: #e10600;
  }
  .link {
    color: #2d6cb4;

    &--password-again {
      cursor: pointer;
    }
  }
  .redirect-text {
    margin-top: 0;
    text-align: center;
    color: #717171;
  }
}

.captcha_submit {
  margin-top: 10px;
}
.color-gray {
  color: #717171;
}

.text-bold {
  font-weight: bold;
}
.loader-pulse {
  margin: 20px auto 0;
  width: 36px;
  height: 36px;
  background-image: url("~/assets/img/gif/loader-bronevik-gray.gif");
  background-size: contain;
}

@keyframes loader {
  from {
    left: -10%;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
.button:disabled {
  opacity: 0.65;
}

.field-error {
  color: red;
  font-size: 14px;
  margin: 10px 0;
}
</style>
