import { watch } from "vue";

const useReplaceQuery = async ({ target, finalValue } = {}) => {
  //TODO: NUXT
  // проверить работоспособность
  const route = useRoute();

  const checkTargetValue = async (query = {}) => {
    const isTarget = Object.hasOwn(query, target);
    
    if(isTarget) {
      const targetValue = query[target];

      if (finalValue != targetValue) {
        await navigateTo({
          query: { ...query, [target]: finalValue },
        });
      }
    }
  };

  await checkTargetValue(route.query);

  watch(
    () => route.query,
    (newQuery) => {
      checkTargetValue(newQuery);  
    },
    { deep: true }
  );
};

export { useReplaceQuery };

export default {
  useReplaceQuery,
};