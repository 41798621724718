<template>
  <div class="detail-content grid-area-content">
    <div class="placeholder-loader placeholder-loader_content">
      <div class="type w-50"></div>
    </div>

    <div class="placeholder-loader">
      <div class="slider slider_preview">
        <div class="placeholder-loader__img slider__img"></div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info">
        <div class="info__item">
          <div class="title"></div>
        </div>
        <div class="info__subitem">
          <div class="type w-50"></div>
          <div class="type w-50"></div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info">
        <div class="info__item">
          <div class="title"></div>
        </div>
        <div class="info__item info__item_flex">
          <div class="info__subitem w-50">
            <div v-for="(rul, index) in 3" class="type type_min"></div>
          </div>
          <div class="info__subitem w-50">
            <div v-for="(rul, index) in 3" class="type type_min"></div>
          </div>
        </div>
        <div class="info__item info__item_flex">
          <div class="info__subitem w-50">
            <div v-for="(rul, index) in 3" class="type w-50"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info">
        <div class="info__item">
          <div class="title"></div>
        </div>
        <div v-for="(rulGroup, index) in 3" class="info__item info__item_flex">
          <div v-for="(rulSub, index) in 2" class="info__subitem w-50">
            <div class="subtitle"></div>
            <div v-for="(rul, index) in 5" :key="index" class="type w-50"></div>
          </div>
        </div>
        <div v-for="(rulGroup, index) in 3" class="info__item info__item_flex">
          <div class="info__subitem w-100">
            <div class="subtitle"></div>
            <div v-for="(rul, index) in 3" class="type w-50"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info">
        <div class="info__item">
          <div class="title"></div>
        </div>
        <div class="info__item info__item_flex">
          <div class="info__subitem w-30">
            <div v-for="(rul, index) in 3" class="type type_middle"></div>
          </div>
          <div class="info__subitem w-70">
            <div v-for="(rul, index) in 3" class="type type_middle"></div>
          </div>
        </div>
        <div class="info__item info__item_flex">
          <div class="info__subitem w-100">
            <div class="subtitle"></div>
            <div v-for="(rul, index) in 2" class="type w-50"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader">
      <div class="info">
        <div class="placeholder-loader__img map"></div>
      </div>

      <div class="placeholder-loader placeholder-loader_content">
        <div class="info info_map">
          <div class="info__item info__item_flex">
            <div class="w-50">
              <div class="subtitle w-50"></div>
              <div v-for="(rul, index) in 5" class="type type_middle"></div>
            </div>
            <div class="w-50">
              <div class="type type_middle block-right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info">
        <div class="info__item">
          <div class="title"></div>
        </div>
        <div class="info__item info__item_flex">
          <div class="info__subitem w-20">
            <div class="square square_rating"></div>
          </div>
          <div class="info__subitem w-40">
            <div v-for="(item, index) in 3" class="type type_middle"></div>
          </div>
          <div class="info__subitem w-40">
            <div v-for="(item, index) in 3" class="type type_middle"></div>
          </div>
        </div>
        <div class="info__item">
          <div class="offset title"></div>
        </div>
        <div v-for="(item, index) in 5" class="info__item info__item_flex">
          <div class="info__subitem w-20">
            <div class="circle"></div>
          </div>
          <div class="info__subitem w-80">
            <div class="title"></div>
            <div v-for="(item, index) in 2" class="offset">
              <div class="type type_min"></div>
              <div v-for="(item, index) in 3" class="type"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="placeholder-loader placeholder-loader_content">
      <div class="info info_map">
        <div class="info__item info__item_flex">
          <div class="w-50">
            <div v-for="(item, index) in 2" class="offset">
              <div class="subtitle w-50"></div>
              <div v-for="(item, index) in 2" class="type type_middle"></div>
            </div>
          </div>
          <div class="w-50">
            <div class="circle circle_big block-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Placeholder",
};
</script>

<style lang="scss" scoped>
// .detail-desktop {
//   display: grid;
//   grid-template-areas:
//     ". bar bar bar ."
//     ". detail-content . detail-aside .";

//   grid-template-columns: 1fr 623px 10px 320px 1fr;
//   /*width: 953*/
//   grid-template-rows: 74px auto;
//   background: #f6f6f6;
// }

// @media only screen and (min-width: 1080px) {
//   .detail-desktop {
//     grid-template-columns: 1fr 702px 10px 320px 1fr;
//     /*width: 1032*/
//   }
// }

// @media only screen and (min-width: 1280px) {
//   .detail-desktop {
//     grid-template-columns: 1fr 819px 10px 320px 1fr;
//     /*width: 1149*/
//   }
// }

// .grid-area {
//   &-content {
//     grid-area: detail-content;
//   }

//   &-aside {
//     grid-area: detail-aside;
//   }
// }
.detail-content {
  padding-top: 11px;
}
.placeholder-loader {
  margin: 0px 0 20px 0;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;

  border: none;

  display: flex;
  flex-wrap: wrap;

  animation: fadein 0.5s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &_content {
    padding: 20px;
  }

  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }

  .w-40 {
    width: 40%;
  }

  .w-50 {
    width: 50%;
  }
  .w-70 {
    width: 70%;
  }
  .w-80 {
    width: 80%;
  }
  .w-100 {
    width: 100%;
  }

  .offset {
    margin-top: 20px;

    &_long {
      margin-top: 40px;
    }
  }

  .slider {
    height: 100%;
    width: 100%;

    &_preview {
      .slider__img {
        height: 520px;
      }
    }
    &_nav {
      display: flex;

      .slider__img {
        width: calc(100% / 6);
        height: 102px;
        outline: 2px solid #fff;
        outline-offset: -2px;
      }
    }
  }

  .block-right {
    display: inline-block;
    float: right;
  }

  .info {
    width: 100%;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: stretch;
    // align-content: stretch;

    &__item {
      &_flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }
    &__subitem {
      margin-top: 30px;
    }

    &_map {
      min-height: 150px;
    }

    .map {
      height: 492px;
    }
  }

  &__img {
    grid-area: carousel;
    width: 100%;
    background-color: #ededef;
    background-image: url("~/assets/img/no_photo.svg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
  }
  .type,
  .place,
  .price,
  .button,
  .square,
  .title,
  .subtitle,
  .circle {
    background: #d8d8d8;
    border-radius: 15px;
    // margin: 0 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .type {
    top: 214px;
    height: 15px;
    width: calc(100% - 40px);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
    &_min {
      width: 30%;
    }
    &_middle {
      width: 60%;
    }
    &_initial {
      width: initial;
    }
  }
  .place {
    height: 10px;
    width: calc(60% - 40px);
  }
  .price {
    height: 17px;
    width: calc(60% - 40px);
    margin: 0 auto;
    margin-top: 30px;
  }
  .button {
    height: 35px;
    width: 101px;
    border-radius: 3px;
    margin: 0 auto;

    &_big {
      height: 42px;
    }

    &_flex {
      width: calc(100% / 3);
      padding: 0 5px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  .square {
    height: 60px;
    width: 80px;
    &_rating {
      height: 80px;
    }
  }
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    &_big {
      width: 100px;
      height: 100px;
    }
  }
  .title {
    width: 30%;
    height: 25px;
  }
  .subtitle {
    width: 25%;
    height: 20px;
    margin-bottom: 10px;
  }
}
</style>
