<i18n lang="json">
{
	"ru": {
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "behind": "за",
    "calcSeason": "по сезонной цене",
    "DopPersonsSeason": "сезонная доплата за {n} гостей | сезонная доплата за {n} гостя | сезонная доплата за {n} гостя | сезонная доплата за {n} гостей",
    "DopPersons": "доплата за {n} гостей | доплата за {n} гостя | доплата за {n} гостя | доплата за {n} гостей",
    "sale": "скидка по цене от",
    "clean": "плата за уборку",
    "hot": "при заселении сегодня до {time}",
    "showCalc": "Показать расчёт",
    "hideCalc": "Скрыть расчёт",
    "precost": "предоплата",
    "aftercost": "при заселении"
	},
	"en": {
    "nights": "less night | {n} night | {n} nights | {n} nights",
    "calcSeason": "at a seasonal price",
    "DopPersonsSeason": "seasonal surcharge for 0 guests | seasonal surcharge for {n} guest | seasonal surcharge for {n} guests | seasonal surcharge for {n} guests",
    "DopPersons": "surcharge for 0 guests | surcharge for {n} guest | surcharge for {n} guests | surcharge for {n} guests",
    "sale": "for the price of",
    "clean": "cleaning fee",
    "hot": "when checking in today before {time}",
    "showCalc": "View calculate",
    "hideCalc": "Hide calculate"
	}
}
</i18n>
<template>
  <div
    class="booking__calc"
  >
    <template>
      <div class="calc__item">
        <div class="calc__result">
          <span>
            {{ formatNum(perNight) }} &#215;
            {{ t("nights", data.hot.countNights) }}
          </span>
          <span>{{ formatNum(data.payment.cost) }}</span>
        </div>
        <div class="calc__text">
          <span>{{ `${t('behind')} ${t("nights", data.hot.countNights)}` }}</span>
        </div>
      </div>
      <div class="calc__item">
        <div>
          <div class="calc__result">
            <span>{{ t("precost") }}</span>
            <span>{{ formatNum(data.payment.precost) }}</span>
          </div>
        </div>
      </div>
      <div class="calc__item">
        <div>
          <div class="calc__result">
            <span>{{ t("aftercost") }}</span>
            <span>{{ formatNum(data.payment.cost - data.payment.precost) }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "OfferCalc",
  props: {
    data: {
      type: Object,
    },
    currency: {
      type: Object,
    },
    dop: {
      type: Array,
    },
    more: {
      type: Array,
    },
    hot: {
      default: false,
    },
    slide: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    perNight() {
      return this.data.payment.cost / this.data.hot.countNights
    }
  },
  methods: {
    formatNum(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.currency.code,
        minimumFractionDigits: 0,
      }).format(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.booking {
  &__sale {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .sale {
      &__icon {
        min-width: 43px;
        .icon {
          width: 34px;
          border-radius: 2px;
          background: #e10600;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          text-align: center;
          position: relative;
          padding-left: 3px;
          white-space: nowrap;

          &:before {
            border-left: 9px solid #e10600;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;

            content: "";
            display: block;
            height: 0;
            width: 0;

            position: absolute;
            right: -8px;
            top: 0;
          }
          &:after {
            content: "";
            display: block;
            width: 2px;

            position: absolute;
            right: -9px;
            top: 0;
            bottom: 0;

            background: #fff;
          }
        }
      }
      &__price {
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        text-decoration-line: line-through;
        color: #717171;
        padding-left: 6px;
      }
    }
  }
  &__calc {
    &_hot {
      padding-top: 10px;
    }
    .calc {
      &__button {
        margin-top: 10px;
      }
      &__item {
        display: flex;
        flex-direction: column;

        padding-top: 10px;
        &:first-child {
          padding-top: 0px;
        }
      }
      &__result {
        display: flex;
        justify-content: space-between;

        width: 100%;
        font-size: 14px;
        line-height: 16px;

        &_sale {
          font-size: 14px;
          line-height: 16px;
          text-decoration-line: line-through;
          color: #717171;
        }
      }
      &__sale-price {
        color: #e10600;
      }
      &__text {
        font-size: 14px;
        line-height: 16px;
        color: #717171;
        &_sticker {
          display: block;
          background: url("~/assets/img/sale-sticker.svg");
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          width: 16px;
          height: 16px;
        }
        // &_sale-text {
        //   padding-left: 6px;
        // }
        &_sale {
          font-size: 14px;
          line-height: 16px;
          color: #e10600;
          display: flex;
        }
      }
    }
  }
}
</style>
