<template>
  <DetailDesktop v-if="viewPort === 'desktop'" :id="route.params.id"/>
  <DetailMobile v-else :id="route.params.id"/>
</template>

<script setup>
import DetailDesktop from "../../components/desktop/pages/Detail.vue";
import DetailMobile from "../../components/mobile/pages/Detail.vue";

import { useStore } from "vuex";

const store = useStore();
const route = useRoute()

const viewPort = computed(() => store.state.user.viewPort);

definePageMeta({
  name: "Detail",
  path: `${process.env.VUE_APP_BASEPATH}/detail/:id`,
})
</script>