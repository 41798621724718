<i18n lang="json">
{
	"ru": {
		"btn": "Подробнее",
    "text": "1. Вы не платите комиссию.<br> 2. Проживаете в выбранные даты по заранее согласованной стоимости.<br>3. Получаете круглосуточную поддержку от Суточно.ру.",
    "title": "Безопасное бронирование"
	},
	"en": {
		"btn": "More details",
    "text": "1. You do not pay a commission. <br> 2. You live on selected dates at a pre-agreed cost. <br> 3. Receive round-the-clock support from Daily Daily.ru.",
    "title": "Secure Booking"
	}
}
</i18n>
<template>
  <div
    class="block-bonus block-bonus-theme-green"
  >
    <div class="block-bonus-img">
      <span class="icon-app-garantie"></span>
    </div>
    <div class="block-bonus-text">
      <div class="block-bonus-text-title">
        {{ t('title') }}
      </div>
      <div class="block-bonus-text-note" v-html="t('text')">
      </div>
    </div>
    <div class="block-bonus-btn">
      <a :href="`//${$DOMAIN}/help/gosti/guarantees?from=sa_object`" target="_blank">
        {{ t("btn") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "BannerBonus",
  props: {
    isGreen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapState("user", ["viewPort"]),
  },
};
</script>

<style lang="scss" scoped>
.block-bonus {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px 20px;
  margin-bottom: 20px;
  background: #eeeeee url("~/assets/img/bg-banner.jpg") 0 0 repeat;
  border-radius: 8px;

  &.mobile {
    flex-direction: column;
    .block-bonus-img {
      margin-right: 0;
      margin-bottom: 10px;
    }
    .block-bonus-text {
      margin-bottom: 18px;
      text-align: center;
    }
    .block-bonus-text-title,
    .block-bonus-text-note {
      padding-right: 0;
    }
  }

  &-theme-green {
    background: #7cc460 url("~/assets/img/affilate-pattern.png") 0 0 repeat;
    .block-bonus-img {
      color: #7cc460;
    }
    .block-bonus-text-title,
    .block-bonus-text-note,
    .block-bonus-btn a {
      color: #fff;
    }
    .block-bonus-btn a {
      background: transparent;
      border: 1px solid #fff;
    }
  }

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 60px;
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 50px;
    margin-right: 24px;
    font-size: 37px;
    color: #717171;
    line-height: 1.6;
    text-align: center;

    .icon-app-garantie {
      font-size: 40px;
    }
  }
  &-text {
    flex: auto;
    &-title {
      font-size: 22px;
      line-height: 26px;
      color: #6fa759;
      padding-right: 20px;
    }
    &-note {
      margin-top: 10px;
      font-size: 16px;
      color: #717171;
      padding-right: 20px;
    }
  }
  &-btn {
    a {
      display: inline-block;
      cursor: pointer;
      outline-style: none;
      padding: 8px 20px;
      color: #6fa759;
      text-decoration: none;
      background: #fff;
      border: none;
      font-size: 14px;
      border-radius: 3px;

      &:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }
    }
  }
}
</style>
