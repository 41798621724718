<i18n lang="json">
{
	"ru": {
    "review": "отзыв",
    "advantages": "Достоинства:",
    "reviewStr": "Другие отзывы об объекте",
    "view": "Показать ещё"
	},
	"en": {
    "review": "review",
    "advantages": "Advantages:",
    "reviewStr": "Другие отзывы об объекте",
    "view": "View more"
	}
}
</i18n>

<template>
  <div>
    <div class="rating-comments">
      <div class="rating-comments-count">{{ t("reviewStr") }}</div>
      <div class="rating-media">
        <div
          v-for="(comment, index) in otherReviews"
          :key="index"
          class="media-container"
        >
          <div class="media-img">
            <!-- <img :src="comment.avatar" /> -->
            <img src="~/assets/img/default-avatar_small.jpg" />
          </div>
          <div class="media-content">
            <div class="media-line">
              <p v-if="comment.name" class="media-title info-item-pad-sm">
                {{ comment.name }}
              </p>
            </div>
            <div class="media-raiting">
              <Rating :data-rating="comment.total_rating / 2" />
            </div>
            <div class="media-line media-date">
              <p class="text-small text-mute info-item-pad-sm">
                {{ dateComment(comment.write_at) }}
              </p>
            </div>
            <div v-if="comment.review" class="media-line info-item-pad-sm">
              <p class="info-item-pad-xs" v-html="comment.review"></p>
            </div>
          </div>
          <div v-if="comment.response" class="media-container">
            <div class="media-img"></div>
            <div class="media-content">
              <div class="media-container media-container-answer">
                <div class="media-img">
                  <img v-if="owner.avatar" :src="owner.avatar" />
                  <img v-else src="~/assets/img/default-avatar_small.jpg" />
                </div>
                <div class="media-content">
                  <div v-if="comment.response" class="media-line">
                    <p class="info-item-pad-xs">{{ comment.response }}</p>
                  </div>
                  <div class="media-line">
                    <p class="text-small text-mute info-item-pad-xs">
                      {{ dateComment(comment.response_at) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="page <= lastPage" class="media-container">
        <div class="media-img"></div>
        <div class="media-content">
          <button
            class="button button_w-100 button_size_md"
            :class="{ 'loading loading_primary': load }"
            @click="viewMore()"
          >
            {{ !load ? t("view") : null }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Rating from "@/components/base/BaseRating";
import { HTTP, Objects } from "@/components/api/";
import { formatSafari } from "@/utils/format";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailOtherComments",
  components: {
    Rating,
  },
  props: {
    otherReviews: {
      type: Array,
    },
    owner: {
      type: Object,
    },
    page: {
      type: Number,
    },
    lastPage: {
      type: Number,
    },
    load: {
      type: Boolean,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      ratingCount: null,
      commentsCount: 5,
      comments: this.init,
      // comments: null,
      commentsArray: null,
    };
  },
  methods: {
    dateComment(dateAdd) {
      var date = new Date(formatSafari(String(dateAdd)));

      var formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formatter = formatter.format(date);
      return formatter;
    },
    splitStr(str) {
      str = str.split(" ").join("");
      return str;
    },
    viewMore() {
      this.$emit("page", this.page + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.media-container {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  .media-container {
    padding-top: 5px;
  }

  &_feedback {
    .media-img-feedback {
      min-width: 42px;

      img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
      }
    }
  }
}

.media-raiting {
  margin-bottom: 5px;
}

.media-date {
  position: absolute;
  right: 0;
  top: 0;
}

.media-img {
  min-width: 80px;

  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
}

.media-content {
  position: relative;
  width: calc(100% - 80px);
}

.media-title {
  font-weight: bold;
  color: #444;
}

.media-container-answer {
  padding-top: 0;

  .media-img {
    min-width: 44px;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.rating-comments-count {
  font-size: 22px;
}
</style>
