<i18n>
  {
    "ru": {
      "bonusInfo": "Бонусы применены"
    },
    "en": {
      "bonusInfo": "Bonuses applied"
    }
  }
</i18n>

<template>
  <div class="bonus-sale">
    <span class="bonus-sale__summ">
      - {{ formatCalc(sale) }}
      <span class="bonus-sale__cliff"></span>
      <span class="bonus-sale__cliff"></span>
      <span class="bonus-sale__cliff"></span>
    </span>
    <span class="bonus-sale__info">
      {{ t("bonusInfo") }}
      <span class="bonus-sale__cliff"></span>
      <span class="bonus-sale__cliff"></span>
      <span class="bonus-sale__cliff"></span>
    </span>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { useCurrencyCalc } from "@/composables/price/useCurrencyCalc";

const { t, locale } = useI18n();

const props = defineProps({
  sale: {
    type: [Number, String],
    required: true,
  }
});

const currencyCalc = useCurrencyCalc();
function formatCalc(item) {
  return new Intl.NumberFormat(locale.value, {
    style: "currency",
    currency: currencyCalc.value.title || "RUB",
    minimumFractionDigits: 0,
  }).format(Math.floor(item));
}
</script>

<style lang="scss" scoped>
.bonus-sale {
  display: flex;
  align-items: center;
  margin-top: 22px;
  &__summ,
  &__info {
    display: block;
    width: 100%;
    height: 27px;
    padding: 6px 9px;
    color: #417505;
    font-size: 12px;
    position: relative;
  }
  &__summ {
    width: auto;
    white-space: nowrap;
    margin-right: 2px;
    font-size: 14px;
    &:before {
      content: "";
      background-color: rgba(255, 169, 0, 0.1);
      transform: rotate(-3deg) translateY(1px);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &__info {
    background-color: rgba(255, 169, 0, 0.1);
  }
  &__cliff {
    display: block;
    width: 6px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
    &:nth-child(2) {
      top: 10px;
      right: -3px;
    }
    &:nth-child(3) {
      top: 18px;
      right: -3px;
    }
  }
  &__info .bonus-sale__cliff {
    right: unset;
    left: -3px;
  }
}
</style>
