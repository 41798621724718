<i18n lang="json">
{
	"ru": {
    "clear": "Чистота",
    "price_quality": "Цена - качество",
    "right_photo": "Соответствие фото",
    "service": "Качество обслуживания",
    "infrastructure": "Расположение",
    "accuracy": "Своевременность заселения",
    "ratingGuests": "Оценка гостей",
    "reviewStr": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "emptyTitle": "Отзывы",
    "emptySubtitle": "В настоящее время пока никто не оставил отзыв на данный объект",
    "externalRating": "Оценка сформирована по реальным отзывам гостей на зарубежных сервисах, ушедших из России. Мы не публикуем отзывы целиком, т.к. гости не давали на это явного согласия. Возможно, отзывы появятся позже."
	},
	"en": {
    "clear": "Clear",
    "price_quality": "Quality - price",
    "right_photo": "Truth a photo",
    "service": "Quality of service",
    "infrastructure": "Good location",
    "accuracy": "Accuracy",
    "ratingGuests": "Оценка гостей",
    "reviewStr": "reviews | {n} review | {n} reviews | {n} reviews",
    "emptyTitle": "Rewiews",
    "emptySubtitle": "No reviews of this property yet",
    "externalRating": "The rating is based on real reviews of guests on foreign services who left Russia. We do not publish full reviews, because the guests did not explicitly agree to this. Perhaps reviews will appear later."
	}
}
</i18n>

<template>
  <div class="reviews__rating">
    <div class="loading" v-if="isLoading"></div>
    <template v-if="!isRating">
      <div v-if="countReviews >= 1" class="reviews">
        <div class="reviews--top mb-3">
          <div class="reviews--title">
            <div class="tmp-font--big_bold">
              {{ t("ratingGuests") }}
            </div>
            <div
              v-if="countReviews > 1"
              class="rating-top tmp-font--medium_bold"
            >
              <span class="icon-app-star2"></span>
              {{ $filters.newRating(avgRating) }}
            </div>
            <div class="count-reviews count-reviews--few tmp-font--medium_gray">
              {{ t("reviewStr", countReviews) }}
            </div>
          </div>
          <div><!-- по умолчанию --></div>
        </div>
      </div>
      <div v-else class="reviews">
        <template v-if="maxRatingExternal">
          <div class="reviews--top">
            <div class="reviews--title">
              <div class="tmp-font--big_bold">
                {{ t("ratingGuests") }}
              </div>
              <div class="rating-top tmp-font--medium_bold">
                <span class="icon-app-star2"></span>
                {{ $filters.newRatingExternal(maxRatingExternal) }}
              </div>
              <div
                v-if="countReviewsTotal"
                class="count-reviews tmp-font--medium_gray"
              >
                {{ t("reviewStr", countReviewsTotal) }}
              </div>
            </div>
          </div>
          <div class="reviews--empty tmp-font--medium_gray">
            {{ t("externalRating") }}
          </div>
        </template>
        <template v-else>
          <div class="reviews--top">
            <div class="reviews--title">
              <div class="tmp-font--big_bold">
                {{ t("emptyTitle") }}
              </div>
            </div>
          </div>
          <div class="reviews--empty tmp-font--medium_gray">
            {{ t("emptySubtitle") }}
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div v-if="countReviews > 1" class="reviews">
        <div class="reviews--top">
          <div class="reviews--title">
            <div class="tmp-font--big_bold">
              {{ t("ratingGuests") }}
            </div>
            <div class="rating-top tmp-font--medium_bold">
              <span class="icon-app-star2"></span>
              {{ $filters.newRating(avgRating) }}
            </div>
            <div class="count-reviews tmp-font--medium_gray">
              {{ t("reviewStr", countReviewsTotal) }}
            </div>
          </div>
          <div><!-- по умолчанию --></div>
        </div>
        <div class="reviews--range tmp-font--medium">
          <div
            v-for="(rating, name, i) in ratingArray"
            :key="i"
            class="range__item"
          >
            <div class="range__text">{{ t(rating.name) }}</div>
            <div class="range__line">
              <div class="line">
                <div
                  class="line_complete"
                  :style="{ width: `${rating.value * 20}%` }"
                ></div>
              </div>
              <div class="range__rating">
                {{ $filters.newRating(rating.value) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <div v-if="maxRatingExternal" class="reviews--top">
          <div class="reviews--title">
            <div class="tmp-font--big_bold">
              {{ t("ratingGuests") }}
            </div>
            <div class="rating-top tmp-font--medium_bold">
              <span class="icon-app-star2"></span>
              {{ $filters.newRatingExternal(maxRatingExternal) }}
            </div>
            <div
              v-if="countReviewsTotal"
              class="count-reviews tmp-font--medium_gray"
            >
              {{ t("reviewStr", countReviewsTotal) }}
            </div>
          </div>
        </div>
        <div
          v-if="maxRatingExternal"
          class="reviews--empty tmp-font--medium_gray"
        >
          {{ t("externalRating") }}
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
  id: {
    type: Number,
    default: {},
  },
  maxRatingExternal: {
    type: Number,
    default: 0,
  },
  countReviewsExternal: {
    type: Number,
    default: 0,
  },
  ratingsObj: {
    type: Object,
    default: null,
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
});

const ratings = computed(() => props.ratingsObj?.ratings)

const countReviews = computed(() => props.ratingsObj?.count ? props.ratingsObj.count : 0);

const countReviewsTotal = computed(() => countReviews.value + props.countReviewsExternal);

const avgRating = computed(() => ratings.value?.avg_rating ? ratings.value?.avg_rating : '');

const rateObj = {
  "clear": 1,
  "price_quality": 1,
  "infrastructure": 1,
  "right_photo": 1,
  "accuracy": 1,
  "service": 1,
};

const isRating = computed(() => {
  if (ratings.value) {
    return Boolean(
      Object.keys(ratings.value)
      .filter(k => rateObj[k] ? ratings.value[k] : false)
      .length
    );
  }
  return false;
});

const ratingArray = computed(() => {
  if (ratings.value) {
    const newArr = [];
    Object.entries(ratings.value).map(([key, value]) => {
      if (rateObj[key]) {
        newArr.push({
          name: key,
          value: value,
          translate: t(key),
        });
      }
    });
    return newArr;
  }
  return [];
});
</script>

<style lang="scss" scoped>
.reviews {
  color: #000;

  &--top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  &--empty {
    padding-bottom: 10px;
  }

  &--title {
    display: flex;
    align-items: center;

    .icon-app-star2 {
      color: #f51449;
      font-size: 20px;
      margin-right: 2px;
      vertical-align: unset;
    }

    .rating-top {
      margin: 4px 10px 0 0;
      font-size: 22px;
      .icon-app-star2 {
        position: relative;
        top: -1px;
      }
    }
    .count-reviews {
      margin-top: 9px;
      color: #717171;
    }
    .count-reviews--few {
      margin-top: 3px;
    }
    .tmp-font--big_bold {
      margin-right: 30px;
    }
  }

  &--range {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin-bottom: 74px;
    padding-top: 6px;

    .range {
      &__item {
        display: flex;
        align-items: flex-start;
        width: calc(50% - 10px);
        min-height: 32px;
        &:nth-child(odd) {
          margin-right: 10px;
        }

        &:nth-child(even) {
          margin-left: 10px;
        }
      }

      &__text {
        display: flex;
        align-items: center;
        width: 50%;
      }

      &__line {
        display: flex;
        align-items: center;
        width: 50%;

        .range__rating {
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
        }

        .line {
          background-color: #ededed;
          display: inline-block;
          height: 6px;
          width: 100%;
          border-radius: 3px;
          margin-right: 18px;
          overflow: hidden;

          &_complete {
            background-color: #f51449;
            height: 100%;
          }
        }
      }
    }
  }
}
.loading {
  &::before {
    opacity: 1;
  }
}
</style>
