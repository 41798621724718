<i18n lang="json">
{
  "ru": {
    "certTitle": "Свидетельство о присвоении категории",
    "rooms": "0| {n} номер | {n} номера | {n} номеров",
    "year": "{year} г постройки",
    "comfort": "Удобства и услуги",
    "paidServices": "Дополнительные платные услуги",
    "smoking": "Курение",
    "playground": "Игровая площадка",
    "spa": "Спа",
    "restaurant": "Ресторан",
    "room_service": "Доставка в номер",
    "all_time": "Круглосуточная стойка",
    "gum": "Фитнес/спортзал",
    "football": "Футбольное поле",
    "golf": "Гольф",
    "bar": "Бар",
    "tennis": "Теннисный корт",
    "sauna": "Сауна",
    "garden": "Сад",
    "terrace": "Терраса",
    "aquapark": "Аквапарк",
    "pool_out": "Бассейн (открытый)",
    "pool_in": "Бассейн (крытый)",
    "jacuzzi": "Джакузи общего пользования",
    "beach": "Собственный пляж",
    "lift": "Лифт",
    "payment": "Оплата",
    "transfer": "Трансфер",
    "transferTitle": "Возможно предоставление трансфера",
    "p_free": "бесплатная парковка",
    "i_free": "бесплатный интернет",
    "is_ramp": "пандус",
    "free": "бесплатно",
    "ip_common": "в местах общего пользования",
    "ip_few_rooms": "в некоторых номерах",
    "ip_all_rooms": "во всех номерах",
    "ip_all_place": "на всей территории",
    "s_all": "курение разрешено везде",
    "s_no": "курение запрещено",
    "s_only": "курение в специально отведенных местах",
    "pm_cash": "к оплате принимаются только наличные",
    "pm_card": "к оплате принимаются кредитные карты",
    "pm_cash_card": "к оплате принимаются наличные и карты",
    "pm_all": "принимается любой способ оплаты",
    "li_free": "бесплатное постельное белье",
    "li_in": "постельное бельё входит в стоимость проживания",
    "cl_free": "бесплатная уборка",
    "cl_in": "уборка входит в стоимость проживания",
    "more": "Еще об отеле",
    "breakfast": "Завтрак",
    "dinner": "Обед",
    "supper": "Ужин",
    "price_0": "входит в стоимость проживания",
    "price_1": "оплачивается отдельно",
    "nofood": "не предоставляется",
    "sweden": "шведский стол",
    "menu": "по меню",
    "complex": "комплексный",
    "food": "Питание",
    "all_inclusive": "всё включено",
    "btnShow": "показать ещё",
    "btnHide": "скрыть",
    "paidSeparately": "оплачивается отдельно",
    "parking": {
      "parking": {
        "1": "оплачивается отдельно",
        "2": "без оплаты",
        "3": "нет парковки",
        "default": "есть бесплатная парковка"
      },
      "is_p_covered": {
        "0": "открытая",
        "1": "крытая"
      },
      "is_p_private": {
        "0": "общественная",
        "1": "частная"
      },
      "is_p_fenced": {
        "0": "не огорожена",
        "1": "огорожена"
      },
      "is_p_video_surveilla": {
        "0": "нет видеонаблюдения",
        "1": "есть видеонаблюдение"
      },
      "is_p_security": {
        "0": "нет охраны",
        "1": "есть охрана"
      },
      "is_p_on_site": {
        "0": "за территорией объекта {distance}",
        "1": "на территории объекта"
      },
      "distance_to_p": {
        "5": "до 5 мин пешком",
        "10": "до 10 мин пешком",
        "15": "до 15 мин пешком",
        "20": "более 15 мин пешком"
      },
      "parking_cost_1": {
        "1": "стоимость: {cost} в сутки",
        "2": "стоимость: {cost} за период проживания",
        "3": "стоимость: {cost} в час",
        "default": "стоимость: {cost}"
      },
      "is_p_booking": {
        "0": "парковочное место нельзя забронировать заранее",
        "1": "парковочное место можно забронировать заранее"
      }
    }
  },
  "en": {
    "rooms": "0| {n} room | {n} rooms | {n} rooms",
    "year": "{year} year of build",
    "comfort": "Comfort and services",
    "paidServices": "Additional paid services",
    "smoking": "Smoking",
    "playground": "Playground",
    "spa": "Spa",
    "restaurant": "Restaurant",
    "room_service": "Room delivery",
    "all_time": "24 hour front desk",
    "gum": "Fitness/gym",
    "football": "Football field",
    "golf": "Golf",
    "bar": "Bar",
    "tennis": "Tennis",
    "sauna": "Sauna",
    "garden": "Garden",
    "terrace": "Terrace",
    "aquapark": "Aquapark",
    "pool_out": "Swimming pool (outdoor)",
    "pool_in": "Pool (indoor)",
    "jacuzzi": "Jacuzzi",
    "beach": "Private beach",
    "lift": "Lift",
    "payment": "Payment",
    "transfer": "Transfer",
    "transferTitle": "Transfers possible",
    "p_free": "free parking",
    "i_free": "free internet",
    "is_ramp": "ramp",
    "free": "free",
    "ip_common": "in common areas",
    "ip_few_rooms": "in some rooms",
    "ip_all_rooms": "in all rooms",
    "ip_all_place": "in all place",
    "s_all": "smoking is allowed everywhere",
    "s_no": "no smoking",
    "s_only": "smoking in designated areas",
    "pm_cash": "only cash accepted",
    "pm_card": "only credit card accepted",
    "pm_cash_card": "cash and cards accepted",
    "pm_all": "any payment method accepted",
    "li_paid": "платно, {price} за комплект",
    "li_in": "bed linen is included in the price",
    "cl_free": "free cleaning",
    "cl_in": "cleaning is included in the price",
    "more": "More about the hotel",
    "breakfast": "Breakfast",
    "dinner": "Lunch",
    "supper": "Dinner",
    "price_0": "included in the cost of living",
    "price_1": "paid separately",
    "nofood": "not provided",
    "sweden": "buffet",
    "menu": "by menu",
    "complex": "complex",
    "food": "Food",
    "all_inclusive": "all inclusive",
    "btnShow": "show more",
    "btnHide": "hide",
    "paidSeparately": "paid separately",
    "parking": {
      "parking": {
        "1": "paid separately",
        "2": "without payment",
        "3": "",
        "default": "there is free parking"
      },
      "is_p_covered": {
        "0": "open",
        "1": "covered"
      },
      "is_p_private": {
        "0": "public",
        "1": "private"
      },
      "is_p_fenced": {
        "0": "not fenced",
        "1": "fenced"
      },
      "is_p_video_surveilla": {
        "0": "no video surveillance",
        "1": "there is video surveillance"
      },
      "is_p_security": {
        "0": "no security",
        "1": "there is security"
      },
      "is_p_on_site": {
        "0": "outside the facility {distance}",
        "1": "on site"
      },
      "distance_to_p": {
        "5": "up to 5 minutes on foot",
        "10": "up to 10 minutes on foot",
        "15": "up to 15 minutes on foot",
        "20": "more than 15 minutes on foot"
      },
      "parking_cost_1": {
        "1": "cost: {cost} per day",
        "2": "cost: {cost} per stay",
        "3": "cost: {cost} per hour",
        "default": "cost: {cost}"
      },
      "is_p_booking": {
        "0": "parking space cannot be reserved in advance",
        "1": "parking space can be reserved in advance"
      }
    }
  }
}
</i18n>

<template>
  <div class="card-hotel">
    <div v-if="media" class="card-hotel--img">
      <div v-for="(item, index) in media" :key="index">
        <img :src="item" alt="" @click="galleryShow(index)" />
      </div>
    </div>
    <Gallery
      v-if="viewImage"
      :media="getHotelData.hotel.media"
      :video="{}"
      :items-to-show="3"
      :view-image="viewImage"
      :selected-slide="selectedSlide"
    />
    <div class="card-hotel--title tmp-font--big_bold">
      <HotelStars
        v-if="isCert && hotelStars"
        :stars-count="hotelStars"
        :is-cert="isCert"
      />
      {{ hotelType }} «{{ title }}»
    </div>
    <div v-if="options" class="card-hotel--data">
      <div
        v-if="options.rooms && options.rooms.value"
        class="val-hotel tmp-font--medium"
      >
        {{ t("rooms", options.rooms.value) }}
      </div>
      <div
        v-if="options.year && options.year.value"
        class="val-hotel tmp-font--medium"
      >
        {{ t("year", { year: options.year.value }) }}
      </div>
    </div>
    <HotelCert
      v-if="isCert"
      :cert-number="starCert?.certNum"
      :cert-date="starCert?.certDate"
    />
    <div
      v-if="description"
      class="card-hotel--desk tmp-font--medium"
      :class="{ show: showButton }"
    >
      <span>
        {{
          showButton
            ? description
            : description.slice(0, 400 * descShowCof)
        }}
        <span v-if="isDescriptionMoreThenLimit && !showButton">
          ...
        </span>
        <span
          v-if="isDescriptionMoreThenLimit"
          class="link-more"
          @click="showButton = !showButton"
        >
          {{ t(!showButton ? "btnShow" : "btnHide") }}
        </span>
      </span>
    </div>
    <div class="card-hotel--facilities">
      <div
        v-if="isAllInclusive"
        id="block-meal"
        class="list meals tmp-font--medium"
      >
        <div class="item">
          <strong>{{ t("food") }}</strong>
        </div>
        <div class="item">
          <strong>{{ t("all_inclusive") }}</strong>
        </div>
      </div>
      <template v-else>
        <div
          v-for="(item, index) in mealTypes"
          id="block-meal"
          :key="index"
          class="list meals tmp-font--medium"
        >
          <div class="item">
            <strong>{{ t(item.name) }}</strong
            >&nbsp;&mdash; {{ t(item.kind) }}
          </div>

          <div class="item">
            <template v-if="item.kind != 'nofood'">
              <strong v-if="item.price === 0">{{
                t(`price_${item.price}`)
              }}</strong>
              <span v-else-if="item.price === 1">{{
                t(`price_${item.price}`)
              }}</span>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="transfer"
      class="card-hotel--desk tmp-font--medium"
      :class="{ show: showTransferDesc }"
    >
      <div class="title tmp-font--average_big_bold">
        {{ t("transfer") }}
      </div>
      <p>{{ t("transferTitle") }}</p>
      <span v-if="transferDesc.length >= 120">
        {{
          showTransferDesc
            ? transferDesc
            : `${transferDesc.slice(0, 120 * descShowCof)}...`
        }}
        <span
          v-if="!showTransferDesc"
          class="link-more"
          @click="showTransferDesc = !showTransferDesc"
        >
          {{ t("btnShow") }}
        </span>
        <span
          v-else
          class="link-more"
          @click="showTransferDesc = !showTransferDesc"
        >
          {{ t("btnHide") }}</span
        >
      </span>
      <span v-else>{{ transferDesc }}</span>
    </div>
    <div
      v-if="paidServices && sumOfPaidServices"
      class="card-hotel--facilities"
    >
      <div class="title tmp-font--average_big_bold">
        {{ t("paidServices") }}
      </div>
      <div class="list tmp-font--medium">
        <div v-for="item in paidServices" :key="item.name" class="item">
          {{ item.title.toLowerCase() }} {{ formatNum(item.value) }}
        </div>
      </div>
    </div>
    <div class="card-hotel--facilities">
      <div class="title tmp-font--average_big_bold">
        {{ t("comfort") }}
      </div>
      <div class="list tmp-font--medium">
        <template v-if="comfortsList.length < 9">
          <div v-for="(prop, index) in comfortsList" :key="index" class="item">
            {{ prop.translate.toLowerCase() || prop.title.toLowerCase() }}
          </div>
        </template>
        <template v-else>
          <div v-for="(prop, index) in comfortsList.slice(0, 8)" :key="index" class="item">
            {{ prop.translate.toLowerCase() || prop.title.toLowerCase() }}
          </div>
          <template v-if="comfortView">
            <div v-for="(prop, index) in comfortsList.slice(8)" :key="index" class="item">
              {{ prop.translate.toLowerCase() || prop.title.toLowerCase() }}
            </div>
          </template>
        </template>
      </div>
    </div>
    <div v-if="isParking && parkingProperties" class="card-hotel--parking">
      <div class="title tmp-font--average_big_bold">
        <span class="b-info-item-title">{{ parkingProperties.title }}</span>
        <span
          class="b-info-item-label"
          v-if="parkingProperties.label"
          :data-label="parkingProperties.label"
        ></span>
      </div>
      <div class="list tmp-font--medium">
        <div v-for="prop in parkingProperties.properties" :key="prop.name" class="item">
          {{ prop.title }}
        </div>
      </div>
    </div>
    <div v-if="comfortsList.length > 8 && !comfortView" class="card-hotel--more-facilities">
      <button class="button button_size_md" @click="comfortView = !comfortView">
        {{ t("more") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useProcessData } from "@/composables/properties/processData";

import BaseStars from "@/components/base/BaseStars";
import Gallery from "@sutochno/gallery";
import HotelStars from "@/components/base/Hotel/HotelStars.vue";
import HotelCert from "@/components/base/Hotel/HotelCert.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailCardHotel",
  components: {
    HotelCert,
    HotelStars,
    BaseStars,
    Gallery,
  },
  setup() {
    const { t, te } = useI18n();
    const { processDataParking } = useProcessData();
    return {
      t, te,
      processDataParking,
    }
  },
  props: {
    hotelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showButton: false,
      showTransferDesc: false,
      comfortView: false,
      descShowCof: 1,
      selectedSlide: 0,
      viewImage: false,
      currencyСoefficient: null,
    };
  },
  watch: {
    viewImage(val) {
      this.$emit("update:modelValue", val);
    },
    userCurrencyId() {
      if (this.getObjectPrice?.currency?.id != 1) {
        this.setRates(1).then((response) => {
          this.currencyСoefficient =
            response[this.getObjectPrice?.currency?.id];
        });
      }
    },
  },
  computed: {
    ...mapGetters("detail", [
      "getHotelData",
      "getObjectPrice",
      "getObjectOriginalCurrency",
      "hotelStars",
      "starCert",
      "isCert"
    ]),
    media() {
      const media = this?.getHotelData?.hotel?.media?.map((img) =>
        img.image_url.replace("source", "medium")
      );
      if (media)
        return this.getHotelData.hotel.media
          .map((img) => img.image_url.replace("source", "medium"))
          .slice(0, 3);
      else return false;
    },
    title() {
      const title =
        this.getHotelData?.hotel?.properties?.title?.properties?.find(
          (p) => p.name === "title"
        )?.value;
      return title ? title : false;
    },
    options() {
      const arrOptions = ["stars", "rooms", "year"];
      const options =
        this.getHotelData?.hotel?.properties?.info?.properties.reduce(
          (acc, item) => {
            if (arrOptions.includes(item.name)) {
              acc[item.name] = item;
            }
            return acc;
          },
          {}
        );
      return options;
    },
    isDescriptionMoreThenLimit () {
      if(this.description) {
        return this.description.length >= 400
      }
    },
    description() {
      const description =
        this.getHotelData?.hotel?.properties?.description?.properties?.find(
          (p) => p.name === "desc"
        )?.value;
      return description ? description : false;
    },
    isParking() {
      if (!this.getHotelData?.hotel?.properties?.parking) return false;
      const parking =
        this.getHotelData.hotel.properties.parking.properties?.find(
          (prop) => prop.name === "parking"
        );
      return parking?.value && parking.value !== "p_no";
    },
    parkingProperties() {
      if (!this.getHotelData?.hotel?.properties?.parking) return false;
      const currency = this.getObjectPrice?.currency?.code || "RUB";
      const parking = JSON.parse(
        JSON.stringify(this.getHotelData.hotel.properties.parking)
      );
      // TODO - костыль, надеюсь временный
      if (parking.properties.length <= 2 && parking.properties.length > 0) {
        const parkingIndex = parking.properties.findIndex(
          (prop) => prop.name === "parking"
        );
        // TODO - костыль, надеюсь временный
        const parking_cost_1 = parking.properties.find(
          (prop) => prop.name === "parking_cost_1"
        );

        const isZeroParkingPay =
          parking_cost_1 &&
          parking_cost_1.value === 0 &&
          parking.properties[parkingIndex].value === "p_paid";

        const isFree =
          parkingIndex !== -1 &&
          (parking.properties[parkingIndex].value === "p_free" ||
            isZeroParkingPay);

        // TODO костыль, перекрывает некоторые быги в БД
        if (isZeroParkingPay) {
          parking.properties[parkingIndex].value = "p_free";
          parking.properties = parking.properties.filter(
            (prop) => prop.name === "parking"
          );
        }

        if (
          isFree ||
          (isFree && parking_cost_1 && parking_cost_1.value === 0)
        ) {
          const result = this.processDataParking(
            "parking",
            parking,
            currency,
            true
          );
          result.properties.push({
            name: "free_parking",
            title: this.t("parking.parking.default"),
            value: 2,
          });
          return result;
        }
      }
      return this.processDataParking("parking", parking, currency, true);
    },
    propArray() {
      if (!this.getHotelData?.hotel?.properties) return false;
      const pick = ["internet", "cleaning", "linen", "smoking", "payment"];

      return Object.keys(this.getHotelData.hotel.properties).reduce(
        (acc, key) => ({
          ...acc,
          ...this.getHotelData.hotel.properties[key].properties.reduce(
            (acc, object) => {
              if (pick.includes(object.name) && object.value) {
                const mod = /([^_]*)$/g.exec(object.value)[1];
                if (
                  (mod !== "no" && mod !== "paid") ||
                  object.name == "smoking"
                ) {
                  acc[object.name] = {
                    ...object,
                    translate: this.t(object.value),
                  };
                }
              }
              return acc;
            },
            {}
          ),
        }),
        {}
      );
    },
    paidServices() {
      const properties = this.getHotelData?.hotel?.properties;
      if (!properties?.payed) return false;
      let payedArr = properties.payed.properties.filter(
        (it) => typeof it.value === "number"
      );
      const services = [];
      const cleaning = {
        title:
          properties?.payed?.properties.find((el) => el.name === "cleaning")
            ?.title ?? "",
        value:
          payedArr.find((el) => el?.name?.includes("cleaning"))?.value ?? 0,
      };
      if (cleaning.value) {
        services.push(cleaning);
      }

      const linen = {
        title:
          properties?.payed?.properties.find((el) => el.name === "linen")
            ?.title ?? "",
        value: payedArr.find((el) => el?.name?.includes("linen"))?.value ?? 0,
      };
      if (linen.value) {
        services.push(linen);
      }

      if (properties?.internet?.properties) {
        const internet = this.checkingServicesForPayment(properties.internet.properties, 'internet');
        if (internet) {
          services.push(internet);
        }
      }
      return services;
    },
    sumOfPaidServices() {
      return this.paidServices.reduce((a, b) => a + b.value, 0);
    },
    comfort() {
      if (!this.getHotelData?.hotel?.properties?.services) return false;
      return this.getHotelData?.hotel?.properties?.services.properties
        .filter((el) => el.value === true && el.name !== "transfer")
        .map((el) => ({...el, translate: this.te(el.name) ? this.t(el.name) : el.title}))
    },
    comfortsList() {
      const list = [];
      if (this.comfort) {
        list.push(...this.comfort);
      }
      if (this.propArray) {
        list.push(...Object.values(this.propArray));
      }
      return list;
    },
    transfer() {
      const result =
        this.getHotelData.hotel.properties?.services?.properties?.filter(
          (v) => v.name === "transfer"
        );
      return result && result.length ? result[0].value : false;
    },
    transferDesc() {
      const result =
        this.getHotelData.hotel.properties?.description?.properties?.filter(
          (v) => v.name === "transfer_description"
        );
      return result && result.length ? result[0].value : "";
    },
    isMeal() {
      if (this.getHotelData?.hotel?.meal) {
        return true;
      } else {
        return false;
      }
    },
    isAllInclusive() {
      if (this.getHotelData?.hotel?.meal?.is_all_inclusive) {
        return true;
      } else {
        return false;
      }
    },
    mealTypes() {
      if (this.getHotelData?.hotel?.meal?.meal_types) {
        return this.getHotelData.hotel.meal.meal_types.filter(
          (item) => item.kind && item.kind != "nofood"
        );
      } else {
        return [];
      }
    },
    // comfortOne() {
    //   let propArray = Object.values(this.propArray);
    //   if (propArray.length) {
    //     return [
    //       propArray[0].translate || propArray[0].title,
    //       propArray[1].translate || propArray[1].title,
    //     ];
    //   } else {
    //     return "";
    //   }
    // },
    // isComfortOne() {
    //   if (
    //     this.comfortOne &&
    //     !Object.values(this.comfort).length &&
    //     Object.values(this.propArray).length &&
    //     !this.comfortView
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    userCurrencyId() {
      return this.getObjectPrice?.currency?.id;
    },
  },
  methods: {
    ...mapActions("detail", ["setRates"]),
    checkingServicesForPayment(properties, type) {
      const values = properties.find(v => v.name === type);
      if (values && values?.value?.includes('paid')) {
        const cost = properties.find(v => v.name === `${type}_cost_1`);
        return {
          title: values.title,
          value: cost.value > 0 ? cost.value : 'paid',
        }
      }
      return false;
    },
    galleryShow(index) {
      this.selectedSlide = index;
      this.viewImage = !this.viewImage;
    },
    formatNum(item) {
      if (!Number.isInteger(Number(item))) {
        return this.t('paidSeparately');
      }
      let price = null;
      if (
        this.getObjectPrice?.currency?.id &&
        this.getObjectPrice?.currency?.id != 1
      ) {
        price = item / this.currencyСoefficient;
      } else {
        price = item;
      }
      if (!price) {
        return this.t("free");
      }
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.getObjectPrice?.currency?.code || "RUB",
        minimumFractionDigits: 0,
      }).format(price);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-hotel {
  &--img {
    display: grid;
    grid-template-columns: 244px 1fr;
    grid-template-areas:
      "img-2 img-1"
      "img-3 img-1";
    margin-bottom: 30px;
    > div {
      &:nth-child(1) {
        grid-area: img-1;
        height: 252px;
      }
      &:nth-child(2) {
        grid-area: img-2;
        padding-right: 10px;
        height: 121px;
        margin-bottom: 10px;
      }
      &:nth-child(3) {
        grid-area: img-3;
        padding-right: 10px;
        height: 121px;
      }
    }
    img {
      object-fit: cover;
      border-radius: 9px;
      vertical-align: top;
      height: 100%;
      width: 100%;
      cursor: url("~/assets/img/zoom.png"), zoom-in;
    }
  }
  &--title {
    display: flex;
    margin-bottom: 16px;
  }
  &--data {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .stars {
      display: flex;
      align-items: center;
      margin-right: 18px;
      .icon-app-star {
        color: #000;
        margin-right: 8px;
        font-size: 20px;
      }
    }
    .val-hotel {
      margin-right: 18px;
    }
  }
  &--desk {
    margin-bottom: 20px;
    white-space: pre-line;
    .link-more {
      margin-left: 4px;
      cursor: pointer;
      margin-left: 4px;
      color: #2d6cb4;
    }
    p {
      margin-bottom: 5px;
    }
  }
  &--facilities {
    margin-bottom: 20px;
    .title {
      margin-bottom: 4px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        padding: 4px 0;

        &:nth-child(odd) {
          margin-right: 10px;
        }

        &:nth-child(even) {
          margin-left: 10px;
        }
      }
    }
  }
  &--parking {
    margin-bottom: 20px;
    .title {
      margin-bottom: 4px;
    }
    .list {
      display: inline-block;
      .item {
        display: inline-block;
        width: 100%;
        padding: 4px 0;
      }
    }
  }
  .b-info-item-label {
    display: inline-block;
    max-width: 160px;
    margin: 0;
    margin-left: 16px;
    padding: 0px 5px 3px;
    border-radius: 3px;
    background: #f6f6f6;
    &::before {
      content: attr(data-label);
      font-size: 12px;
      font-weight: 400;
    }
  }
  .meals {
    .item {
      margin: 0 !important;
      padding: 11px 10px 14px 15px;
      display: flex;
      flex-direction: row;
      background-color: #f6f6f6;
      border: 2px solid #fff;
    }
  }
}
</style>
