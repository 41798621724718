<i18n lang="json">
{
    "ru": {
        "reviews": "нет отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
        "showMap":"Показать на карте"
    },
    "en": {
        "reviews": "No reviews | {n} review | {n} reviews | {n} reviews",
        "showMap":"To show on the map"
    }
}
</i18n>
<template>
  <div class="detail-top">
    <div class="detail-top--name tmp-font--big_bold" v-if="titleObject">
      <HotelStars
        :stars-count="hotelStars"
        :is-cert="isCert"
        :cert-size="18"
        show-cert-icon
        v-if="isCert"
      />
      {{ titleObject }}
    </div>
    <div class="detail-top--body">
      <template v-if="ratingCount > 1">
        <span class="icon-app-star2" @click="scrollTo('panelReviews')"></span>
        <div class="rating tmp-font--medium_bold" @click="scrollTo('panelReviews')">
          <template v-if="isExternalRating">
            {{ $filters.newRatingExternal(ratingState) }}
          </template>
          <template v-else>
            {{ $filters.newRating(ratingState) }}
          </template>
        </div>
      </template>
      <div
        class="review tmp-font--medium_gray"
        v-if="ratingCount"
        @click="scrollTo('panelReviews')"
      >
        {{ t("reviews", ratingCount) }}
      </div>
      <div class="address tmp-font--medium_gray">
        {{ location.address }}
      </div>
      <div class="link-map">
        <span class="tmp-font--link" @click="scrollTo('panelMap')">{{
          t("showMap")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import HotelStars from "@/components/base/Hotel/HotelStars.vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  titleObject: {
    type: String,
    default: "",
  },
  avgRating: {
    type: Object,
    default: null,
  },
  location: {
    type: Object,
    default: null,
  },
  maxRatingExternal: {
    type: Number,
    default: 0,
  },
  countReviewsExternal: {
    type: Number,
    default: 0,
  },
});

const { count } = toRefs(props.avgRating);
const store = useStore();

const hotelStars = computed(() => store.getters['detail/hotelStars']);
const isCert = computed(() => !!store.getters['detail/isCert']);

const ratingState = computed(() => {
  if (!count) {
    return 0 + props.countReviewsExternal;
  }
  if (count.value <= 1 && props.maxRatingExternal > 0) {
    return props.maxRatingExternal;
  }
  return props.avgRating?.ratings?.avg_rating || 0;
});

const ratingCount = computed(() => {
  if (!count) {
    return 0 + props.countReviewsExternal;
  }
  return count.value + props.countReviewsExternal;
});

const isExternalRating = computed(() => {
  return count && count.value <= 1 && props.maxRatingExternal > 0;
});

const scrollTo = function scrollToRating(item) {
  store.commit('detail/setRefForScroll', item);
};
</script>

<style lang="scss" scoped>
.detail-top {
  margin-bottom: 20px;
  padding-top: 17px;
  &--name {
    margin-bottom: 10px;
    min-height: 26px;
    display: flex;
  }
  &--body {
    display: flex;
    align-items: center;
    .icon-app-star2 {
      color: #f51449;
      font-size: 12px;
      margin-right: 4px;
      cursor: pointer;
      margin-top: -1px;
    }
    .rating {
      margin-right: 10px;
      cursor: pointer;
    }
    .review {
      margin-right: 20px;
      cursor: pointer;
    }
    .address {
      margin-right: 20px;
    }
    .link-map {
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
