<i18n lang="json">
{
	"ru": {
		"orderMore": "Подробнее"
	},
	"en": {
		"orderMore": "More details"
	}
}
</i18n>
<template>
  <transition :name="isAnimateModal ? 'base-modal--fade' : ''">
    <!-- <div class="base-modal-wrap"> -->
    <div v-if="vShow || visible" v-show="visible"  class="base-modal">
      <div class="base-modal-wrap" :class="typeModal">
        <div class="base-modal-header" :class="{ light: theme === 'light' }">
          <template v-if="!hideCloseBtn">
            <template v-if="viewPort === 'mobile'">
              <span class="icon-app-cross" @click="close()" v-if="theme === 'light'"></span>
              <span class="icon-app-arrow-left" @click="close()" v-else></span>
            </template>
            <template v-else>
              <div class="base-modal-close" @click="close()"></div>
            </template>
          </template>
          <span class="base-modal-title" :class="typeModal">{{ title }}</span>
          <div v-if="!!$slots['side-action']" class="base-modal-side-action">
            <slot name="side-action"></slot>
          </div>
        </div>
        <div v-if="!!$slots['head']" class="base-modal-head">
          <slot name="head"></slot>
        </div>
        <div class="base-modal-body">
          <slot></slot>
        </div>
        <div
          v-if="!!$slots['footer']"
          class="base-modal-footer"
          :class="{ 'base-modal-footer-merge': footerMerge }"
        >
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </transition>
</template>
<script>
import { mapState } from "vuex";
import { documentBody } from "@sutochno/utils";

export default {
  name: "BaseModal",
  props: {
    title: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "dark"
    },
    footerMerge: {
      type: Boolean,
      default: false
    },
    hideCloseBtn: {
      type: Boolean,
      default: false
    },
    typeModal: {
      type: String,
      default: ""
    },
    vShow:{
      type: Boolean,
      default: false
    },
    isAnimateModal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "$attrs.value": function (value) {
      this.visible = Boolean(value);
      if (this.visible) {
        documentBody.overflow("hidden");
      } else {
        documentBody.overflow("");
      }
    },
  },
  computed: {
    ...mapState({
      viewPort: (state) => state.user.viewPort,
    }),
  },
  beforeUnmount() {
    // Возвращаем скрол в случае скрытия модалки путем выгрузки компонента
    documentBody.overflow("");
  },
  methods: {
    show() {
      this.visible = true;
      documentBody.overflow("hidden");
      this.$emit("show");
      this.$emit("update:modelValue", this.visible);
    },
    close() {
      this.visible = false;
      documentBody.overflow("");
      this.$emit("close");
      this.$emit("update:modelValue", this.visible);
    },
    toggle() {
      this.visible ? this.close() : this.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.base-modal--fade-enter-active,
.base-modal--fade-leave-active {
  transform: translateX(0%);
  transition: transform 0.35s ease;
}
.base-modal--fade-enter,
.base-modal--fade-leave-to {
  transform: translateX(100%);
}
.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  overflow: auto;

  -webkit-overflow-scrolling: touch;

  background-color: white;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-wrap {
    overflow: auto;
    max-height: 100%;
    // max-height: 100vh;
  }
  &-header {
    height: 50px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    background-color: #444444;
    color: white;
    position: sticky;
    top: 0;
    z-index: 201;
    &.light {
      background-color: #f6f6f6;
      color: #000;
      .icon-app-cross {
        color: #464646;
      }
    }
    .icon-app-arrow-left,
    .icon-app-cross {
      position: absolute;
      left: 0px;
      display: flex;
      align-items: center;
      height: 100%;
      padding-left: 20px;
    }
    .icon-app-cross {
      font-size: 10px;
    }
  }
  &-top {
    border-bottom: 1px solid #dee2e6;
    position: sticky;
    top: 50px;
    left: 0;
    right: 0;
    background: #fff;
    padding: 10px;
    z-index: 150;
  }
  &-footer {
    &-merge {
      box-shadow: none;
    }
    box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.4);
    // border-top: 1px solid #dee2e6;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 10px;
    z-index: 1;
  }
  &-body {
    overflow: auto;
    overflow-x: hidden;
    color: #444444;
    min-height: 350px;
    .mobile & {
      height: 100% !important;
      height: 100vh !important; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100) !important;
      max-height: calc(100vh - 50px);
    }
  }
  &-close {
    position: absolute;
    font-size: 64px;
    line-height: 40px;
    width: 40px;
    height: 50px;
    transform: scale(0.7);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      background-color: white;
      width: 4px;
      height: 20px;
      display: inline-block;
    }
    &::before {
      transform: rotate(45deg) translate(-6px, -10px);
      transform-origin: 100% 50%;
    }
    &::after {
      transform: rotate(-45deg);
      transform-origin: 0% 50%;
    }
  }
}
.desktop {
  .base-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.25);
    &-wrap {
      background-color: white;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.2);
      min-width: 630px;
      max-width: 750px;
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      max-height: calc(100vh - 80px);
      &.booking-can-hold {
        max-width: 530px;
        min-width: 470px;
      }
    }
    &-body {
      min-height: 50px;
      max-height: calc(100vh - 450px);
    }
    &-header {
      color: #444444;
      background: #fff;
      text-align: left;
      text-indent: 10px;
    }
    &-title {
      font-size: 18px;
      color: #000;
    }

    &-close {
      right: 0;
      &::before,
      &::after {
        content: "";
        background-color: black;
        width: 1px;
        height: 30px;
        display: inline-block;
      }
      &::before {
        transform: rotate(45deg) translate(-2px, -2px);
        transform-origin: 100% 50%;
      }
      &::after {
        transform: rotate(-45deg) translate(0px, -3px);
        transform-origin: 0% 50%;
      }
    }
  }
  .base-modal--fade-enter-active,
  .base-modal--fade-leave-active {
    opacity: 1;
    transition: opacity 0.25s ease;
  }
  .base-modal--fade-enter,
  .base-modal--fade-leave-to {
    opacity: 0;
  }
}
.mobile {
  .base-modal-title.booking-can-hold {
    font-size: 15px;
    padding-left: 30px;
  }
}
// .desktop {
//   .base-modal {
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     overflow-x: hidden;
//     border: 1px solid rgba(0, 0, 0, 0.2);
//     box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5), 0 0 0 1000px rgba(0, 0, 0, 0.25);
//     min-width: 630px;
//     &-wrap {
//       position: fixed;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       background: rgba(0, 0, 0, 0.25);
//     }
//     &-header {
//       color: #444444;
//       background: #fff;
//       text-align: left;
//       text-indent: 10px;
//     }

//     &-close {
//       right: 0;
//       &::before,
//       &::after {
//         content: "";
//         background-color: black;
//         width: 1px;
//         height: 30px;
//         display: inline-block;
//       }
//       &::before {
//         transform: rotate(45deg) translate(-2px, -2px);
//         transform-origin: 100% 50%;
//       }
//       &::after {
//         transform: rotate(-45deg) translate(0px, -3px);
//         transform-origin: 0% 50%;
//       }
//     }
//   }
//   .base-modal--fade-enter-active,
//   .base-modal--fade-leave-active {
//     opacity: 1;
//     transition: opacity 0.25s ease;
//   }
//   .base-modal--fade-enter,
//   .base-modal--fade-leave-to {
//     opacity: 0;
//   }
// }
</style>
