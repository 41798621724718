<i18n lang="json">
{
	"ru": {
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "for": "Итого за",
    "prepayment": "Предоплата {prepay} %",
    "bonusInfo": "Бонусы применены",
    "saving": "экономия {saving}"
	},
	"en": {
    "nights": "less night | {n} night | {n} nights | {n} nights",
    "for": "for",
    "prepayment": "{prepay} % prepayment",
    "bonusInfo": "Bonuses applied",
    "saving": "saving {saving}"
	}
}
</i18n>
<template>
  <div class="view-price">
    <div class="view-price__calc">
      <div class="view-price__text tmp-font--medium">
        {{ t("for") }} {{ t("nights", Number(count || 1)) }}
      </div>
      <div class="view-price__value tmp-font--big_bold" v-if="!bonuses">
        {{ formatNum(totalPrice) }}
      </div>
      <div class="view-price__price-box" v-else>
        <span class="view-price__old-price">{{ formatNum(price) }}</span>
        <span class="view-price__new-price">{{ formatNum(totalPrice) }}</span>
      </div>
    </div>
    <div v-if="bonuses" class="booking__bonus-sale">
        <span class="booking__bonus-sale-summ">
          - {{ formatNum(bonusCount) }}
          <span class="booking__bonus-sale-cliff"></span>
          <span class="booking__bonus-sale-cliff"></span>
          <span class="booking__bonus-sale-cliff"></span>
        </span>
      <span class="booking__bonus-sale-info">
          {{ t("bonusInfo") }}
          <span class="booking__bonus-sale-cliff"></span>
          <span class="booking__bonus-sale-cliff"></span>
          <span class="booking__bonus-sale-cliff"></span>
        </span>
    </div>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "OfferPrice",
  props: {
    price: {
      type: Number
    },
    count: {
      type: Number,
    },
    bonuses: {
      type: Number,
      default: 0
    },
    defaultPrice: {
      type: Number,
      default: 0,
    },
    currency: {
      type: Object,
      default: {},
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    bonusCount() {
      if(!this.bonuses) {
        return 0;
      }
      const tenPercent = this.price * 0.1;
      if(this.bonuses > tenPercent) {
        return Math.round(this.price * 0.1);
      }
      return this.bonuses;
    },
    totalPrice () {
      return Math.round(this.price - this.bonusCount)
    }
  },
  methods: {
    formatNum(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: (this.currency && this.currency.code) || "RUB",
        minimumFractionDigits: 0,
      }).format(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.view-price {
  &__price-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__old-price {
    display: block;
    color: #717171;
    font-weight: 700;
    font-size: 16px;
    margin-right: 10px;
  }
  &__new-price{
    color: #e10600;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }
  &__calc {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__value {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.view-price__old-price:before {
  content: "";
  border-top: 1px solid red;
  display: block;
  transform: rotate(-13deg) translate(-5px,10px);
  width: calc(100% + 5px);
}

.booking__bonus-sale {
  display: flex;
  align-items: center;
  margin-top: 22px;
  &-summ,
  &-info {
    display: block;
    width: 100%;
    height: 27px;
    padding: 6px 9px;
    color: #417505;
    font-size: 12px;
    position: relative;
  }
  &-summ {
    width: auto;
    white-space: nowrap;
    margin-right: 2px;
    font-size: 14px;
    &:before {
      content: "";
      background-color: rgba(255, 169, 0, 0.1);
      transform: rotate(-3deg) translateY(1px);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-info {
    background-color: rgba(255, 169, 0, 0.1);
  }
  &-cliff {
    display: block;
    width: 6px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
    &:nth-child(2) {
      top: 10px;
      right: -3px;
    }
    &:nth-child(3) {
      top: 18px;
      right: -3px;
    }
  }
  &-info &-cliff {
    right: unset;
    left: -3px;
  }
}
</style>
