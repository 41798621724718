<i18n lang="json">
{
    "ru": {
        "superhostLabel": "Суперхозяин",
        "superhostText": "Один из лучших хозяев жилья. \n Имеет много бронирований и хороших отзывов."
    },
    "en": {
        "superhostLabel": "Superhost",
        "superhostText": "One of the best homeowners. \n Has a lot of bookings and good reviews."
    }
}
</i18n>
<template>
  <div class="superhost-block">
    <div class="superhost-block--avatar">
      <img :src="srcAvatar" alt="" />
      <span>{{ t("superhostLabel") }}</span>
    </div>
    <div class="superhost-block--text tmp-font--medium">
      {{ t("superhostText") }}
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailSuperhost",
  props: {
    srcAvatar: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
};
</script>

<style lang="scss" scoped>
.superhost-block {
  margin: 20px auto;
  border-radius: 9px;
  border: solid 1px #d8d8d8;
  display: flex;
  align-items: center;
  padding: 15px 40px;
  font-family: "Inter", sans-serif;
  width: 100%;
  max-width: 830px;

  &--avatar {
    width: 78px;
    text-align: center;
    margin-right: 40px;
    position: relative;

    img {
      width: 60px;
      border-radius: 60px;
    }

    span {
      color: #fff;
      font-size: 9px;
      background: #ffa900;
      padding: 3px 10px 4px;
      border-radius: 9px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  &--text {
    white-space: pre-line;
  }
}
</style>
