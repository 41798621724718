<i18n lang="json">
{
	"ru": {
    "checkIn": "Заезд: {date} в {time}",
    "checkOut": "Отъезд: {date} в {time}",
    "guests": "0| {n} гость|{n} гостя|{n} гостей"
	},
	"en": {
    "checkIn": "Check-in: {date} at {time}",
    "checkOut": "Check-out: {date} at {time}",
    "guests": "0| {n} guest|{n} guests|{n} guests"
	}
}
</i18n>
<template>
  <div class="offer-date">
    <p class="offer-date__item">{{ t('checkIn', { date: checkInView.date, time: checkInView.time }) }}</p>
    <p class="offer-date__item">{{ t('checkOut', { date: checkOutView.date, time: checkOutView.time }) }}</p>
    <p class="offer-date__item">{{ t('guests', guests) }}</p>
  </div>
</template>
<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "OfferDate",
  props: {
    checkIn: {
      type: String
    },
    checkOut: {
      type: String
    },
    guests: {
      type: Number
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    };
  },
  computed: {
    checkInView() {
      const date = new Date(this.checkIn)
      return {
        date: this.dateFormatForm(date),
        time: this.timeFormatForm(date)
      }
    },
    checkOutView() {
      const date = new Date(this.checkOut)
      return {
        date: this.dateFormatForm(date),
        time: this.timeFormatForm(date)
      }
    }
  },
  methods: {
    dateFormatForm(date) {
      return date
        .toLocaleString(this.$i18n.locale, {
          day: "numeric",
          month: "short",
          year: "numeric"
        })
        .replace(".", "")
        .replace(" г.", "");
    },
    timeFormatForm(date) {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        hour: "numeric",
        minute: "numeric"
      }).format(date)
    },
  },
};
</script>
<style lang="scss" scoped>
.offer-date {

}
</style>
