<i18n lang="json">
{
	"ru": {
    "btnPrice": "Выберите даты",
    "btnPriceSub": "Выберите даты и количество гостей для расчёта стоимости проживания",
    "questionDefault": "Доступность жилья и стоимость зависят от выбранных дат"
	},
	"en": {
    "btnPrice": "Select dates",
    "btnPriceSub": "Enter date and guests for count of the staying cost",
    "questionDefault": "Property access and the cost depends on chosen date"
	}
}
</i18n>
<template>
  <div class="default">
    <DetailBookingFix
      v-if="isFixed && viewPort == 'mobile'"
      :btn-title="t('btnPrice')"
    />
    <div class="default__button">
      <button
        ref="btnBooking"
        v-observe-visibility="{
          callback: (isVisible, entry) => visibilityChanged(isVisible, entry),
          throttle: 20,
          intersection: {
            threshold: 0.001,
          },
        }"
        class="button button_green button_w-100 button_size_md"
        :class="{ 'loading loading_simple button_disabled': !calcInfo }"
        @click.stop="showDatePicker()"
      >
        {{ calcInfo ? t("btnPrice") : null }}
      </button>
    </div>

    <div class="default__text">
      {{ t("btnPriceSub") }}
    </div>
  </div>
</template>
<script>
import DetailBookingFix from "@/components/mobile/Detail/DetailBookingFix";
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "Aside",
  components: {
    DetailBookingFix,
  },
  props: {
    calcInfo: {
      default: null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isFixed: true,
    };
  },
  computed: {
    ...mapState("user", ["viewPort"]),
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      this.isFixed = !isVisible && entry.target.getBoundingClientRect().top > 0;
    },
    showDatePicker() {
      // Открываем календарь с заездом
      this.$parent.$refs.calendar.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.default {
  color: #000;

  &__text {
    padding-top: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #717171;
  }
  .icon-app-question {
    position: relative;
    top: 2px;
    font-size: 16px;
  }
}
</style>
