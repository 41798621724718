<i18n lang="json">
{
    "ru": {
        "maxGuests": "ни одного | 1 гость | {n} гостя | {n} гостей",
        "beds": "нет кроватей | 1 кровать | {n} кровати | {n} кроватей",
        "meter": "м²",
        "more": "Подробнее",
        "booking": "Забронировать",
        "default": "Цена и доступность",
        "moreOfRoom": "Подробнее о номере",
        "errorGuest": "Объект не может вместить указанное количество гостей.",
        "errorDate": "Забронировано на ваши даты! Жилье пользуется спросом.",
        "errorChooseDates":"Выберите даты заезда и выезда",
        "single_bed": "нет односпальных кроватей | 1 односпальная кровать | {n} односпальных кровати | {n} односпальных кроватей",
        "double_bed": "нет двуспальных кроватей | 1 двуспальная кровать | {n} двуспальных кровати | {n} двуспальных кроватей",
        "king_bed": "нет кроватей king-size | 1 кровать king-size | {n} кроватей king-size | {n} кроватей king-size",
        "super_king_bed": "нет особо широких двуспальных кроватей | 1 особо широкая двуспальная кровать | {n} особо широких двуспальных кровати | {n} особо широких двуспальных кроватей",
        "yarus_bed": "нет двухъярусных кроватей | 1 двухъярусная кровать | {n} двухъярусных кровати | {n} двухъярусных кроватей",
        "bsofa_bed": "нет диван-кроватей | 1 диван-кровать | {n} диван-кровати | {n} диван-кроватей"
    },
    "en": {
        "maxGuests": "no guests | 1 guest | {n} guests | {n} guests",
        "beds": "no beds | 1 bed | {n} beds | {n} beds",
        "meter": "m²",
        "more": "More",
        "booking": "Booking",
        "default": "Price and availability",
        "moreOfRoom": "More of room",
        "errorGuest": "The property cannot accommodate the specified number of guests.",
        "errorDate": "Booked for your dates! Housing is in demand.",
        "errorChooseDates":"Select check-in and check-out dates",
        "single_bed": "no single bed | 1 single bed | {n} single beds | {n} single beds",
        "double_bed": "no double bed | 1 double bed | {n} double beds | {n} double beds",
        "king_bed": "no king-size beds | 1 king-size bed | {n} king-size beds | {n} king-size beds",
        "super_king_bed": "no extra wide double beds | 1 extra wide double bed | {n} extra wide double beds | {n} extra wide double beds",
        "yarus_bed": "no bunk beds | 1 bunk bed | {n} bunk beds | {n} bunk beds",
        "bsofa_bed": "no sofa beds | 1 sofa bed | {n} sofa beds | {n} sofa beds"
    }
}
</i18n>
<template>
  <div class="room" :class="{ placeholder: hotelObjectsLoad, 'room--disabled': roomDisabled }" v-if="room">
    <template v-if="!hotelObjectsLoad">
      <div class="img" v-if="img">
        <img :src="img" :alt="alt" />
      </div>
      <div class="desk">
        <div class="desk--title tmp-font--medium">
          {{ room.type_title }}
        </div>
        <div class="desk--val tmp-font--medium_gray" v-if="options">
          <div v-if="options.max_guests">
            {{ t("maxGuests", options.max_guests.value) }}
          </div>
          <div v-if="numberOfBeds">
            {{ t("beds", numberOfBeds) }}
          </div>
          <div v-if="options.area">
            {{ `${options.area.value} ${t("meter")}` }}
          </div>
        </div>
        <div class="desk--hint tmp-font--medium" v-if="beds.length > 0">
          <span v-for="(bed, i) in beds" :key="i">
            <span>{{ t(bed.name, bed.value) }}</span>
            <span v-if="beds.length > i + 2">,&nbsp;</span>
            <span v-if="beds.length - 2 === i"> +&nbsp;</span>
          </span>
        </div>
      </div>
      <div class="btn-wr" :class="{ 'no-days': !dates }">
        <button
          v-if="!dates"
          class="button button_size_md"
          @click="choiceDates()"
        >
          {{ t("default") }}
        </button>
        <template v-else-if="price && price.data">
          <div
            class="warning-block"
            v-if="price && price.data && price.data.busy == 'busy'"
          >
            <span class="icon-app-clock"></span>
            <span>
              {{ t("errorDate") }}
            </span>
          </div>
          <div
            class="warning-block"
            v-else-if="
              price.data.cnt_guests >
              price.data.max_guests * price.data.rooms_cnt
            "
          >
            <span class="icon-app-clock"></span>
            <span>
              {{ t("errorGuest") }}
            </span>
          </div>
          <template v-else>
            <div
              class="warning-block"
              v-if="price && price.errors && price.errors.length"
            >
              <span class="icon-app-clock"></span>
              <span>
                {{ price.errors[0] }}
              </span>
            </div>
            <template v-else>
              <div class="price" v-if="price && price.data">
                <BaseViewPrice
                  :price="price.data"
                  count="top"
                  :currency="currency"
                  :default-price="1"
                  :prepay="t('questionPrepayment')"
                  :hot="price.data.object_hot"
                  :old-price="oldPrice"
                  :sale-price="salePrice"
                />
              </div>
              <div class="room-more">
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Detail',
                    params: {
                      id: room.id,
                    },
                  }"
                  class="button button_w-100 button_size_md room__button-link"
                  >{{ t("more") }}</router-link
                >
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Detail',
                    params: {
                      id: room.id,
                    },
                  }"
                  class="room--link"
                ></router-link>
                <router-link
                  v-if="guestsSearch?.pets?.value"
                  target="_blank"
                  :to="{
                    name: 'Detail',
                    params: {
                      id: room.id,
                    },
                    query: {
                      open_guest_modal: true,
                    }
                  }"
                  class="button button_w-100 button_green button_size_md"
                >{{ t("booking") }}</router-link>
                <button
                  v-else
                  class="button button_w-100 button_green button_size_md"
                  @click="bookingStart()"
                >
                  {{ t("booking") }}
                </button>
              </div>
            </template>
          </template>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="img"><div class="img-placeholder"></div></div>
      <div class="desk">
        <div class="desk--title">
          <div class="info__item w75"></div>
        </div>
        <div class="desk--val">
          <div class="info__item w25"></div>
          <div class="info__item w25"></div>
        </div>
        <div class="desk--hint">
          <div class="info__item w75"></div>
          <div class="info__item w50"></div>
        </div>
      </div>
      <div class="btn">
        <div class="price">
          <div class="price--val">
            <div class="info__item w25"></div>
          </div>
          <div class="tmp-font--medium">
            <div class="info__item w50"></div>
          </div>
        </div>
        <div class="room-more">
          <div class="info__item big w50"></div>
          <div class="info__item big w50"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { format } from "@/utils/format.js";
import BaseViewPrice from "@/components/base/BaseViewPrice";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailRoom",
  mixins: [dataLayerPushGA4],
  components: {
    BaseViewPrice,
  },
  props: {
    room: {
      type: Object,
      default: {},
    },
    price: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("detail", ["hotelObjectsLoad"]),
    ...mapGetters("detail", ["getBookingData", "isBronevik"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("search", { guestsSearch: "guests" }),
    ...mapState("user", ["isAuth", "userData", "currency"]),
    dates() {
      if (!this.checkIn || !this.checkOut) return false;
      else return true;
    },
    img() {
      if (!this.room?.media?.length && !this.room.media[0]?.image_url) return false;
      else return this.room.media[0].image_url.replace("source", "small");
    },
    alt() {
      if (!this.room?.media?.length && !this.room.media[0]?.comment) return false;
      else return this.room.media[0].comment;
    },
    options() {
      const optionsArr = ["max_guests", "area"];
      return this?.room?.properties?.basic_properties?.properties?.reduce(
        (acc, item) => {
          if (optionsArr.includes(item.name)) {
            acc[item.name] = item;
          }
          return acc;
        },
        {}
      );
    },
    numberOfBeds() {
      return this.beds.reduce((acc, item) => acc + item.value, 0);
    },
    beds() {
      const NAMES_BEDS = [
        "single_bed",
        "double_bed",
        "king_bed",
        "super_king_bed",
        "yarus_bed",
        "bsofa_bed",
      ];

      const basicProperties =
        this?.room?.properties?.basic_properties?.properties || [];

      const property = basicProperties.reduce(
        (acc, item) => ((acc[item.name] = item.value), acc),
        {}
      );

      return Object.entries(property || {}).reduce((acc, [key, value]) => {
        if (NAMES_BEDS.includes(key) && value > 0) {
          acc.push({
            name: key,
            value: value,
          });
        }
        return acc;
      }, []);
    },
    currency() {
      return {
        id: this.price?.data?.currency?.id ? this.price.data.currency.id : 1,
        title: this.price?.data?.currency?.code
          ? this.price.data.currency.code
          : "RUB",
      };
    },
    getPhone() {
      if (
        this.isAuth &&
        this.userData &&
        this.userData.user &&
        this.userData.user.contacts
      ) {
        return this.userData.user.contacts.reduce((acc, item, index) => {
          if (item.type_id == 2 && item.visible == 1) {
            acc = item.value;
          }
          return acc;
        }, []);
      } else {
        return "";
      }
    },
    guestsSend() {
      let countChild = 0;
      return _.range(
        0,
        this.guestsSearch.adults + this.guestsSearch.childrens.length
      ).map((g, i) => {
        if (i <= this.guestsSearch.adults - 1) {
          return (g = { type: 0 });
        } else {
          g = { type: 3, age: this.guestsSearch.childrens[countChild] };
          countChild += 1;
          return g;
        }
      });
    },
    dateCheck() {
      if (this.room.properties.check_in_out_rules) {
        return this.room.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.room.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
    currencyCalc() {
      if (this.price.data?.currency) {
        return {
          id: this.price.data.currency.id,
          title: this.price.data.currency.code,
        };
      } else {
        return this.currency;
      }
    },
    sale() {
      if (Number(this.currencyCalc.id) === 1 && Number(this.currency.id === 1) && this.isAuth) {
        const sale = Math.floor(this.oldPrice * .10);
        if (this.userData?.balance_virtual) {
          return sale <= this.userData?.balance_virtual ? sale : this.userData.balance_virtual;
        }
      }
      return 0;
    },
    salePrice() {
      return this.oldPrice - this.sale;
    },
    oldPrice() {
      return this.price.data?.price ? this.price.data.price : this.price.data.price_default;
    },
    roomDisabled() {
      return  this.price?.errors?.length || this.price?.data?.busy == 'busy'
    },
  },
  methods: {
    ...mapActions("detail", ["setBookingData"]),
    async bookingStart() {

      if (this.isBronevik) {
        // Пока костыль
        this.modalBronevik = true;
        sendEventGtag("test_bronevik", {
          click: "booking",
        });
      } else {
        await this.setBookingData({
          phone: this.getPhone,
          guests: this.guestsSend,
          rooms_cnt: 1,
          hotel_booking: true,
          time_begin: this.dateCheck.in.match(/\d{2}/),
          time_end: this.dateCheck.out.match(/\d{2}/),
          date_begin: format(this.checkIn),
          date_end: format(this.checkOut),
          object_id: this.room.id,
          price: this.price?.data?.price ? this.price.data.price : null,
          no_time: 1,
          tm: "-240",
        });

        // this.dataLayerPush("add_to_cart");
        // https://jira.webpower.ru/browse/VUECLIENT-1811

        this.$router
          .push({
            name: this.isAuth ? "LightBooking" : "Booking",
            params: { orderCode: this.getBookingData.order_code },
          })
          .catch(() => {
            this.$router.push({
              name: this.isAuth ? "LightBooking" : "Booking",
              params: { orderCode: this.getBookingData.order_code },
            });
          });
      }
    },
    datesDefault() {
      setTimeout(() => {
        document
          .querySelector(".availability__datepicker")
          .dispatchEvent(new Event("click"));
      }, 100);
    },
    choiceDates() {
      setTimeout(() => {
        this.$parent.$refs.availability.datePickerToggle();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.room {
  background: #fff;
  border-radius: 9px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 168px 1fr 300px;
  padding: 15px 20px;
  position: relative;
  min-height: 132px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 168px 1fr 142px;
  }
  &--link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &--disabled {
    .img {
      opacity: .5;
    }
  }
  .img {
    padding-right: 25px;
    img {
      width: 100%;
      height: 102px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .desk {
    &--title {
      margin-bottom: 12px;
      font-weight: 500;
    }
    &--val {
      display: flex;
      margin-bottom: 14px;
      > div {
        margin-right: 10px;
      }
    }
  }
  .btn-wr {
    align-self: center;
    &.no-days {
      align-self: end;
      justify-self: end;
    }
    .price {
      text-align: right;
      margin-bottom: 12px;
      :deep() {
        .view-price__calc {
          flex-direction: column-reverse;
          align-items: flex-end;
        }
        .view-price__value {
          margin-bottom: 10px;
        }
      }
    }
    .warning-block {
      background-color: #fdd2d2;
      color: #f51449;
      font-size: 12px;
      display: flex;
      padding: 10px;
      border-radius: 6px;
      .icon-app-clock {
        color: #f51449;
        font-size: 12px;
        margin-right: 10px;
        margin-top: 2px;
      }
    }
    .room-more {
      display: flex;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-end;
      }
      .button.button_w-100 {
        width: 142px;
        padding: 0 16px;
        text-align: center;
        position: relative;
        z-index: 1;
      }
      .button {
        margin-left: 12px;
        position: relative;
        z-index: 1;
        @media only screen and (max-width: 1024px) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
  &.placeholder {
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -10%;
      top: -10%;
      bottom: -10%;
      width: 30px;
      opacity: 0;
      background: #fff;
      animation: loader 1.3s linear infinite;
      filter: blur(20px);
      transform: rotate(25deg);
    }
    .img {
      .img-placeholder {
        width: 100%;
        height: 102px;
        border-radius: 5px;
        background-color: #ededef;
        background-image: url("~/assets/img/no_photo.svg");
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .desk {
      &--hint {
        .info__item {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
    .btn {
      padding: 0;
      .info__item {
        display: inline-block;
      }
      .price {
        text-align: right;
        margin-bottom: 12px;
        &--val {
          margin-bottom: 8px;
        }
      }
      .room-more {
        display: flex;
        gap: 10px;
      }
    }
    .info__item {
      height: 15px;
      background: #d8d8d8;
      border-radius: 15px;
      width: 100%;
      &.w75 {
        width: 75%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.big {
        height: 42px;
      }
    }
  }
}
.loading {
  z-index: 1;
}
@keyframes loader {
  from {
    left: -10%;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
</style>
