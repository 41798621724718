<i18n lang="json">
{
	"ru": {
    "clear": "Чистота",
    "quality": "Цена - качество",
    "truthPhoto": "Соответствие фото",
    "service": "Качество обслуживания",
    "goodLocation": "Расположение",
    "accuracy": "Своевременность заселения",
    "ratingTitle": "Оценка гостей",
    "emptyTitle": "Отзывы",
    "onBasis": "на основании",
    "reviewStr": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "emptySubtitle": "В настоящее время пока никто не оставил отзыв на данный объект"
	},
	"en": {
    "clear": "Clear",
    "quality": "Quality - price",
    "truthPhoto": "Truth a photo",
    "service": "Quality of service",
    "goodLocation": "Good location",
    "accuracy": "Accuracy",
    "ratingTitle": "Reviews guests",
    "emptyTitle": "Rewiews",
    "onBasis": "on basis",
    "reviewStr": "reviews | {n} review | {n} reviews | {n} reviews",
    "emptySubtitle": "No reviews of this property yet"
	}
}
</i18n>
<template>
  <div v-if="reviews">
    <div v-if="commentsCount == 0 || !commentsCount">
      <div class="panel-head">
        <h1 class="panel-title">{{ t("emptyTitle") }}</h1>
        <p class="panel-planning text-mute">{{ t("emptySubtitle") }}</p>
      </div>
    </div>
    <div v-else>
      <div class="panel-head">
        <h1 class="panel-title">{{ t("ratingTitle") }}</h1>
      </div>

      <div class="panel-body">
        <div>
          <p class="text-small text-mute info-item-pad-sm">
            {{ t("onBasis") }} {{ t("reviewStr", reviews.count) }}
          </p>
        </div>
        <Rating :data-rating="Math.round(allRating.value)" />

        <b-row class="text-center rating-view">
          <b-col cols="2">
            <div class="rating-square">
              <span class="rating-square-count">{{ allRating.value }}</span>
            </div>
          </b-col>

          <b-col cols="10">
            <b-row>
              <b-col v-for="(item, index) in rating" :key="index" cols="6">
                <b-row>
                  <b-col cols="6">
                    <p class="text-small text-left">{{ item.name }}</p>
                  </b-col>
                  <b-col cols="6">
                    <div class="rating-line-count text-small text-mute">
                      {{ item.value }}
                    </div>
                    <div class="rating-line">
                      <div
                        class="rating-line-complete"
                        :style="{ width: item.width + '%' }"
                      ></div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import Rating from "@/components/base/BaseRating";
import { HTTP, Objects } from "@/components/api/";
import { mapActions } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "DetailRating",
  components: {
    Rating,
  },
  props: {
    reviews: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      commentsCount: null,
      commentsArray: null,
      comments: null,
      rating: {
        clear: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("clear"),
        },
        service: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("service"),
        },
        price_quality: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("quality"),
        },
        infrastructure: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("goodLocation"),
        },
        right_photo: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("truthPhoto"),
        },
        accuracy: {
          title: null,
          value: null,
          valueAll: null,
          width: null,
          name: this.t("accuracy"),
        },
      },
      allRating: {
        title: null,
        value: null,
        valueAll: null,
        width: null,
      },
      ratingTitile: [
        { name: this.t("clear") },
        { name: this.t("quality") },
        { name: this.t("truthPhoto") },
        { name: this.t("service") },
        { name: this.t("goodLocation") },
        { name: this.t("accuracy") },
      ],
    };
  },
  computed: {},
  watch: {
    "allRating.value"(val) {
      this.setObjectRating({
        rating: val,
        reviews: this.reviews.count,
      });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions("detail", ["setObjectRating"]),
    init() {
      Objects.getObjectReviewsMore(
        Number(this.id),
        Number(this.reviews.count),
        0
      )
        .then((response) => {
          const data = response.data.data;
          this.commentsArray = Object.values(data.comments).reverse();
          this.commentsCount = data.commentsCount;
          this.computedRating();
        })
        .catch((error) => {
          console.log("Ajax error: Objects.getObjectReviewsMore, ", error);
        });
    },
    computedRating() {
      if (
        this.commentsArray.length !== 0 &&
        this.commentsArray.length !== undefined &&
        this.commentsArray.length !== null
      ) {
        let findRating = this.commentsArray.map((value) => {
          return Object.entries(value.data.properties).reduce(
            (acc, [key, prop]) => {
              for (var lol in this.rating) {
                if (lol == prop.name) {
                  this.rating[lol].title = prop.title;
                }
              }

              if (
                Number.isInteger(prop.value) &&
                prop.value >= 0 &&
                prop.value <= 5
              ) {
                acc[key] = prop.value;
              }

              return acc;
            },
            {}
          );
        });

        for (let i = 0; i < findRating.length; i++) {
          this.allRating.valueAll += findRating[i].rating;
          for (var key in this.rating) {
            if (findRating[i][key] != undefined) {
              this.rating[key].valueAll += findRating[i][key];
            } else {
              this.rating[key].valueAll += findRating[i].rating;
            }
          }
        }

        for (var key in this.rating) {
          this.rating[key].value = (
            this.rating[key].valueAll / findRating.length
          ).toFixed(1);
          this.rating[key].width = this.rating[key].value * 20;
        }

        this.allRating.value = (
          this.allRating.valueAll / findRating.length
        ).toFixed(1);
        this.allRating.width = this.allRating.value * 20;

        return findRating;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-view {
  margin-top: 15px;
}

.rating-square {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  background-color: #545454;
  color: #fff;
  text-align: center;
  line-height: 80px;
  vertical-align: text-bottom;
  font-size: 35px;
  cursor: pointer;
}

.rating-square-count {
  color: #fff;
  text-align: center;
  line-height: 80px;
  vertical-align: text-bottom;
  font-size: 35px;
}

.rating-line {
  background-color: #ededed;
  display: inline-block;
  height: 6px;
  width: 67%;
}

.rating-line-complete {
  background-color: #fdb12e;
  height: 100%;
}

.rating-line-count {
  display: inline-block;
  margin-right: 10px;
}

.rating-comments {
  margin-top: 30px;
}
</style>
