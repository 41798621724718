<i18n lang="json">
{
	"ru": {
    "agreement": {
      "text": "Нажимая кнопку {btn}, вы соглашаетесь с условиями {link1} и на {link2}",
      "link1":"<a href='{link}' target='_blank'>пользовательского соглашения</a>",
      "link2":"<a href='{link}' target='_blank'>обработку персональных данных</a>"
    },
    "yes": "Да, продолжить",
    "no": "Нет",
    "btnBooking": "Хочу забронировать",
    "btnBookingLight": "Забронировать",
    "btnBookingNext": "Продолжить",
    "prebooking": "Пока платить не нужно.\nСначала дождитесь подтверждения бронирования.",
    "prebooking2": "Внесите предоплату в размере {precost} сейчас, остальное - при заселении.",
    "prebookingMinute": "Это займет меньше двух минут",
    "prebookingHold": "Пока платить не нужно",
    "pay": "Visa, Mastercard, Мир, Сбербанк.Онлайн и др.",
    "bookingPhone": "Ваш телефон для бронирования",
    "otherPhone": "Другой номер телефона",
    "enterPhone": "Введите номер телефона",
    "invalidPhone": "Укажите корректный номер телефона",
    "wrongPhone": "Телефон был указан ранее другим пользователем",
    "checkRules": "Примите условия Пользовательского соглашения",
    "bronevikTitle": "Бронирование номера",
    "bronevikText": "Чтобы забронировать этот номер, позвоните в нашу Службу бронирования.",
    "bronevikText2": "Вам не нужно тратить время на заполнение данных, мы всё сделаем за вас!",
    "hintPhone": "Отправим код подтверждения и информацию по бронированию",
    "wlLabelText": "Забронировать помогает партнёр <br> {n} — Суточно.ру",
    "inModerate": "Хозяин отредактировал объявление, изменения проверяются модератором. Попробуйте забронировать чуть позже.",
    "error": "Бронирование временно недоступно",
    "ok": "Понятно",
    "clarifyBooking": {
      "party": "Вы бронируете это жильё для вечеринки или мероприятия?",
      "pets": "Вы будете проживать с питомцем?",
      "partyAndPets": "Вы бронируете это жильё для вечеринки (мероприятия) и будете проживать с питомцем?"
    },
    "modalTitle": "Уточните запрос"
	},
	"en": {
    "agreement": {
      "text": "By clicking the {btn}, you agree to the terms of the {link1} and to the {link2}",
      "link1":"<a href='{link}' target='_blank'>user agreement</a>",
      "link2":"<a href='{link}' target='_blank'>processing of personal data</a>"
    },
    "yes": "Yes, continue",
    "no": "No",
    "btnBooking": "Booking",
    "btnBookingLight": "Booking",
    "btnBookingNext": "Continue",
    "prebooking": "You don't need to pay yet.\nFirst wait for the booking confirmation.",
    "prebooking2": "You can prepay of {precost} now, more - of check-in",
    "prebookingMinute": "It takes less than two minutes",
    "prebookingHold": "You don't need to pay yet",
    "pay": "Visa, Mastercard, Мир, Сбербанк.Онлайн etc",
    "bookingPhone": "Booking Phone",
    "otherPhone": "Another phone number",
    "enterPhone": "Enter correct phone number",
    "invalidPhone": "Enter phone number",
    "wrongPhone": "The phone has been previously entered by another user",
    "checkRules": "Accept the terms of the User Agreement",
    "bronevikTitle": "Room Reservation",
    "bronevikText": "To book this number, call our Booking Service.",
    "bronevikText2": "You do not need to spend time filling out the data, we will do everything for you!",
    "hintPhone": "We will send a confirmation code and booking information",
    "wlLabelText": "Partner helps to book <br> {n} - Sutochno.ru",
    "inModerate": "The owner has edited this ad. The changes are checking by moderator right now. Please try to book it again a little later.",
    "error": "Reservations are temporarily unavailable",
    "ok": "Ok",
    "clarifyBooking": {
      "party": "Are you booking this accommodation for a party or event?",
      "pets": "Will you live with your pet?",
      "partyAndPets": "Are you booking this party (event) accommodation and will be accommodating with a pet?"
    },
    "modalTitle": "Refine your request"
	}
}
</i18n>
<template>
  <div class="booking">
    <!-- <div class="booking__default" v-if="calcInfo.errors.length > 0">

    </div>-->
    <div class="booking__main">
      <template v-if="!manyGuests">
        <div v-if="disabledBlock" class="booking__error">
          <ul>
            <li
              v-for="(error, i) in getObjectPriceErrors"
              :key="i"
              class="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
        <template v-else>
          <CardPrice :object="object" />

          <div class="booking__phone">
            <div class="phone__text">{{ t("bookingPhone") }}</div>
            <div v-if="isAuth" class="phone__select">
              <b-form-select
                v-if="getPhone"
                id="max_guests"
                v-model="selectedPhone"
                :options="getPhone"
                @change="gaChangeForm(object.location.location), ymChangeForm()"
              ></b-form-select>
            </div>
            <div v-if="!compSelectedPhone" class="phone__input">
              <InputPhone
                ref="inputPhone"
                v-model="numberPhone"
                :is-valid="false"
                :class="{ error: errors.phone }"
                @change="gaChangeForm(object.location.location), ymChangeForm()"
                @click="initVkEvents"
                @activeMask="activeMask"
              />
              <div v-if="errors.phone" class="phone__errors">
                <div class="error">
                  {{ errors.phone }}
                </div>
              </div>
              <div class="hint-phone">
                {{ t("hintPhone") }}
              </div>
            </div>
            <FreeCancellation
              v-if="isFreeCancellation"
              :arrival-day="checkInFreeCancellation"
              :free-cancellation-day="dateFreeCancellation"
              :free-cancellation-day-crop="dateFreeCancellationCrop"
              :is-occupied="occupied"
              view="detail"
            />
            <div class="booking__bonus">
              <BaseBonus :value="calcBonus" />
            </div>
            <!-- так же этот блок в /mobile/Detail/Booking/ModalSteps/Booking/PriceCalcDetail.vue -->
            <div
              v-if="whitelabel && whitelabel.name === 'cian'"
              class="wl-text"
            >
              <div class="logo">
                <img :src="logoMob" class="img" />
              </div>
              <div class="info">
                <span v-html="t('wlLabelText', { n: whitelabel.title })" />
              </div>
            </div>
            <div v-if="!isAuth" class="phone__rule">
              <div
                class="user-agreement tmp-font--small"
                v-html="
                  t('agreement.text', {
                    btn: isInstant ? t('btnBookingLight') : t('btnBooking'),
                    link1: t('agreement.link1', {
                      link: '//sutochno.ru/rules',
                    }),
                    link2: t('agreement.link2', {
                      link: '//sutochno.ru/rules/agreement',
                    }),
                  })
                "
              />
            </div>
            <div class="booking__error">
              <ul>
                <li v-for="(value, name, index) in errors" :key="index">
                  <span v-if="value && name !== 'phone'" class="error">{{
                    value
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>
      <div class="booking__button">
        <button
          ref="btnBooking"
          class="button button_green button_w-100 button_size_md"
          :class="{
            button_disabled:
              nextButtonDisabled ||
              getObjectPrice.busy === 'busy' ||
              disabledBlock,
            'loading loading_simple button_disabled': isLoading,
          }"
          :disabled="
            nextButtonDisabled ||
            getObjectPrice.busy === 'busy' ||
            isLoading ||
            manyGuests ||
            disabledBlock
          "
          @click="onSubmit"
        >
          <template v-if="!isLoading">
            <template v-if="isInstant">
              <span class="icon-app-lightning"></span>
              {{ t("btnBookingLight") }}
            </template>
            <template v-else>{{ t("btnBooking") }}</template>
          </template>
          <!-- <span v-if="!isLoading">{{
            btnNext ? t("btnBookingNext") : t("btnBooking")
          }}</span> -->
        </button>
      </div>
    </div>
    <ModalCode
      v-model="isModalCode"
      :phone="numberPhone"
      @success="successModalCode"
    />
    <Modal
      ref="modalBronevik"
      v-model="modalBronevik"
      :title="t('bronevikTitle')"
      :footer-merge="true"
      class="bronevik"
    >
      <div class="bronevik__wrap">
        <div class="bronevik__text">{{ t("bronevikText") }}</div>
        <div class="bronevik__text">{{ t("bronevikText2") }}</div>
        <div class="bronevik__phone">
          <!-- <ViewPhone
            :calc-info="getObjectPrice ? getObjectPrice : null"
            :object="object"
            :is-always="true"
            :is-bronevik="true"
          /> -->
        </div>
      </div>
    </Modal>
    <Modal
      v-model="inModerationModal"
      :title="t('error')"
      max-width="600px"
      min-width="600px"
    >
      <div class="moderate-text tmp-font--medium">{{ t("inModerate") }}</div>
      <template #footer>
        <button class="btn btn-primary" @click="inModerationModal = false">
          {{ t("ok") }}
        </button>
      </template>
    </Modal>
    <Modal
      v-model="clarifyBookingModal"
      class="clarify-modal"
      :title="t('modalTitle')"
      max-width="600px"
      min-width="600px"
    >
      <div class="moderate-text tmp-font--medium">
        {{ t(`clarifyBooking.${isGetFilterParams}`) }}
      </div>
      <template #footer>
        <div class="modal-flex w-100">
          <button class="btn btn-outline-dark btn-left" @click="routeBuking">
            {{ t("yes") }}
          </button>
          <button class="btn btn-outline-dark" @click="continueBooking">
            {{ t("no") }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { isNavigationFailure } from "vue-router";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

import { Detail, Booking } from "@/utils/gtm/";
import format from "@/utils/format";

import InputPhone from "@sutochno/inputPhone";
import Modal from "@sutochno/modal";
import CardPrice from "./CardPrice/index.vue";
import BaseBonus from "@/components/base/BaseBonus";
import ViewPhone from "@/components/base/Detail/Aside/ViewPhone";
import FreeCancellation from "@/components/base/Detail/Aside/FreeCancellation";
import logoMob from "@/assets/img/logo-mob-new.svg";
import ModalCode from "./ModalCode";
import saleBonus from "@/mixins/saleBonus.js";
import { Users } from "@/components/api/";
// TODO: это из-за setQuery нужно отрефакторить и переделать систему роутинга в идеале
// отказаться или усовершенствовать beforeEach
import objectTimeLocation from "@/mixins/objectTimeLocation.js";
import PriceCalcDetail from "@/components/base/PriceCalcDetail/PriceCalcDetail.vue";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import { pushDataToYM } from "@/utils/yandexMetrica";

export default {
  name: "Booking",
  components: {
    CardPrice,
    PriceCalcDetail,
    BaseBonus,
    InputPhone,
    Modal,
    ViewPhone,
    FreeCancellation,
    ModalCode,
  },
  mixins: [saleBonus, objectTimeLocation, dataLayerPushGA4],
  props: {
    calcInfo: null,
    id: {
      type: String,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    date: {},
    guests: {
      type: Object,
    },
    manyGuests: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      inModerationModal: false,
      status: true,
      numberPhone: null,
      selected: null,
      compSelectedPhone: null,
      errors: {
        phone: false,
        status: false,
      },
      isFixed: true,
      viewCalc: false,
      modalBronevik: false,
      isLoading: false,
      maxLengthPhone: 11,
      btnNext: false,
      isModalCode: false,
      logoMob,
      draftType: null,
      clarifyBookingModal: false,
      openOldBooking: false,
      target: null,
    };
  },
  mounted() {
    this.changeHold();
    if (this.isAuth) {
      this.getMobiles();
    }
    if (Object.keys(this.vkAppInfo).length && this.vkAppInfo.phone_number) {
      this.numberPhone = `+${this.vkAppInfo.phone_number}`;
    }
  },
  unmounted() {
    this.isLoading = false;
  },
  computed: {
    ...mapState("user", ["isAuth", "userData", "whitelabel", "isLoadUserData"]),
    ...mapState("user", [
      "userData",
      "currency",
      "isAuth",
      "whitelabel",
      "isLoadUserData",
    ]),
    ...mapState("search", ["checkIn", "checkOut", "location", "filters"]),
    ...mapState("user", ["currency", "viewPort", "isVkApp", "vkAppInfo"]),
    ...mapGetters("detail", [
      "getBookingData",
      "getObjectPrice",
      "getObjectPriceErrors",
      "isBronevik",
      "getInstant",
      "isValidDates",
      "isInModerationStatus",
      "getObjectData",
      "isHotel",
      "getDataForYM",
    ]),
    ...mapState("booking", ["canHold", "params"]),
    ...mapGetters("detailBooking", ["petsObject", "isInstant"]),
    ...mapGetters("booking", ["userMobiles"]),
    ...mapGetters("search", [
      "occupied",
      "guestsSend",
      "maxGuests",
      "getUsersSearchParams",
      "commonParamsForMetrika",
    ]),
    nextButtonDisabled() {
      if (this.compSelectedPhone && this.getObjectPrice?.busy !== "busy") {
        return false;
      } else {
        if (this.numberPhone == "") {
          return true;
        } else {
          return false;
        }
      }
    },
    disabledBlock() {
      if (this.getObjectPriceErrors?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    hiddenProp() {
      if (
        this.getObjectPrice &&
        (this.getObjectPrice?.is_booking_now ||
          this.getObjectPrice?.is_booking_no_precost ||
          this.getObjectPrice?.is_object_hot)
      )
        return true;
      else return false;
    },
    selectedPhone: {
      get() {
        if (
          this.userMobiles.length ||
          (this.getPhone && this.getPhone[0] && this.getPhone[0].value)
        )
          return (this.compSelectedPhone =
            this.getPhone[0].value ||
            (this.isAuth &&
              this.userData.user.contacts.find((item) => item.type_id == 2)));
        else return (this.compSelectedPhone = null);
      },
      set(value) {
        this.numberPhone = null;
        this.compSelectedPhone = value;
      },
    },
    calcObject() {
      return this.getObjectPrice.detail;
    },
    reducedCalcObject() {
      const other = this.calcObject.filter((el) => el.type !== "interval");
      const interval = this.calcObject.filter((el) => el.type === "interval");
      const result = interval.reduce((acc, val) => {
        return {
          ...acc,
          ...val,
          base:
            acc.hasOwnProperty("base") && acc.hasOwnProperty("nights")
              ? acc.base + val.base * val.nights
              : val.base,
          nights: acc.hasOwnProperty("nights")
            ? acc.nights + val.nights
            : val.nights,
          cost:
            acc.hasOwnProperty("cost") && acc.hasOwnProperty("nights")
              ? acc.cost + val.cost * val.nights
              : val.cost,
        };
      }, {});
      other.push(result);
      return other;
    },
    // calcData() {
    //   return this.getObjectPrice;
    // },
    calcCurrency() {
      return this.getObjectPrice.currency;
    },
    calcBonus() {
      return this.getObjectPrice.bonus;
    },
    currencyCalc() {
      if (this.getObjectPrice?.currency) {
        return {
          id: this.getObjectPrice.currency.id,
          title: this.getObjectPrice.currency.code,
        };
      } else {
        return this.currency;
      }
    },
    getPhone() {
      return [
        ..._.range(0, this.userMobiles.length).map((p) => {
          return {
            text: this.userMobiles[p],
            id: p,
            value: Number(this.userMobiles[p].match(/\d/g).join("")),
          };
        }),
        {
          text: this.t("otherPhone"),
          value: null,
          id: null,
        },
      ];
    },
    priceNight() {
      return this.object.extprices.find((p) => p.interval_start == 0);
    },
    isFc() {
      let item = false;
      if (this.object.properties.hidden_properties) {
        item = this.object.properties.hidden_properties.properties.find(
          (item) => item.name == "fc"
        );
        item = item ? item.value : false;
      }
      return item;
    },
    isFreeCancellation() {
      if (this.isFc) {
        if (this.occupied && this.checkIn) {
          let сurrentTime = new Date(
            new Date().toISOString().slice(0, 10) + "T00:00:00"
          ).getTime();
          let amountDays = Math.round(
            (this.checkIn.getTime() - сurrentTime) / 86400000
          );

          if (amountDays < 5) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    checkInFreeCancellation() {
      if (this.checkIn) {
        return this.checkIn
          .toLocaleString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
          .replace(" г.", "");
      } else {
        return "";
      }
    },
    dateFreeCancellation() {
      if (this.checkIn) {
        let lastDay = new Date(this.checkIn.getTime() - 86400000 * 4);
        return lastDay
          .toLocaleString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
          .replace(" г.", "");
      } else {
        return "";
      }
    },
    dateFreeCancellationCrop() {
      if (this.checkIn) {
        let lastDay = new Date(this.checkIn.getTime() - 86400000 * 4);
        const day = lastDay.toLocaleString("ru-RU", {
          day: "numeric",
        });
        const month = lastDay
          .toLocaleString("ru-RU", {
            month: "short",
          })
          .slice(0, 3);
        return `${day} ${month}`.toLocaleLowerCase();
      } else {
        return "";
      }
    },
    isGetFilterParams() {
      // дает добро для открытия модалки сопоставляя парметры фильтра и правила размещения
      // отвечает за показ нужного текста в модалке
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      if (!properties) return false;
      const party = properties.find((item) => item.name === "party");
      if (this.filters?.party && !this.filters.pets) {
        return party?.value === 2 ? "party" : false;
      }
      if (this.filters?.party && this.filters?.pets) {
        if (party?.value === 2) return "party";
        return false;
      }
      return false;
    },
  },
  watch: {
    // status(val) {
    //   if (!val) {
    //     this.errors.status = this.t("checkRules");
    //   }
    //   {
    //     this.errors.status = false;
    //   }
    // },
    isAuth(val) {
      if (val) {
        this.getMobiles();
      } else {
        this.compSelectedPhone = null;
      }
    },
    getObjectPrice: {
      deep: true,
      handler(val) {
        this.changeHold();
      },
    },
    petsObject: {
      deep: true,
      handler(val) {
        this.changeHold();
      },
    },
    "vkAppInfo.phone_number": function (val) {
      this.numberPhone = `+${this.vkAppInfo.phone_number}`;
      // deep: true,
      // handler() {
      //   alert(JSON.stringify(val))
      //   if(Object.keys(this.vkAppInfo).length && this.vkAppInfo.phone_number){
      //     this.selectedPhone = Number(this.vkAppInfo.phone_number)
      //     this.showPhone = true
      //     this.bookingNext = true
      //   }
      // }
    },
    "errors.phone": {
      deep: true,
      handler(value) {
        if (value && !this.isAuth) {
          Detail.gaLoginStatus("error", "sms");
        }
      },
    },
  },
  methods: {
    ...mapActions("detail", ["setBookingData"]),
    ...mapActions("booking", ["getMobiles", "bookingToAction"]),
    ...mapMutations("booking", ["setCanHold", "setMaskPhone"]),
    ...mapMutations("detailBooking", ["setIsPets", "setOpenDetailGuestsModal"]),
    ...mapActions("detailBooking", ["routeBooking"]),
    changeHold() {
      const CAN_HOLD =
        !this.getInstant &&
        this.getObjectPrice?.can_hold &&
        !this.petsObject.petsWarning;
      this.setCanHold(CAN_HOLD);
    },
    visibilityChanged(isVisible, entry) {
      this.isFixed = !isVisible && entry.target.getBoundingClientRect().top > 0;
    },
    async bookingStart() {
      if (this.isBronevik) {
        this.modalBronevik = true;
      } else {
        this.onSubmitAnalytics();
        this.isLoading = true;
        const dataForYM = this.getDataForYM("add", this.$route.name);
        pushDataToYM(dataForYM);
        await this.setBookingData({
          phone: this.compSelectedPhone || this.numberPhone,
          guests: this.guestsSend,
          date_begin: this.getObjectPrice.date_begin,
          date_end: this.getObjectPrice.date_end,
          object_id: this.id,
          price: this.getObjectPrice.price,
          is_pets: this.petsObject.isPets ? 1 : 0,
          pets: this.petsObject.pets,
          target: this.target,
          name: this.userData?.user?.name,
        })
          .then(async (response) => {
            if (response.data.errors.length) {
              this.$emit("onErrors", response.data.errors);
            } else {
              this.$emit("onErrors", false);
              if (this.getBookingData.draft_type) {
                this.draftType = this.getBookingData.draft_type;
              }

              const pushToBooking = () => {
                let number = this.numberPhone
                  ? String(this.numberPhone).match(/\d/g)
                  : null;
                if (
                  this.getBookingData.order_code &&
                  (this.compSelectedPhone ||
                    number.length >= this.maxLengthPhone ||
                    (number[0] === "4" &&
                      number[1] === "7" &&
                      number.length === 10))
                ) {
                  // если даты не пересекаются с закрытыми датами
                  // отправляем на упрощенное бронирование
                  if (this.isValidDates && !this.whitelabel) {
                    // https://jira.webpower.ru/browse/VUECLIENT-1811
                    // this.dataLayerPush("add_to_cart");
                    this.$router
                      .push({
                        name: this.isTodayBooking ? "Booking" : "LightBooking",
                        params: { orderCode: this.getBookingData.order_code },
                      })
                      .then((failure) => {
                        if (isNavigationFailure(failure)) {
                          this.$router.push({
                            name: this.isTodayBooking
                              ? "Booking"
                              : "LightBooking",
                            params: {
                              orderCode: this.getBookingData.order_code,
                            },
                          });
                        }
                      });
                  } else {
                    // если пересекаются то отправляем на старый черновик
                    // или это whitalbel
                    // https://jira.webpower.ru/browse/VUECLIENT-1811
                    // this.dataLayerPush("add_to_cart");
                    this.$router
                      .push({
                        name: "Booking",
                        params: { orderCode: this.getBookingData.order_code },
                      })
                      .then((failure) => {
                        if (isNavigationFailure(failure)) {
                          this.$router.push({
                            name: "Booking",
                            params: {
                              orderCode: this.getBookingData.order_code,
                            },
                          });
                        }
                      });
                  }
                }
              };

              const pushToPay = async () => {
                await this.bookingToAction({
                  order_code: this.getBookingData.order_code,
                  no_matter: 1,
                  cost: this.getObjectPrice.price,
                  precost: this.getObjectPrice.precost,
                }).then((res) => {
                  this.$store.commit("booking/clearBookingToIntersect");
                  this.$store.commit("booking/clearBookingToErrors");
                  if (typeof res.data.data.intersect === "string") {
                    this.simpleBookingLoadingMutate(false);
                    this.$store.commit(
                      "booking/setBookingToIntersect",
                      res.data.data.intersect
                    );
                  } else if (
                    res?.data?.errors &&
                    Object.keys(res.data.errors).length
                  ) {
                    this.$store.commit(
                      "booking/setBookingToErrors",
                      res.data.errors
                    );
                    this.$router
                      .push({
                        name: "Booking",
                        params: { orderCode: this.getBookingData.order_code },
                      })
                      .then((failure) => {
                        if (isNavigationFailure(failure)) {
                          this.$router.push({
                            name: "Booking",
                            params: {
                              orderCode: this.getBookingData.order_code,
                            },
                          });
                        }
                      });
                  } else if (res?.data?.data?.to_url) {
                    this.bookingAnalytics();
                    location.href = res.data.data.to_url;
                  }
                  return res;
                });
              };

              if (this.openOldBooking) {
                // если животные и вечеринка по запросу и в фильтрах выбран один из них то открывется старая страница брони
                this.$router
                  .push({
                    name: "Booking",
                    params: { orderCode: this.getBookingData.order_code },
                  })
                  .catch((err) => {
                    if (err) {
                      this.$router.push({
                        name: "Booking",
                        params: { orderCode: this.getBookingData.order_code },
                      });
                    }
                  });
              } else {
                if (this.whitelabel) {
                  pushToBooking();
                } else if (
                  this.draftType === "mb" ||
                  this.draftType === "hot"
                ) {
                  await pushToPay();
                } else if (this.draftType === "order") {
                  if (this.canHold) {
                    await pushToPay();
                  } else {
                    pushToBooking();
                  }
                }
              }
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.error(err);
          });
        return;
      }
    },
    ymChangeForm() {
      return Detail.ymChangeForm();
    },
    gaChangeForm(value) {
      return Detail.gaChangeForm(value);
    },
    format(v) {
      return format(v);
    },
    textCalc(elem) {
      let findString;

      findString = Object.values(this.getObjectPrice.detail).find(
        (item) => item
      );
      return findString;
    },
    // TODO: onSubmitAnalytics также добавлен в store, вызывается в ButtonSwitch
    onSubmitAnalytics() {
      Detail.gaEventBooking(
        this.object.location.location,
        this.getObjectPrice.price
      );
      Detail.gtmAddProduct(
        this.id,
        this.getObjectPrice.price,
        this.getObjectPrice.cnt,
        this.currency.name
      );
      // https://jira.webpower.ru/browse/VUECLIENT-1811
      this.dataLayerPush("view_item");
      // Detail.gaAddProduct(
      //   this.format(this.checkIn ? this.checkIn : this.date.in),
      //   this.format(this.checkOut ? this.checkOut : this.date.out),
      //   this.object.title,
      //   this.id,
      //   this.object.extprices?.find((item) => item.interval_start == 0)
      //     ?.value || "?",
      //   this.object.location.location,
      //   String(this.object.type_title).charAt(0).toUpperCase() +
      //     String(this.object.type_title).substr(1),
      //   `${this.t(
      //     "rooms",
      //     this.object.properties?.basic_properties?.properties?.find(
      //       (item) => item.name == "rooms"
      //     )?.value || 1
      //   )}`,
      //   this.object.position,
      //   this.getObjectPrice.cnt,
      //   this.isHotel ? "Отель" : "Отдельный объект"
      // );
      Detail.gtmStep1();
    },
    routeBuking() {
      this.openOldBooking = true;
      this.clarifyBookingModal = false;
      const isPets =
        this.isGetFilterParams === "pets" ||
        this.isGetFilterParams === "partyAndPets"
          ? 1
          : 0;
      this.setIsPets(isPets);
      this.target =
        this.isGetFilterParams === "party" ||
        this.isGetFilterParams === "partyAndPets"
          ? 4
          : null;
      this.bookingStart();
    },
    continueBooking() {
      this.clarifyBookingModal = false;
      this.bookingStart();
    },
    async onSubmit(e) {
      sutochnoMetrika.detail("click", "book", {
        ...this.commonParamsForMetrika,
        userPhone: this.selectedPhone,
        cost: this.salePrice,
        page: "object",
      });

      this.gaChangeForm(this.object.location.location);
      this.ymChangeForm();

      let number = this.numberPhone
        ? String(this.numberPhone).match(/\d/g)
        : null;

      if (this.isAuth) {
        if (this.checkBookingErrors()) {
          return true;
        }

        this.errors.phone = false;
        if (this.compSelectedPhone) {
          if (this.isGetFilterParams) {
            this.clarifyBookingModal = true;
            return true;
          }
          this.bookingStart();
          return true;
        } else if (this.numberPhone && number.length < this.maxLengthPhone) {
          if (number[0] === "4" && number[1] === "7" && number.length === 10) {
            this.errors.phone = false;
            this.isModalCode = true;
            this.btnNext = true;
          } else {
            this.errors.phone = this.t("invalidPhone");
            this.scrollToPhone();
          }
        } else {
          // телефон новый и валидный
          let formData = new FormData();
          let phone = this.numberPhone
            ? Number(this.numberPhone.split(" ").join("").slice(1))
            : null;
          formData.append("contactValue", phone || "");
          formData.append("typeId", "2");
          formData.append("isLandline", "0");
          const { data } = await Users.addContact(formData);
          if (data && !data.success) {
            this.errors.phone = this.t("wrongPhone");
            this.scrollToPhone();
            return;
          }
          this.errors.phone = false;
          this.isModalCode = true;
          this.btnNext = true;
        }
        e.preventDefault();
      } else {
        this.errors.phone = false;

        if (this.numberPhone && number.length < this.maxLengthPhone) {
          if (number[0] === "4" && number[1] === "7" && number.length === 10) {
            this.errors.phone = false;
            this.isModalCode = true;
            this.btnNext = true;
          } else {
            this.errors.phone = this.t("invalidPhone");
            this.scrollToPhone();
          }
        } else {
          this.isModalCode = true;
          this.btnNext = true;
        }
        e.preventDefault();
      }
    },
    formatCalc(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: (this.calcCurrency && this.calcCurrency.code) || "RUB",
        minimumFractionDigits: 0,
      }).format(item);
    },
    initVkEvents() {
      if (this.isVkApp && !Object.keys(this.vkAppInfo).length) {
        /** eventsNameList - содержит эвенты которые ловятся в шапке проекта */
        const eventsNameList = [
          "VK_GET_USER_MAIL",
          "VK_GET_USER_INFO",
          "VK_GET_PHONE_NUMBER",
        ];
        const eventsCollection = [];
        eventsNameList.forEach((collEl) => {
          const evt = new Event(collEl);
          eventsCollection.push(evt);
        });
        eventsCollection.forEach((collEl) => {
          document.dispatchEvent(collEl);
        });
      }
    },
    successModalCode() {
      // холд и мб можно проверить по this.canHoldAside
      this.bookingStart();
    },
    bookingAnalytics() {
      Booking.gaEventBooking(
        this.object.location.location,
        this.getObjectPrice.price
      );
      Booking.ymSendForm();
      // TODO: часть данных с миксина ecommerceItems (на будущее переделать) везде где вызывается Booking.gaSendProduct
      // пока оставил как есть чтобы не сломать данные по аналитике
      // https://jira.webpower.ru/browse/VUECLIENT-1811
      this.dataLayerPush("begin_checkout");
      // Booking.gaSendProduct(
      //   this.object.id,
      //   this.ecommerceItems[0]["item_name"],
      //   this.getObjectPrice.price,
      //   this.object.location.location,
      //   this.ecommerceItems[0]["item_category"],
      //   this.ecommerceItems[0]["item_category2"],
      //   this.ecommerceItems[0]["item_variant"],
      //   this.object.position,
      //   this.getObjectPrice.cnt,
      //   this.maxGuests
      // );
      const phone = this.compSelectedPhone || this.numberPhone;
      Booking.gtmStep2(this.object.id, `${phone}@unknown.email`);
    },
    scrollToPhone() {
      let input = this.$refs.inputPhone?.$el;
      if (input) {
        // в зоне видимости ли input
        // 82 размер кнопки
        let diff =
          input.getBoundingClientRect().bottom +
          82 -
          document.documentElement.clientHeight;
        input.scrollIntoView({
          block: diff > 0 ? "center" : "nearest",
          inline: "nearest",
          behavior: "smooth",
        });
        input
          .getElementsByClassName("input-phone__textbox")[0]
          ?.focus({ preventScroll: true });
      }
    },
    activeMask(val) {
      this.setMaskPhone(JSON.parse(JSON.stringify(val)));
    },
    checkBookingErrors() {
      if (
        this.$route.name == "Detail" &&
        this.guests?.pets?.value &&
        this.guests?.pets?.description == ""
      ) {
        this.setOpenDetailGuestsModal(true);
        return true;
      }
      if (this.isInModerationStatus) {
        this.inModerationModal = true;
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.clarify-modal {
  :deep() {
    .sc-modal-header {
      padding: 0 20px;
      text-indent: 0;
    }
    .sc-modal-footer {
      padding: 30px 20px 20px;
    }
  }
  .moderate-text {
    padding: 0px 20px;
  }
}
.modal-flex {
  display: flex;
  justify-content: center;
}
.btn-left {
  margin-right: 20px;
}
.wl-text {
  margin-top: 15px;
  margin-bottom: -5px;
  display: flex;
  align-items: center;

  .logo {
    padding-right: 14px;
  }
  .info {
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
}
.bronevik {
  &__wrap {
    padding: 10px 10px 40px 10px;
  }
  &__phone {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__text {
    font-size: 16px;
  }
}
.booking {
  .rules_error {
    :deep(.sc-checkbox--mark) {
      border: 1px solid #e10600;
      border-radius: 3px;
    }
  }
  &__button {
    position: sticky;
    bottom: 0;
    margin: 0 -20px -20px;
    padding: 20px;
    background-color: #ffffff;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      &:disabled {
        cursor: default;
        opacity: 0.8;
        &:hover {
          opacity: 0.8;
        }
      }
      .icon-app-lightning {
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
  &__info,
  &__pay {
    margin-top: 10px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    white-space: pre-line;
  }
  &__pay {
    color: #717171;
    &_center {
      text-align: center;
    }
  }
  &__bonus {
    margin-top: 20px;
  }
  &__error {
    margin-top: 10px;
  }
  &__error,
  .phone__errors {
    .error {
      color: #e10600;
      font-size: 13px;
      line-height: 14px;
    }
  }

  &__phone {
    margin-top: 20px;
    .phone {
      &__text {
        font-size: 14px;
        line-height: 16px;
      }
      &__select {
        margin-top: 8px;
        .sc-select {
          height: 42px;
        }
      }
      &__input {
        margin-top: 10px;
      }
      &__rule {
        margin-top: 10px;
        .sc-checkbox {
          color: #000;
          font-size: 13px;
        }
      }
    }

    .hint-phone {
      margin: 8px 0 20px;
      font-size: 12px;
      color: #717171;
    }
  }

  .user-agreement {
    color: #717171;
    margin: 20px 0 0;
    text-align: center;
    :deep(a) {
      color: #717171;
      text-decoration: underline;
    }
  }
}
</style>
